<template>
  <span></span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "IbanReupload",
  mounted () {
    const token = this.$route.query.jwtToken || this.$route.query.token
    if (!token) this.goToPage("expired-page", this)
    this.isUpdateIban(token)
      .then(res => {

        // If Customer Token Not Found
        if (!res.response) {
          this.onUpdateIbanError()
        // return
        }

        // Everything can proceed as normal
        // this.displaySuccess()
      })
      .catch(err => {
        this.onUpdateIbanError()
      })

  },
  methods: {
    ...mapActions(['isUpdateIban']),
    onUpdateIbanError () {
      // Set a modal error and hide all other things
      // this.showModal('Die soeben angegebene IBAN hat im Rahmen unserer Aktion bereits erfolgreich teilgenommen. Bitte verwenden Sie eine andere IBAN!')
      setTimeout(() => {
        this.goToPage("expired-page" , this);
      }, 2000)
    }
  }
};
</script>

<template>
  <div class="m-0 p-0 checkboxWrapper" :style="cssVars">
    <ValidationProvider :rules="validation" v-slot="{ errors }" :name="name.toLowerCase()">
      <div class="wrapper" :style="styles">
        <label class="box-container" :for="`checkbox_${fieldId}`">
          <span class="checkmark" :class="[{'hiddenOnThankYou': tyPage}, {'checked': checked}]"></span>
          <span v-html="$t(label)"></span>
          <input
            v-model="localChecked"
            :name="name"
            :checked="checked"
            type="checkbox"
            :id="`checkbox_${fieldId}`"
            required
          />
        </label>
        <div
          v-if="expandable"
          role="button"
          id="down-arrow-btn"
          :aria-expanded="expand"
          aria-controls="expandedDiv"
          style="display: flex; align-items:center; max-height:30px; min-width:30px;"
        >
          <img
            class="down-arrow"
            @click="checkBoxClicked"
            src="/assets/images/down-arrow.svg"
            alt
          />
        </div>
      </div>
      <div v-if="expandable" class="expandedDiv" ref='expandedDiv' id="expandedDiv">
        <div class="card card-body-dop" v-html="$t(expandableText)"></div>
      </div>
      <div class="validation-error" style="margin-left: 5px;" v-if="errors.length">
        <img class="error_min" src="https://jolt-assets.s3.eu-central-1.amazonaws.com/template/islos/error.svg" alt="Error" >
        {{errors[0]}}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import store from "../../store";

export default {
  name: "checkbox",
  data () {
    return {
      localChecked: this.checked,
      expand: false
    };
  },
  props: {
    label: {
      type: String,
      default: "Empty Link Text"
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    expandable: {
      required: false,
      type: Boolean,
      default: false
    },
    expandableText: {
      required: false,
      type: String,
      default: ""
    },
    checked: {
      required: false,
      type: Boolean,
      default: false
    },
    fieldId: {
      type: String,
      required: true
    },
    validation: {
      type: [String, Object],
      required: false,
      default: ""
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    colorSchema: {
      type: String,
      default: ""
    },
    tyPage: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.$emit("component-updated", {
      checked: !!this.checked
    });
    this.$emit("input", !!this.checked);
    if (document.querySelector('.modalOpener')) {
      document.querySelectorAll('.modalOpener').forEach(x => {
        x.addEventListener('click', function () {
          store.commit('setModalOptions', {
            type: 'custom',
            message: this.dataset.text,
            isActive: true,
            closeButton: true
          })
        })
      })
    }
  },
  methods: {
    checkBoxClicked () {
      this.expand = !this.expand;
      const expandedDiv = this.$refs['expandedDiv'];
      expandedDiv.style.maxHeight = expandedDiv.style.maxHeight ? null : "fit-content";
    }
  },
  updated () {
    if (document.querySelector('.modalOpener')) {
      document.querySelectorAll('.modalOpener').forEach(x => {
        x.addEventListener('click', function () {
          store.commit('setModalOptions', {
            type: 'custom',
            message: this.dataset.text,
            isActive: true,
            closeButton: true
          })
        })
      })
    }
  },
  watch: {
    localChecked (value) {
      // console.log("localChecked = ", value)
      // this.$eventHub.$emit('checkbox-event', { id: this.fieldId, checked: value ? '1' : '0' });
      this.$emit("component-updated", {
        checked: value
      });
      this.$emit("input", value);
    }
  },
  computed: {
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
}

/* The container */
.box-container {
  display: block;
  position: relative;
  margin: 0 !important;
  cursor: pointer;
  font-size: 14px;
  line-height: 27px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.box-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.box-container .checkmark {
  position: absolute;
  top: 0px !important;
  right: calc(100% + 5px);
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #303030;
}

/* On mouse-over, add a grey background color */
.box-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.box-container input:checked ~ .checkmark {
  background-color: var(--color);
}
.checkmark{
  &.checked{
    background-color: #B49759;
    background-image: url("~@/assets/icons/thick-tick.svg");
    background-position: center center;
    background-size: contain;
  }
}
/* Create the checkmark/indicator (hidden when not checked) */
.box-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.box-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.box-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.box-container {
  img {
    @media (max-width: 667px){
    display: none !important;
    }
  }
}

.down-arrow {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: var(--color-1);
}
[aria-expanded="true"] {
  .down-arrow {
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.card-body-dop {
  border: none;
  align-items: center;
  background-color: transparent;
}

.expandedDiv {

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
  -moz-transition: height 0.4s ease-out;; /* Firefox 4 */
  -webkit-transition: height 0.4s ease-out;;  /* Safari and Chrome */
  -o-transition: height 0.4s ease-out;;  /* Opera */

  &Image {
    -webkit-transform: rotate(180deg); /* Safari and Chrome */
    -moz-transform: rotate(180deg);   /* Firefox */
    -ms-transform: rotate(180deg);   /* IE 9 */
    -o-transform: rotate(180deg);   /* Opera */
    transform: rotate(180deg);
  }
}

.hiddenOnThankYou {
  display: none;
}

.mobileSurvey {
  @media (max-width: 667px){
    max-width:340px !important;
  }
}

.survey {
  width: 644px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background-color: white;
  padding: 10px 20px;
  gap: 15px;

  img {
    @media (max-width: 667px){
    width: 100%;
  }

  }

  @media (max-width: 667px){
    width: 340px;
  }

  .questionWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-flow: column;
    gap: 10px;

    .question {
      display: flex;
      align-items: center;
      color: var(--color-1);

      .number {
        height:30px;
        width: 30px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        display:flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: #003EA5;
        margin-right:8px;
      }

      p {
        margin: 0 !important;
      }
    }

    .answer {
      margin-left: 38px;

      &Flex {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      textarea {
        resize: none;
        background: #f0f4fd;
        border-radius: 5px;
        padding: 10px;
        @media (max-width: 667px){
        width: 220px;
        }
      }

      input {
        margin-right: 8px;
      }

      input:checked + label {
        font-weight: bold;
        font-style: normal;
      }

      label {
        color: #002870;
        font-style: italic;
      }
    }

    .textareaLimit {
      align-self: flex-end;
      margin-right: 112px;
      margin-top: -5px;
      color: #828282
    }
  }

  button {
    font-weight: bold;
    text-transform: none !important;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}

</style>

<template>
  <div class="counterWrapper" :style="styles">
    <div class="counter">
      <div class="counterText d-flex flex-column flex-sm-row justify-content-center align-items-center">
        <span>
          <img
            src="https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/logo.png"
            alt="Bordeau Chesnel Logo"
            style="height: 60px;"
          >
        </span>
        So viele Einlösungen sind noch verfügbar:
      </div>
      <div
        class="digits"
        v-html="`${participationNumber}`"
      >
      </div>
    </div>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import axios from 'axios';
import { mapActions } from 'vuex';
export default {
  name: "participation-counter",
  props: {
    text: {
      type: String,
      default: "Empty paragraph"
    },
    mobile: {
      type: Boolean,
      default: false
    },
    variables: Object,
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    classes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    totalAllowedParticipations: {
      type: String,
      default: "35.000"
    },
    endpoint: String
  },
  data () {
    return {
      cssString: "",
      classString:"",
      totalParticipations: "nan",
      isLoading: false
    };
  },
  computed: {
    html () {
      let result = this.text;
      if (this.variables) {
        for (const variable in this.variables) {
          let value = this.$store.state.formData[variable];
          if(variable === "referenceNumber") {
            value = this.$store.getters.getReferenceNumber
          }
          result = result.replace(new RegExp(`{${variable}}`, "g"), value);
        }
      }
      return result;
    },

    participationNumber () {
      if (!isNaN(this.totalParticipations)) {
        let participationsLeft = (this.totalParticipations + "");

        if(participationsLeft <= 0){
          window.location.href = "/participation-limit-reached"
          this.$store.dispatch("setComponentStatus", {
            component: this.fieldId,
            status: false
          });
        }else {
          this.$store.dispatch("setComponentStatus", {
            component: this.fieldId,
            status: true
          });
        }
      }
      let digits = this.totalParticipations.toString().split("")
      let result = ""
      digits.forEach((digit, index) => {
        result = result + `<span class='digit'>${digit}</span>`
      })
      return result;
    }
  },
  watch: {
    styles: {
      immediate: true,
      handler () {
        const keys = Object.keys(this.styles);
        const vals = Object.values(this.styles);
        keys.forEach((key, idx) => (this.cssString += key + ":" + vals[idx]));
      }
    },
    classes: {
      immediate: true,
      handler () {
        this.classes.forEach(cls=>{
          this.classString += cls+" ";
        })
      }
    }
  },
  methods: {
    ...mapActions(['checkParticipationCount']),
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000),
    triggerActions () {
      if (this.actions) {
        this.actions.forEach(action => {
          this.$store.dispatch(action, { val: 123 });
        });
      }
    }
  },
  mounted () {
    this.checkParticipationCount({ endpoint: this.endpoint })
      .then((data) => {
        this.totalParticipations = data.remainingParticipation;
        this.isLoading = false
        this.$store.dispatch("setComponentStatus", {
          component: this.fieldId,
          status: data.remainingParticipation > 0
        });
      })
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_application.scss";

.counterWrapper {
  max-width: calc(75vw - 10%);
  height: 120px;
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 840px) {
    max-width: 90vw;
    flex-direction: column;
    height: auto;
    padding: 10px;
    justify-content: space-between;
    gap: 20px;
  }

  .logoWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1024px) {
      scale: 0.7;
    }
  }

  .counter {
    flex: 4;
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    padding: 20px 20px;
    min-width: max-content;
    color: #FFF;
    font-size: 26px;
    font-weight: bold;
    border-radius: 15px;
    justify-content: space-around;
    background-color: #E42314;

    @media (max-width: 987px) {
      display: flex;
      flex-flow: column;
    }

    .counterText{
      color:#fff;
      font-size: 24px;
      font-weight: 500;

      @media (max-width: 1200px) {
        font-size: 18px;
      }

      @media (max-width: 670px) {
        font-size: 16px;
      }
    }

    span:not(.digit) {
      flex: 3;
      text-align: center;
    }

    ::v-deep .digits {
      flex-wrap: nowrap !important;
      display: flex;
      justify-content: space-evenly;
      height: 120%;
      align-items: center;

      .digit {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 30px;
        margin: 0px 3px;
        line-height: 48px;
        width: 48px;
        height: 48px;
        background-color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 48px;

        @media (max-width: 1220px) {
          width:40px;
          height:40px;
        }
      }
    }
    @media (max-width: 667px) {
      flex-direction: column;
      gap: 10px;
      font-size: 18px;
      padding: 15px 5px;
    }
  }
}

.digits {
  @media (max-width: 1600px) {
    font-size: 18px
  }
  @media (max-width:1300px) {
    font-size: 16px
  }
  @media (max-width: 667px) {
    font-size: 14px;
  }
}

p {
  outline: 0;
  margin-bottom: 0;
}

.heading {
  margin: 0px 20px;
  color: white;
  text-align: center;
  font: normal normal 500 22px/25px;
  letter-spacing: 0px;
  opacity: 1;
}

@media (max-width: 500px) {
  .heading {font-size: 15px; line-height: 15px; margin: 0px ;}
}

.digits {
  color: #000;

  b {
    color: red !important;
  }
}
.AvenirLight {
  font-family: avenir_lt_35_lightregular;
}

.AvenirMedium {
  font-family: avenir_lt_65_mediumregular;
}

.headingNightPad {
  margin: 5px 10px 0 0;
  font-size: 20px;
}

</style>

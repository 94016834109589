<template>
  <div class="gray_block" :class="[(!shouldDisplayFull && !pullDown) ? 'drop_down_wrapper' : '', mainWrapperClass]">
    <div :style="{'cursor': !shouldDisplayFull ? 'pointer' : ''}"  @click="pull" @keypress.enter="pull" class="mainContent" tabindex="0">
      <div class="inner_gray_block mt-3" :style="{'margin-bottom': !shouldDisplayFull ? '30px' : ''}" >
        <div class="text" v-html="$t(descriptionText)"></div>
        <div class="subText" v-html="$t(descriptionSubText)"></div>
      </div>
      <div class="row align-items-center flex-nowrap">
        <div v-if="showProduct" class="col-lg-6 col-sm-6 px-0 mt-2">
          <img class="img-fluid mt-lg-2" src="https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/receipt-product-example.png" alt="receipt example">
        </div>
        <div v-else class="col-lg-5 col-sm-6 px-0 mt-2 d-flex justify-content-center">
          <img class="img-fluid mt-lg-2" src="https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/receipt-example.png" alt="receipt example">
        </div>
        <div class="col-lg-6 col-sm-6 px-0 mt-2">
          <ul class="wraper_punkt">
            <li class="media" :key="step.label" v-for="step in steps">
              <div class="item">{{ $t(step.label) }}</div>
              <div class="media-body item_text">{{ $t(step.text) }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="!hideExamples" class="wrapper_check_block">
        <div class="checkMark correct">
          <img class="img-fluid" :src="showProduct ? '/assets/images/png/rcpt-prod-1.png' : '/assets/images/png/check_1.png'" alt="Correct">
          <img class="checkMark_img checkMark_img_1" src="../../assets/images/svg/checkMark.svg" alt="Correct">
        </div>
        <div class="checkMark">
          <img class="img-fluid" :src="showProduct ? '/assets/images/png/rcpt-prod-2.png' : '/assets/images/png/check_2.png'" alt="Incorrect">
          <img class="cross_red_img cross_red_img_2" src="../../assets/images/svg/cross_red.svg" alt="Incorrect">
        </div>
        <div class="checkMark">
          <img class="img-fluid" :src="showProduct ? '/assets/images/png/rcpt-prod-3.png' : '/assets/images/png/check_3.png'" alt="Incorrect">
          <img class="cross_red_img cross_red_img_3" src="../../assets/images/svg/cross_red.svg" alt="Incorrect">
        </div>
        <div class="checkMark">
          <img class="img-fluid" :src="showProduct ? '/assets/images/png/rcpt-prod-4.png' : '/assets/images/png/check_4.png'" alt="Incorrect">
          <img class="cross_red_img cross_red_img_4" src="../../assets/images/svg/cross_red.svg" alt="Incorrect">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "receipt-information",
  props: {
    descriptionText: {
      required: false,
      type: String,
      default: ""
    },
    descriptionSubText: {
      required: false,
      type: String,
      default: ""
    },
    steps: {
      required: false,
      type: [Array, Object],
      default: () => []
    },
    hideExamples: {
      type: Boolean,
      default: false
    },
    mainWrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    showProduct: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    pullDown: false
  }),
  computed: {
    shouldDisplayFull () {
      return screen.width > 992 ? true : false
    }
  },
  methods: {
    pull () {
      this.pullDown = !this.shouldDisplayFull ? !this.pullDown : false
    }
  }
};
</script>
<style lang="scss" scoped>
  .gray_block {
    background-color: transparent;
    border-radius: 4px;
  }
  .wraper_punkt {
    margin-bottom: 15px;
    li {
      margin-bottom: 20px;
      font-size: 16px;
      color:#4F4F4F;
      align-items: center;
      @media (max-width: 991px) {
        margin-bottom: 15px;
      }
      .item {
        width: 25px;
        height: 25px;
        display: inline-block;
        color: #161616;
        border-radius: 100px;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        line-height: 26px;
      }
      .item_text {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.16px;
        text-align: left;
        color: var(--text-color-4);
        @media(max-width: 1024px){
          font-size: 12px;
        }
        @media (max-width: 668px) {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
  .checkMark {
    position: relative;
    float: left;
    margin-left: 15px;
    &:first-child {
      margin-left: 0px;
    }
    .checkMark_img {
      position: absolute;
      top: 100px;
      left: 100%;
      margin-left: -27px;
      @media (max-width: 767px) {
        max-width: 30px;
        margin-left: -15px;
      }
    }
    .cross_red_img {
      position: absolute;
      top: 70px;
      left: 50%;
      margin-left: -18px;
      @media (max-width: 767px) {
        max-width: 24px;
        margin-left: -12px;
      }
    }
  }
  .wrapper_check_block {
    padding-top: 90px;
    display: flex;
    justify-content: center;
    @media (max-width: 991px) {
      padding-top: 20px;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

.text {
  font-weight: 800;
  font-size: 20px;
  line-height: 19px;
  color: #4F4F4F;
  text-align: left;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    margin: 10px 0;
    font-size: 16px;
    line-height: 26px;
  }
}
.subText {
  font-size: 16px;
  line-height: 20px;
  color: #4F4F4F;
  text-align: left;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 15px;
  }
}

.drop_down {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    cursor: pointer;


    p {
        margin: 0px;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
    }

    img {
        width: 20px;
        height: 20px;
    }
}

.drop_down_wrapper {
    padding: 10px !important;
    border-radius: 100px;
    margin-bottom: 20px !important;
}

@media all and (orientation: landscape ) {

    .drop_down {
        display: flex;
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
        cursor: pointer;

        p {
            margin: 0px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }

    .drop_down_wrapper {
        padding: 10px !important;
        border-radius: 100px;
        margin-bottom: 20px !important;
    }
    @media screen and (max-width: 425px) {
      .stepCardWrapper {
        padding: 15px !important;
        margin-bottom: 200px !important;
        border-radius: 30px;
        height: 450px;
        background-color: white !important;
      }
      .drop_down {
        display: none;
      }
    }
    @media screen and (max-width: 375px) {
      .stepCardWrapper {
        font-size: 12px !important;
      }
    }
}

.border {
  border: 8px solid #E5E8EE !important;
}

.mainContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mobile_version_hide {
  @media (max-width: 900px) {
    display: none;
  }
}
</style>

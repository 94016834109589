<template>
  <div class="steps_container mobileStepContainer" :style="[styles, cssVars]">
    <div class="progressbar">
      <div
        v-for="(step, idx) in steps"
        :key="idx"
        class="progress-step"
        :data-step="idx + 1"
        :data-title="$t(step.title)"
        :data-smtitle="$t(step.smTitle)"
        :class="activeIndex == idx+1 ? 'active' : activeIndex > idx+1 ? 'done' : ''"
      >
      <img
      class="step-img"
      v-if="activeIndex == idx + 1"
      :src="step.stepIcon" alt="step-img">
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "step-counter",
  props: {
    grid: {
      type: Object,
      default: () => {
        `backgroundColor: "#fff";
        
        `;
      }
    },
    steps: {
      type: Array,
      required: true
    },
    actions: {
      type: Object
    },
    colorSchema: {
      required: false,
      type: String,
      default: '#000'
    },
    styles: {
      type: Object,
      default: () => {}
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      currentstep: {
        page: "bankInformation"
      }
    };
  },
  computed: {
    active () {
      const idx = this.steps.findIndex(
        step => {
          return step.page === this.$store.getters.currentPage()
        }
      );
      return idx;
    },
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  },
  methods: {
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000),
    triggerActions () {
      if (this.actions) {
        this.actions.forEach(action => {
          this.$store.dispatch(action, { val: 123 });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>

li {
  list-style-type: none;
}

img {
  vertical-align: baseline;
}

.mobileStepContainer{
  @media(max-width: 1024px){
    max-width: 50%   !important;

    .progressbar {
      gap: 50px;

      .progress-step {
        width: 40px !important;
        height: 40px !important;

        &:not(:first-child)::before {
          width: 50px;
        }

        &::after {
          display: flex;
          text-align: center;
          align-items: center;
          width: 90px;
        }
      }

      .active {
        color: #4E4B66;
        background-color: #4E4B66;
        background-position: center;
        background-repeat: no-repeat;
        border-color: #4E4B66;

        &::before {
          border-bottom-style: solid !important;
        }
      }
    }
  }
  @media(max-width: 450px){
    max-width: 100% !important;
  }
}
.steps_ul {
  padding: 0;
  margin: 0;
  text-align: center;
  margin: 0 auto;
  // display: flex;
  // flex-flow: column;
  // align-items: center;
  // justify-content: center;

  li:not(:last-child).active {
    .bg>.doneContainer>.done{
      @media(max-width: 450px){
        margin-top: 16px;
      }
    }
  }
}

.step-img {
  z-index: 9999;
}

.progresstexts {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 !important;
}

.progressbar {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 216px;

  .progress-step {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    aspect-ratio: 1/1 !important;
    border: 2px solid #4E4B66;
    border-radius: 50%;
    background: #4E4B66;
    text-align: center;
    color: #4E4B66;
    font-weight: 700;
    position: relative;

    &:not(:first-child)::before {
      content: '';
      position: absolute;
      top: 50%;
      right: calc(100% + 2px);
      transform: translateY(-50%);
      width: 216px;
      border-bottom: 4px solid #4E4B66;
    }

    &::after {
      content: attr(data-title);
      position: absolute;
      top: calc(100% + 50px);
      width: max-content;
      @media(max-width:450px){
        display: none !important;
      }
    }
  }

  .active {
    color: #4E4B66;
    background-color: #4E4B66;
    background-position: center;
    background-repeat: no-repeat;
    border-color: #4E4B66;

    &::before {
      border-bottom-style: solid !important;
    }
    &::after{
      @media(max-width:450px){
        display: flex !important;
      }
    }
  }
}

.progressbar li {
  list-style: none;
  // display: inline-block;
  position: relative;
  text-align: center;
  cursor: pointer;
  // margin: 0 50px;
}
.steps_ul {
  padding: 0;
  margin: 0;
  text-align: center;
  margin: 0 auto;
  width: 100% !important;
}

.steps {
  padding-top: 160px;
  padding-bottom: 110px;
}

.steps_container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.step_action {
  .bg {
    font-size: 25px;
    line-height: 37.5px;
    width: 50px;
    height: 50px;
    border: 2px solid #9DCAFF;
    // opacity: 0.75;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0px auto;
    background-color: transparent;
    z-index: 2;
    position: relative;
    @media(max-width: 450px){
      font-size: 7vw;
      width: 10vw;
      height: 10vw;
    }
    .stepNumber {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      transform: translateY(-50%);
      z-index: 2;
      color: #9DCAFF;
    }
    .done {
      max-width: 2vw;
      position: absolute;
      @media(max-width: 450px){
        max-width: 5vw;
      }
    }
    & > img {
      margin-left: 50px;
    }
  }
  .step_text {
    font-size: 14px;
    margin-top: 10px;
    line-height: 24px;
    text-align: center !important;
    color: #9DCAFF;
  }
  &.active {
    .bg {
      background: #E3F0FF;
      border: none;
    }
    .step_text {
      color: var(--color-5);
      font-weight: 700;
    }
  }
}

@media (max-width: 400px) {
   .steps_container {
     padding: 0 !important;
   }
   .step_text {
     font-size: 14px !important;
   }
}
</style>

<template>
  <div class="m-0">
    <ValidationProvider
      :name="label || name"
    >
      <div
        class="input-container dateInputWrapper form-group"
      >
        <label
          :for="name"
          class="w-100 m-0"
        >
          <div class="top-label">
            {{ label }}
          </div>
          <input
            type="date"
            :name="label"
            :id="name"
            ref="input"
            class="picker"
            v-model="selectedValue"
            @input="updateValue"
            @click="floatLabel = true"
            :style="styles"
            :max="disableDate()"
            :placeholder="placeholder"
          >
        </label>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>

export default {
  name: "DateInput",
  props: {
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default: "TT/MM/JJ"
    },
    value: {
      type: String
    },
    validation: {
      type: String,
      required: false
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    params: {
      type: Object,
      required: false
    }
  },
  computed: {
    datepickerParams () {
      const params = { ...this.params }
      if (params.disabledDates) {
        if (params.disabledDates.to) {
          params.disabledDates.to = this.convertToDate(params.disabledDates.to)
        }
        if (params.disabledDates.from) {
          params.disabledDates.from = this.convertToDate(params.disabledDates.from)
          params.openDate = params.disabledDates.from
        }
      }
      return params
    }
  },
  data () {
    return {
      floatLabel: false,
      selectedValue: ""
    }
  },
  methods: {
    updateValue (event) {
      this.floatLabel = !this.floatLabel
      let value = event.target.value;
      this.floatLabel = value == '' ? false : true;
      this.$emit("component-updated", {
        value
      });
    },
    convertToDate (date) {
      if (date === "today") {
        return new Date()
      } else if (date === "18years") {
        const date = new Date()
        date.setFullYear(date.getFullYear() - 18)
        return date
      } else {
        return new Date(date)
      }
    },
    disableDate () {
      const date = new Date()
      date.setFullYear(date.getFullYear() - 18)
      return date.toISOString().split('T')[0]
    }
  }
};
</script>

<style scoped lang="scss">
.dateInputWrapper {
  position: relative;
  .inputIcon {
    position: absolute;
    right: 10px;
    top: 40%;
  }
}

.input-container input {
  background: #F0F4FD;
  border: 1px solid #f0f4fd;
  border-radius: 8px;
  box-sizing: border-box;
  outline: 0;
  padding: .75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.dateInputWrapper {
  position: relative;

  :deep(input) {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 6px;
    height: 46px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    padding-left: 10px;
    &::placeholder {
      font-size: 12px;
      line-height: 20px;
      color: rgba(82, 82, 86, 0.45);
    }
  }
}
</style>

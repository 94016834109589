export default {
  isEditMode: true,
  pageProperties: {
    testing: true,
    pages: [
      { // HOME PAGE
        pageName: 'home',
        isPublic: true,
        pageId: 100,
        sections: [
          {
            sectionId: 'orsdfdsbg8s',
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            },
            fields: [
              { // Refresh Token
                fieldType: 'refresh-token',
                fieldId: 'ij3433'
              },
              {
                fieldType: 'key-visual',
                fieldId: 'key100',
                properties: {
                  styles: {
                    padding: '0 0 0 0',
                    maxWidth: '100vw'
                  },
                  noContainer: true,
                  desktopImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/banner.png',
                  tabletImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/banner.png',
                  mobileImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/banner.png'
                }
              },
              {
                fieldType: "campaign-steps",
                fieldId: "x1p9hx",
                properties: {
                  grid: {
                    columns: 11,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: '20px',
                    marginBottom: '10px'
                  },
                  points: [
                    `<div class='campaign-dates'>Aktionszeitraum<br><b>15.04.2024-14.07.2024</b><br><b>Einsendeschluss: 21.07.2024</b></div>`,
                    `<p class='campaign-points'>An der Aktion nehmen ausschließlich Produkte mit dem gelben Aktionsaufkleber teil.</p>`,
                    `<p class='campaign-points'>Jede Person kann mit maximal einem Produkt teilnehmen. 100% Cashback. Jeder akzeptierte Kaufbeleg nimmt außerdem an der Verlosung eines Thermomixes teil.</p>`
                  ],
                  mobilePoints: [
                    `<div class='campaign-dates'>Aktionszeitraum<br><b>15.04.2024-14.07.2024</b><br><b>Einsendeschluss<br>21.07.2024</b></div>`,
                    `<p class='campaign-points'>An der Aktion nehmen ausschließlich Produkte mit dem gelben Aktionsaufkleber teil.</p>`,
                    `<p class='campaign-points'>Jede Person kann mit maximal einem Produkt teilnehmen. 100% Cashback. Jeder akzeptierte Kaufbeleg nimmt außerdem an der Verlosung eines Thermomixes teil.</p>`
                  ],
                  columnValues: [4, 4, 4],
                  columnValuesMobile: [12, 12, 12]
                }
              },
              { // participation counter
                fieldType: 'participation-counter',
                fieldId: '6mo8qp',
                properties: {
                  grid: {
                    alignItems: 'center',
                    justifyContent: 'center',
                    columns: 12
                  },
                  endpoint: '/participationCount'
                }
              },
              {
                fieldType: 'paragraph',
                fieldId: 'par1001',
                properties: {
                  grid: {
                    columns: 12
                  },
                  styles: {
                    fontSize: '50px !important',
                    fontFamily: 'Times New Roman',
                    fontWeight: '400',
                    color: '#151515'
                  },
                  text: 'Schritte zur Teilnahme an der Aktion',
                  classes: 'page-heading paragraphBg overrideMarginBot text-center'
                }
              },
              {
                fieldType: 'participation-steps',
                fieldId: 'acsh315',
                properties: {
                  grid: {
                    columns: 12
                  },
                  color: '#fff',
                  buttonClass: 'btn-lightGreen-transparent',
                  nextPage: 'produkt-auswählen',
                  steps: [
                    {
                      title: 'Schritt 1',
                      text: 'Produkt Kaufen',
                      image: 'step1',
                      count: 1
                    },
                    {
                      title: 'Schritt 2',
                      text: 'Produkt auswählen',
                      image: 'step2',
                      count: 2
                    },
                    {
                      title: 'Schritt 3',
                      text: 'Formular ausfüllen',
                      image: 'step3',
                      count: 3
                    },
                    {
                      title: 'Schritt 4',
                      text: 'Geld zurück erhalten',
                      image: 'step4',
                      count: 4
                    }
                  ]
                }
              },
              {
                fieldType: 'button-component',
                fieldId: 'x1p9hx',
                properties: {
                  grid: {
                    columns: 3,
                    alignItems: 'center',
                    justifyContent: 'center'
                  },
                  styles: {
                    fontSize: '20px !important'
                  },
                  classes: 'pc-long-button mb-2rem-desktop-only',
                  text: 'Rückerstattung starten',
                  disabled: false,
                  action: [
                    {
                      fn: 'goToPage',
                      params: 'product-selection'
                    },
                    {
                      fn: 'dataLayerPush',
                      params: {
                        event: 'view_just_snap',
                        gaEventAction: 'view'
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // PRODUCT SELECTION
        pageName: 'product-selection',
        locale: 'de',
        pageId: 102,
        sections: [
          { // Container
            sectionId: '98j3bew9jm',
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center'
            },
            fields: [
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw'
                  },
                  styles: {
                    paddingTop: '0px !important'
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    {
                      fieldType: 'step-counter',
                      fieldId: 'step100',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        activeIndex: 1,
                        colorSchema: '#4E7593',
                        steps: [
                          {
                            title: 'Choose product',
                            page: 'produkt-auswählen',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step1.png'
                          },
                          {
                            title: 'Upload receipt',
                            page: 'kaufbeleg-hochladen',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step2.png'
                          },
                          {
                            title: 'Kontodaten eingeben',
                            page: 'bankverbindung-angeben',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step3.png'
                          },
                          {
                            title: 'Formular ausfüllen',
                            page: 'registrieren',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step4.png'
                          }
                        ]
                      }
                    },
                    {
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginTop: '100px'
                        },
                        text: 'Produkt auswählen',
                        classes: 'page-heading paragraphBg overrideMarginBot'
                      }
                    },
                    {
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '50px'
                        },
                        text: 'Bitte wählen Sie das Aktionsprodukt aus, mit dem Sie teilnehmen möchten.',
                        classes: 'paragraphBg addPadding page-subheading'
                      }
                    },
                    { // Product Selection
                      fieldType: 'product-selection',
                      fieldId: '56hythe',
                      properties: {
                        grid: {
                          columns: 9
                        },
                        successAction: {
                          fn: 'goToPage',
                          params: 'receipt-upload'
                        },
                        texts: {
                          required: '',
                          maxSelectable: 'Du kannst nicht mehr als ein Produkt auswählen.'
                        },
                        maxSelectable: 1,
                        endpoint: '/submitProducts',
                        colorSchema: '#FFF',
                        products: [
                          {
                            ean: '3024720022934',
                            title: 'Bordeau Chesnel Pulled Chicken mit Tomaten-Confit 120g',
                            name: 'Bordeau Chesnel Pulled Chicken mit Tomaten-Confit 120g',
                            backgroundImage: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/products/3024720022934.png',
                            selectable: true,
                            id: 0
                          },
                          {
                            ean: '3024720022941',
                            title: 'Bordeau Chesnel Pulled Chicken mit Limette 120g',
                            name: 'Bordeau Chesnel Pulled Chicken mit Limette 120g',
                            backgroundImage: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/products/3024720022941.png',
                            selectable: true,
                            id: 1
                          },
                          {
                            ean: '3024720022958',
                            title: 'Bordeau Chesnel Pulled Chicken mit Schalotte & Schnittlauch 120g',
                            name: 'Bordeau Chesnel Pulled Chicken mit Schalotte & Schnittlauch 120g',
                            backgroundImage: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/products/3024720022958.png',
                            selectable: true,
                            id: 2
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              { // Buttons
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    marginBottom: '30px'
                  },
                  classes:
                    'btn-bucket pc-two-button-bucket m-2rem-desktop-only',
                  fields: [
                    { // Button
                      fieldType: 'button-component',
                      fieldId: 'but100',
                      properties: {
                        grid: {
                          columns: 3
                        },
                        text: 'Back',
                        classes: 'btn-sec-back',
                        action: {
                          fn: 'goToPage',
                          params: 'home'
                        },
                        arrow: {
                          prev: true,
                          color: '#E42314'
                        }
                      }
                    },
                    { // Next Button
                      fieldType: 'button-component',
                      fieldId: 'but100',
                      properties: {
                        grid: {
                          columns: 3
                        },
                        requires: ['56hythe'],
                        text: 'Next',
                        classes: 'btn-sec-next',
                        action: {
                          fn: 'sendProducts',
                          params: ''
                        },
                        arrow: {
                          next: true,
                          color: '#FFF'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // RECEIPT UPLOAD
        pageName: 'receipt-upload',
        locale: 'de',
        pageId: 103,
        sections: [
          { // Steps and Header
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center'
            },
            sectionId: 'fu30zq',
            fields: [
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    paddingTop: 0
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    {
                      fieldType: 'step-counter',
                      fieldId: 'step100',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        activeIndex: 2,
                        colorSchema: '#4E7593',
                        steps: [
                          {
                            title: 'Choose product',
                            page: 'produkt-auswählen',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step1.png'
                          },
                          {
                            title: 'Upload receipt',
                            page: 'kaufbeleg-hochladen',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step2.png'
                          },
                          {
                            title: 'Kontodaten eingeben',
                            page: 'bankverbindung-angeben',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step3.png'
                          },
                          {
                            title: 'Formular ausfüllen',
                            page: 'registrieren',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step4.png'
                          }
                        ]
                      }
                    },
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginTop: '60px'
                        },
                        text: 'Kaufbeleg + Produktfoto mit Aktionssticker hochladen',
                        classes: 'page-heading paragraphBg overrideMarginBot'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '15px'
                        },
                        text: 'Bitte laden Sie ein Foto Ihres Kassenbons zusammen mit dem Deckel des teilnehmenden Produkts hoch. Achten Sie darauf, dass der Werbeaufkleber auf dem Deckel gut sichtbar ist.',
                        classes: 'page-subheading paragraphBg addPadding'
                      }
                    },
                    { // Receipt uploader
                      fieldType: 'empty-bucket',
                      fieldId: 'h453eadaf',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        styles: {
                          gap: '50px',
                          marginTop: '30px',
                          marginBottom: '30px',
                          maxWidth: '85%',
                          flexWrap: 'nowrap',
                          borderRadius: '20px',
                          justifyContent: 'center',
                          backgroundColor: 'rgba(255, 255, 255, 0.4)',
                          padding: '0 !important',
                          color: '#828282'
                        },
                        classes: 'banner-responsive',
                        fields: [
                          { // Recipt upload
                            fieldType: 'receipt-processor',
                            fieldId: 'klvakj',
                            properties: {
                              grid: {
                                columns: 5,
                                alignItems: 'center',
                                justifyContent: 'center'
                              },
                              styles: {
                                maxHeight: '540px',
                                backgroundColor: '#FFF',
                                borderRadius: '30px',
                                padding: '12px !important'
                              },
                              successAction: {
                                fn: 'goToPage',
                                params: 'banking'
                              },
                              texts: {
                                choose: 'Choose a file',
                                upload: 'Upload a file',
                                text1: 'Click here to upload the receipt and product photo',
                                text2: 'Only JPEG, PNG or PDF. Maximum 16MB',
                                wrongFormat: 'Wrong format receipt',
                                fileSizeLimit: 'File too big'
                              },
                              editTexts: {
                                crop: 'Crop',
                                erase: 'Erase',
                                drag: 'Drag',
                                zoomIn: 'Zoom In',
                                zoomOut: 'Zoom Out'
                              },
                              mainWrapperClass: 'pc-receipt-processor',
                              colorSchema: '#303030',
                              phrases: [
                                'Please erase your personal data with the eraser tool.',
                                'Please frame the receipt / invoice in the gray rectangle.'
                              ],
                              cornerDetectionPhrases: [
                                'Please erase your personal data with the eraser tool.',
                                'Please frame the receipt / invoice in the gray rectangle.'
                              ],
                              convertToBlackWhite: false
                            }
                          },
                          { // Receipt Info
                            fieldType: 'empty-bucket',
                            fieldId: 'vdf932fd',
                            properties: {
                              grid: {
                                columns: 7
                              },
                              classes: 'addMarginLeft',
                              fields: [
                                {
                                  fieldType: 'receipt-information',
                                  fieldId: '5mq1p6',
                                  properties: {
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: 12
                                    },
                                    mainWrapperClass: 'pc-receipt-information',
                                    shouldDisplayFull: true,
                                    descriptionText: 'Die folgenden Informationen müssen deutlich lesbar sein.',
                                    descriptionSubText: 'Zerknitterte Belege können nicht akzeptiert werden.',
                                    hideExamples: true,
                                    showProduct: true,
                                    steps: [
                                      { // Retailer
                                        text: 'Retailer / Shop',
                                        label: 'A'
                                      },
                                      {
                                        text: 'Campaign product and its price',
                                        label: 'B'
                                      },
                                      {
                                        text: 'Total sum of the receipt / invoice and applied VAT',
                                        label: 'C'
                                      },
                                      {
                                        text: 'Date and time of purchase',
                                        label: 'D'
                                      },
                                      {
                                        text: 'Receipt / Invoice ID (not the bank transaction ID)',
                                        label: 'E'
                                      },
                                      {
                                        text: 'Sticker',
                                        label: 'F'
                                      }
                                    ]
                                  }
                                },
                                { // Receipt Examples
                                  fieldType: 'empty-bucket',
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      margin: '0px auto'
                                    },
                                    classes: 'receiptUploader',
                                    fields: [
                                      {
                                        fieldType: 'receipt-examples',
                                        fieldId: '5mq1p6',
                                        properties: {
                                          grid: {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            columns: 12
                                          },
                                          mainWrapperClass: 'pc-receipt-examples',
                                          showProduct: true
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px'
                        },
                        classes:
                          'btn-bucket pc-two-button-bucket m-2rem-desktop-only',
                        fields: [
                          { // Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: 'Back',
                              classes: 'btn-sec-back',
                              action: {
                                fn: 'goToPage',
                                params: 'product-selection'
                              },
                              arrow: {
                                prev: true,
                                color: '#E42314'
                              }
                            }
                          },
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: 'Next',
                              classes: 'btn-sec-next',
                              requires: ['klvakj'],
                              action: [
                                {
                                  fn: 'sendReceipt',
                                  params: 'klvakj'
                                },
                                {
                                  fn: 'dataLayerPush',
                                  params: {
                                    event: 'generate_lead',
                                    gaEventAction: 'lead'
                                  }
                                }
                              ],
                              arrow: {
                                next: true,
                                color: '#FFF'
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // IBAN UPLOAD
        pageName: 'banking',
        locale: 'de',
        pageId: 106,
        requires: [1],
        sections: [
          { // Section
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              columns: 12
            },
            fields: [
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    paddingTop: '0px !important'
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    {
                      fieldType: 'step-counter',
                      fieldId: 'step100',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        activeIndex: 3,
                        colorSchema: '#4E7593',
                        steps: [
                          {
                            title: 'Choose product',
                            page: 'produkt-auswählen',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step1.png'
                          },
                          {
                            title: 'Upload receipt',
                            page: 'kaufbeleg-hochladen',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step2.png'
                          },
                          {
                            title: 'Kontodaten eingeben',
                            page: 'bankverbindung-angeben',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step3.png'
                          },
                          {
                            title: 'Formular ausfüllen',
                            page: 'registrieren',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step4.png'
                          }
                        ]
                      }
                    },
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginTop: '60px'
                        },
                        text: 'Bankverbindung eingeben',
                        classes: 'page-heading overrideMarginBot paragraphBg'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '40px'
                        },
                        text: 'Bitte teilen Sie uns mit, auf welches Konto der Erstattungsbetrag überwiesen werden soll.',
                        classes:
                          'page-subheading paragraphBg addPadding overrideMarginBot'
                      }
                    },
                    {
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 11,
                          justifyContent: 'center',
                          maxWidth: '85vw',
                          padding: '0 !important'
                        },
                        fields: [
                          {
                            fieldId: 'fu0923r',
                            fieldType: 'empty-bucket',
                            properties: {
                              grid: {
                                columns: 9,
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: '0 !important'
                              },
                              class: 'col-sm-12',
                              fields: [
                                { // Form
                                  fieldType: 'form-component',
                                  fieldId: 'bank1Nf0',
                                  properties: {
                                    successAction: {
                                      fn: 'goToPage',
                                      params: 'guest-register'
                                    },
                                    endpoint: '/submitIBANv2',
                                    keepInState: ['referenceNumber'],
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: 11
                                    },
                                    styles: {
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      backgroundColor: 'white',
                                      padding: '2vw',
                                      borderRadius: '20px',
                                      color: 'black',
                                      boxShadow:
                                        '0px 4px 10px rgba(132, 132, 132, 0.65)'
                                    },
                                    fields: [
                                      {
                                        fieldType: 'empty-bucket',
                                        fieldId: 'f934if',
                                        properties: {
                                          grid: {
                                            columns: 12
                                          },
                                          fields: [
                                            {
                                              fieldId: 'tp6zfb',
                                              fieldType: 'text-input',
                                              properties: {
                                                id: 'firstname',
                                                name: 'firstname',
                                                label: 'Vorname Kontoinhaber*',
                                                noLabel: true,
                                                placeholder:
                                                  'First name of the account holder*',
                                                validation:
                                                  'required|namesWithHyphensAndSpaces',
                                                text: '',
                                                grid: {
                                                  columns: 6
                                                }
                                              }
                                            },
                                            {
                                              fieldId: 'p55fao',
                                              fieldType: 'text-input',
                                              properties: {
                                                id: 'lastname',
                                                name: 'lastname',
                                                validation:
                                                  'required|namesWithHyphensAndSpaces',
                                                label: 'Nachname Kontoinhaber*',
                                                noLabel: true,
                                                placeholder:
                                                  'Last name of the account holder*',
                                                text: '',
                                                grid: {
                                                  columns: 6
                                                }
                                              }
                                            },
                                            {
                                              fieldId: 'h9arlr',
                                              fieldType: 'text-input',
                                              properties: {
                                                id: 'iban',
                                                name: 'iban',
                                                validation:
                                                  'required|iban|sepa',
                                                label: 'IBAN*',
                                                noLabel: false,
                                                labelOnTop: false,
                                                placeholder: 'IBAN*',
                                                text: '',
                                                grid: {
                                                  columns: 6
                                                }
                                              }
                                            },
                                            {
                                              fieldId: 'v1nf34',
                                              fieldType: 'text-input',
                                              properties: {
                                                id: 'bic',
                                                name: 'bic',
                                                label: 'BIC*',
                                                noLabel: false,
                                                labelOnTop: false,
                                                placeholder: 'BIC*',
                                                validation: 'required|bic',
                                                text: '',
                                                grid: {
                                                  columns: 6
                                                }
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                { // Subheading
                                  fieldType: 'paragraph',
                                  fieldId: 'par1001',
                                  properties: {
                                    grid: {
                                      columns: 12
                                    },
                                    text: '* Vermeiden Sie eine Änderung bei den Umlauten: <b>Müller bzw. Mueller.</b> Vermeiden Sie ein Hinzufügen oder Weglassen von Bindestrichen: <b>Marie Luise bzw. Marie-Luise.</b> Geben Sie den zweiten Vornamen nur dann an, wenn auch das Konto so geführt wird. Sollten die Daten nicht mit den Kontodaten übereinstimmen, kommt es in den meisten Fällen zu Rückbuchungen seitens der Bank und erschwert den Ablauf einer unkomplizierten und raschen Rückerstattung.',
                                    styles: {
                                      fontSize: '14px',
                                      color: '#303030',
                                      marginTop: '30px'
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px'
                        },
                        classes: 'btn-bucket pc-two-button-bucket',
                        fields: [
                          { // Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: 'Back',
                              classes: 'btn-sec-back',
                              action: {
                                fn: 'goToPage',
                                params: 'receipt-upload'
                              },
                              arrow: {
                                prev: true,
                                color: '#E42314'
                              }
                            }
                          },
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: 'Next',
                              classes: 'btn-sec-next',
                              action: {
                                fn: 'sendForm',
                                params: 'bank1Nf0'
                              },
                              arrow: {
                                next: true,
                                color: '#FFF'
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // GUEST REGISTRATION
        pageName: 'guest-register',
        locale: 'de',
        pageId: 107,
        sections: [
          { // Steps and Heading
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              columns: 12
            },
            fields: [
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    paddingTop: '0 !important'
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    {
                      fieldType: 'step-counter',
                      fieldId: 'step100',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        activeIndex: 4,
                        colorSchema: '#4E7593',
                        steps: [
                          {
                            title: 'Choose product',
                            page: 'produkt-auswählen',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step1.png'
                          },
                          {
                            title: 'Upload receipt',
                            page: 'kaufbeleg-hochladen',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step2.png'
                          },
                          {
                            title: 'Kontodaten eingeben',
                            page: 'bankverbindung-angeben',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step3.png'
                          },
                          {
                            title: 'Formular ausfüllen',
                            page: 'registrieren',
                            stepIcon:
                              'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/step-counter/step4.png'
                          }
                        ]
                      }
                    },
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginTop: '60px'
                        },
                        text: 'Registrierung',
                        classes: 'page-heading overrideMarginBot paragraphBg'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '50px'
                        },
                        text: 'Wir benötigen folgende Informationen, um Ihre Rückerstattung abschließen zu können.',
                        classes: 'page-subheading addPadding paragraphBg'
                      }
                    },
                    { // Form
                      fieldType: 'form-component',
                      fieldId: 'guestForm',
                      properties: {
                        styles: {
                          padding: '15px',
                          justifyContent: 'center'
                        },
                        grid: {
                          columns: 11,
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '50px'
                        },
                        requires: ['1'],
                        successAction: {
                          fn: 'goToPage',
                          params: 'thank-you'
                        },
                        successState: [
                          {
                            state: 'referenceNumber',
                            form: 'referenceNumber'
                          }
                        ],
                        endpoint: '/register',
                        fields: [
                          { // CARD BUCKET
                            fieldType: 'empty-bucket',
                            fieldId: 'row-bucket-2',
                            properties: {
                              grid: {
                                columns: 12
                              },
                              classes: 'card-bucket',
                              fields: [
                                { // ROW BUCKET
                                  fieldType: 'empty-bucket',
                                  fieldId: 'row-bucket-2',
                                  properties: {
                                    grid: {
                                      columns: 12
                                    },
                                    classes: 'form-row-bucket',
                                    fields: [
                                      { // form-heading
                                        fieldType: 'paragraph',
                                        fieldId: 'par1001',
                                        properties: {
                                          grid: {
                                            columns: 12
                                          },
                                          styles: {
                                            color: '#4f4f4f'
                                          },
                                          text: 'Über Sie',
                                          classes: 'form-heading'
                                        }
                                      }
                                    ]
                                  }
                                },
                                { // ROW BUCKET
                                  fieldType: 'empty-bucket',
                                  fieldId: 'row-bucket-3',
                                  properties: {
                                    grid: {
                                      columns: 12
                                    },
                                    classes: 'form-row-bucket',
                                    fields: [
                                      { // First name
                                        fieldId: 'b4baqs',
                                        fieldType: 'text-input',
                                        properties: {
                                          id: 'firstName',
                                          name: 'firstName',
                                          label: 'Vorname*',
                                          placeholder: 'First name',
                                          text: '',
                                          validation:
                                            'required|namesWithHyphensAndSpaces',
                                          grid: {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            columns: 6
                                          }
                                        }
                                      },
                                      { // Last name
                                        fieldId: 'gofije',
                                        fieldType: 'text-input',
                                        properties: {
                                          id: 'lastName',
                                          name: 'lastName',
                                          label: 'Nachname*',
                                          placeholder: 'Last name',
                                          text: '',
                                          validation:
                                            'required|namesWithHyphensAndSpaces',
                                          grid: {
                                            columns: 6
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                                { // ROW BUCKET NEXT
                                  fieldType: 'empty-bucket',
                                  fieldId: 'row-bucket-5',
                                  properties: {
                                    grid: {
                                      columns: 12
                                    },
                                    classes: 'form-row-bucket',
                                    fields: [
                                      { // Gender radio
                                        fieldType: 'select-input',
                                        fieldId: 'Geschlecht',
                                        properties: {
                                          id: 'gender',
                                          name: 'gender',
                                          colorSchema: 'var(--color-1)',
                                          grid: {
                                            columns: 6
                                          },
                                          selectedValue: 'other',
                                          validation: '',
                                          labelOnTop: true,
                                          label: 'Geschlecht',
                                          options: [
                                            {
                                              value: 'male',
                                              text: 'Herr'
                                            },
                                            {
                                              value: 'female',
                                              text: 'Frau'
                                            },
                                            {
                                              value: 'other',
                                              text: 'Keine Angabe'
                                            },
                                            {
                                              value: 'na',
                                              text: 'Divers'
                                            }
                                          ]
                                        }
                                      },
                                      { // Email
                                        fieldId: 'h7kzf9',
                                        fieldType: 'text-input',
                                        properties: {
                                          id: 'email',
                                          name: 'email',
                                          label: 'Email Adresse*',
                                          noLabel: true,
                                          placeholder: 'example@example.com',
                                          text: '',
                                          validation: 'required|email',
                                          grid: {
                                            columns: 6
                                          }
                                        }
                                      }
                                    ],
                                    styles: {
                                      justifyContent: 'flex-end'
                                    }
                                  }
                                }
                              ]
                            }
                          },
                          { // ROW BUCKET
                            fieldType: 'empty-bucket',
                            fieldId: 'row-bucket-8',
                            properties: {
                              grid: {
                                columns: 11
                              },
                              classes: 'form-column-bucket checkbox-bucket',
                              fields: [
                                {
                                  fieldType: 'checkbox',
                                  fieldId: 'psyn1c',
                                  properties: {
                                    id: 'terms',
                                    name: 'terms',
                                    grid: {
                                      columns: 12
                                    },
                                    validation: {
                                      mandatory: {
                                        allowFalse: false
                                      }
                                    },
                                    styles: {
                                      color: '#525256',
                                      textAlign: 'justify',
                                      paddingBottom: '20px'
                                    },
                                    colorSchema: 'var(--color-4)',
                                    label: `Ich habe die <a href="/teilnahmebedingungen" target="_blank"><u>Teilnahmebedingungen</u></a> gelesen und stimme diesen zu.`
                                  }
                                },
                                {
                                  fieldType: 'checkbox',
                                  fieldId: 'wgc3j7',
                                  properties: {
                                    id: 'receiveEmails',
                                    name: 'receiveEmails',
                                    validation: {
                                      mandatory: {
                                        allowFalse: false
                                      }
                                    },
                                    grid: {
                                      columns: 12
                                    },
                                    styles: {
                                      color: '#525256',
                                      textAlign: 'justify'
                                    },
                                    colorSchema: 'var(--color-4)',
                                    label: `Ich bestätige, dass ich mindestens 18 Jahre alt bin und die IFM <a href="https://www.coraya.de/rechtliche-hinweise/" target="_blank"><u>Geschäftsbedingungen</u></a>, <a href="https://www.coraya.de/datenschutz-2/" target="_blank"><u>Datenschutzrichtlinien</u></a> & <a href="https://justsnap.de/Datenschutz" target="_blank"><u>JustSnap Datenschutzrichtlinien</u></a> akzeptiere.<br>Ihre Daten werden ausschließlich für die Durchführung der Pulled Chicken Gratis Testen Aktion verwendet und werden nach der Aktion von uns gelöscht.`
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          rowGap: '10px'
                        },
                        fields: [
                          {
                            fieldId: 'fu0923r',
                            fieldType: 'empty-bucket',
                            properties: {
                              grid: {
                                columns: 12,
                                justifyContent: 'center',
                                alignItems: 'center',
                                columnGap: '20px'
                              },
                              classes: 'pc-two-button-bucket',
                              fields: [
                                { // Back Button
                                  fieldType: 'button-component',
                                  fieldId: 'but100',
                                  properties: {
                                    grid: {
                                      columns: 3
                                    },
                                    text: 'Back',
                                    classes: 'btn-sec-back',
                                    action: {
                                      fn: 'goToPage',
                                      params: 'banking'
                                    },
                                    arrow: {
                                      prev: true,
                                      color: '#E42314'
                                    }
                                  }
                                },
                                { // Next Button
                                  fieldType: 'button-component',
                                  fieldId: 'but100',
                                  properties: {
                                    grid: {
                                      columns: 3
                                    },
                                    text: 'Next',
                                    classes: 'btn-sec-next',
                                    action: {
                                      fn: 'sendForm',
                                      params: 'guestForm'
                                    },
                                    arrow: {
                                      next: true,
                                      color: '#FFF'
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // THANK YOU
        pageName: 'thank-you',
        pageId: 113,
        sections: [
          {
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12
            },
            fields: [
              {
                fieldType: 'empty-bucket',
                fieldId: 'expiredImageBucket',
                properties: {
                  errorImages: false,
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    padding: '0px !important'
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    { // Container
                      fieldId: 'secondaryErrorBucket',
                      fieldType: 'empty-bucket',
                      properties: {
                        errorImages: false,
                        grid: {
                          columns: 12,
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '0px',
                          maxWidth: '100vw',
                          padding: '0px !important',
                          background: 'transparent !important'
                        },
                        styles: {
                          padding: '0px !important'
                        },
                        classes: 'form-top-wrapper',
                        fields: [
                          {
                            fieldId: 'secondaryErrorBucket',
                            fieldType: 'empty-bucket',
                            properties: {
                              errorImages: false,
                              grid: {
                                columns: 12,
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexWrap: 'nowrap',
                                padding: '0 !important'
                                // background: "transparent !important"
                              },
                              classes: 'form-top-wrapper',
                              fields: [
                                {
                                  fieldType: 'image-component',
                                  fieldId: 'sdjfgh',
                                  properties: {
                                    styles: {
                                      padding: '0 0 0 0',
                                      maxWidth: '100vw',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    },
                                    grid: {
                                      columns: 12
                                    },
                                    noContainer: true,
                                    desktopImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                    tabletImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                    mobileImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png'
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                fieldId: 'secondaryErrorBucket',
                fieldType: 'empty-bucket',
                properties: {
                  errorImages: false,
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '40px',
                    marginBottom: '0px',
                    maxWidth: '100vw',
                    padding: '0 !important',
                    background: 'transparent !important'
                  },
                  classes: 'form-top-wrapper',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'headerReclaimPage',
                      properties: {
                        text: 'Danke für die Teilnahme',
                        classes: 'page-heading text-center',
                        grid: {
                          columns: 10,
                          marginTop: '20px'
                        }
                      }
                    },
                    { // Subeading
                      fieldType: 'paragraph',
                      fieldId: 'subHeaderReclaimPage',
                      properties: {
                        text: 'Ihr Kassenbeleg und Ihre Daten wurden erfolgreich an uns übermittelt und werden überprüft.',
                        classes: 'page-subheading ',
                        grid: {
                          columns: 10,
                          marginTop: '20px'
                        }
                      }
                    },
                    { // Clipboard
                      fieldType: 'clipboard',
                      fieldId: 'ref123',
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: '30px',
                          marginTop: '60px',
                          justifyContent: 'flex-start'
                        },
                        title: 'Referenznummer:',
                        disabledInput: true,
                        variables: {
                          referenceNumber: 'referenceNumber'
                        }
                      }
                    },
                    { // Subeading
                      fieldType: 'paragraph',
                      fieldId: 'subHeaderReclaimPage',
                      properties: {
                        text: 'Sobald wir Ihre Daten überprüft haben, bekommen Sie entweder eine Bestätigungs-E-Mail oder eine E-Mail mit Rückfragen, falls Ihre Daten unvollständig waren oder Ihr Kaufbeleg nicht erfasst werden konnte. Dies kann bis zu 36 Stunden in Anspruch nehmen. Wir bitten um Ihre Geduld. Nach erfolgreicher Überprüfung des Kaufbelegs und aller weiteren Daten, überweisen wir den Erstattungsbetrag innerhalb von 4 Wochen auf das von Ihnen angegebene Konto. Sollten sich Unklarheiten ergeben, werden wir Sie per E-Mail nochmal separat kontaktieren.',
                        classes: 'page-subheading text-center',
                        grid: {
                          columns: 10,
                          marginTop: '20px'
                        },
                        styles: {
                          textAlign: 'justify !important'
                        }
                      }
                    },
                    {
                      fieldType: 'button-component',
                      fieldId: 'errorBackButton',
                      properties: {
                        text: 'ZURÜCK ZUR STARTSEITE',
                        grid: {
                          columns: 4,
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '25px',
                          marginBottom: '25px'
                        },
                        classes: 'pc-long-button-2',
                        disabled: false,
                        action: {
                          anchor: true,
                          link: '/'
                        },
                        styles: {
                          textTransform: 'none',
                          fontWeight: '400',
                          fontSize: '15px !important',
                          fontFamily: 'Source Sans Pro, sans-serif !important'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // RECLAIM PAGE
        pageName: 'reclaim',
        locale: 'de',
        pageId: 115,
        isPublic: true,
        sections: [
          { // Heading
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12
            },
            fields: [
              {
                fieldType: 'reclaim-bucket',
                fieldId: 'r3cla1m3uket',
                properties: {
                  grid: {
                    alignItems: 'center',
                    justifyContent: 'center',
                    columns: 12,
                    marginBottom: '50px'
                  },
                  colorSchema: '#014E2C',
                  endpoint: '/reclaim',
                  fields: [
                    {
                      fieldId: 'secondaryErrorBucket',
                      fieldType: 'empty-bucket',
                      properties: {
                        errorImages: false,
                        grid: {
                          columns: 12,
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '0px',
                          maxWidth: '100vw',
                          padding: '0 !important',
                          background: 'transparent !important'
                        },
                        classes: 'form-top-wrapper',
                        fields: [
                          {
                            fieldType: 'empty-bucket',
                            fieldId: 'expiredImageBucket',
                            properties: {
                              errorImages: false,
                              grid: {
                                columns: 12,
                                alignItems: 'center',
                                justifyContent: 'center',
                                maxWidth: '100vw',
                                padding: '0px !important'
                              },
                              classes: 'form-top-wrapper maxWidthOverride',
                              fields: [
                                { // Container
                                  fieldId: 'secondaryErrorBucket',
                                  fieldType: 'empty-bucket',
                                  properties: {
                                    errorImages: false,
                                    grid: {
                                      columns: 12,
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginBottom: '0px',
                                      maxWidth: '100vw',
                                      padding: '0px !important',
                                      background: 'transparent !important'
                                    },
                                    classes: 'form-top-wrapper',
                                    fields: [
                                      {
                                        fieldId: 'secondaryErrorBucket',
                                        fieldType: 'empty-bucket',
                                        properties: {
                                          errorImages: false,
                                          grid: {
                                            columns: 12,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: '100px',
                                            flexWrap: 'nowrap',
                                            padding: '0 !important',
                                            background: 'transparent !important'
                                          },
                                          classes: 'form-top-wrapper',
                                          fields: [
                                            {
                                              fieldType: 'image-component',
                                              fieldId: 'sdjfgh',
                                              properties: {
                                                styles: {
                                                  padding: '0 0 0 0',
                                                  maxWidth: '100vw',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center'
                                                },
                                                grid: {
                                                  columns: 12
                                                },
                                                noContainer: true,
                                                desktopImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                                tabletImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                                mobileImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png'
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                          { // Heading
                            fieldType: 'paragraph',
                            fieldId: 'headerReclaimPage',
                            properties: {
                              text: 'Vielen Dank für Ihre Benachrichtigung.',
                              classes: 'page-heading',
                              grid: {
                                alignItems: 'flex-end',
                                columns: 10,
                                marginTop: '20px'
                              }
                            }
                          },
                          { // Subeading
                            fieldType: 'paragraph',
                            fieldId: 'subHeaderReclaimPage',
                            properties: {
                              text: 'Wir werden den Erstattungsbetrag nochmals überprüfen und Sie anschließend informieren. Die Überprüfung kann einige Zeit in Anspruch nehmen, wir bitten um Ihre Geduld.',
                              classes: 'page-subheading',
                              grid: {
                                alignItems: 'flex-end',
                                columns: 10
                              }
                            }
                          },
                          {
                            fieldType: 'button-component',
                            fieldId: 'errorBackButton',
                            properties: {
                              text: 'Zurück zur Startseite',
                              grid: {
                                columns: 3,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '50px',
                                marginBottom: '75px'
                              },
                              classes: 'pc-long-button-2',
                              disabled: false,
                              action: {
                                anchor: true,
                                link: '/'
                              },
                              styles: {
                                textTransform: 'none',
                                fontWeight: '400'
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // T&C
        pageName: 'teilnahmebedingungen',
        locale: 'de',
        isPublic: true,
        sections: [
          {
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12,
              marginBottom: '30px'
            },
            fields: [
              {
                fieldType: 'pdf-viewer',
                fieldId: 'vabd69',
                properties: {
                  grid: {
                    alignItems: 'center',
                    justifyContent: 'center',
                    columns: 8
                  },
                  mainWrapperClass: 'embed-wrapper-no-padding',
                  link: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/pulled-chicken-gratis/terms.pdf'
                }
              }
            ]
          }
        ]
      },
      { // FAQ
        pageName: 'faq',
        locale: 'de',
        isPublic: true,
        sections: [
          {
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12,
              marginBottom: '30px'
            },
            fields: [
              {
                fieldType: 'pdf-viewer',
                fieldId: 'vabd69',
                properties: {
                  grid: {
                    alignItems: 'center',
                    justifyContent: 'center',
                    columns: 8
                  },
                  mainWrapperClass: 'embed-wrapper-no-padding',
                  link: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/pulled-chicken-gratis/faq.pdf'
                }
              }
            ]
          }
        ]
      },
      { // ERROR PAGE
        pageName: 'error-page',
        locale: 'de',
        pageId: 114,
        isPublic: true,
        sections: [
          { // Heading
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12,
              marginBottom: '1rem'
            },
            fields: [
              { // Container
                fieldId: 'secondaryErrorBucket',
                fieldType: 'empty-bucket',
                properties: {
                  errorImages: true,
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    paddingTop: '0 !important',
                    position: 'relative'
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'oopsErrorPage',
                      properties: {
                        text: `Hoppla!`,
                        classes: 'page-heading paragraphBg overrideMarginBot text-center',
                        grid: {
                          alignItems: 'center',
                          justifyContent: 'center',
                          columns: 6
                        },
                        styles: {
                          margin: '50px 0px 0px 50px',
                          color: '#4E4B66'
                        }
                      }
                    },
                    { // 404 image
                      fieldType: 'image-component',
                      fieldId: 'sdfdgjfgh',
                      properties: {
                        styles: {
                          padding: '0 0 0 0',
                          maxWidth: '100vw',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          maxHeight: '30vh',
                          marginTop: '21px'
                        },
                        grid: {
                          columns: 8
                        },
                        noContainer: true,
                        desktopImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/error-banner.png',
                        tabletImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/error-banner.png',
                        mobileImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/error-banner.png'
                      }
                    },
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'errorSubHeading',
                      properties: {
                        text: 'da ist etwas schief gelaufen. <br> Bitte versuche es erneut.',
                        classes: 'text text-center',
                        grid: {
                          alignItems: 'center',
                          justifyContent: 'center',
                          columns: 12,
                          marginTop: '60px'
                        },
                        styles: {
                          fontSize: '30px',
                          textTransform: 'uppercase',
                          fontFamily: 'Source Sans Pro, sans-serif',
                          lineHeight: '30px',
                          fontWeight: '400',
                          letterSpacing: '-1px',
                          color: '#4E4B66'
                        }
                      }
                    }
                  ]
                }
              },
              {
                fieldType: 'button-component',
                fieldId: 'errorBackButton',
                properties: {
                  text: 'Zurück zur STARTSEITE',
                  grid: {
                    columns: 3,
                    alignItems: 'center',
                    justifyContent: 'center'
                  },
                  classes: 'pc-long-button-2',
                  styles: {
                    fontWeight: '400',
                    textTransform: 'none',
                    margin: '0px 0 50px 0',
                    fontSize: '15px !important',
                    fontFamily: 'Source Sans Pro, sans-serif !important'
                  },
                  disabled: false,
                  action: {
                    anchor: true,
                    link: '/'
                  }
                }
              }
            ]
          }
        ]
      },
      { // EXPIRED
        pageName: 'expired-page',
        locale: 'de',
        pageId: 115,
        isPublic: true,
        sections: [
          { // Heading
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12
            },
            styles: {
              height: '100vh !important'
            },
            fields: [
              {
                fieldType: 'empty-bucket',
                fieldId: 'expiredImageBucket',
                properties: {
                  errorImages: false,
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    padding: '1vw 1vw'
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    { // Container
                      fieldId: 'secondaryErrorBucket',
                      fieldType: 'empty-bucket',
                      properties: {
                        errorImages: false,
                        grid: {
                          columns: 12,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          marginBottom: '0px',
                          maxWidth: '100vw',
                          padding: '2vw 4vw',
                          background: 'transparent !important'
                        },
                        classes: 'form-top-wrapper banner-responsive',
                        fields: [
                          {
                            fieldId: 'secondaryErrorBucket',
                            fieldType: 'empty-bucket',
                            properties: {
                              errorImages: false,
                              grid: {
                                columns: 6,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '00px',
                                marginBottom: '0px',
                                maxWidth: '100vw',
                                padding: '0 !important',
                                background: 'transparent !important'
                              },
                              classes: 'form-top-wrapper',
                              fields: [
                                { // Heading
                                  fieldType: 'paragraph',
                                  fieldId: 'headerExpiredPage',
                                  properties: {
                                    text: `This session has expired`,
                                    classes: 'page-heading',
                                    grid: {
                                      alignItems: 'flex-end',
                                      columns: 12,
                                      marginTop: '20px'
                                    }
                                  }
                                },
                                { // Heading
                                  fieldType: 'paragraph',
                                  fieldId: 'subHeaderExpiredPage',
                                  properties: {
                                    text: "Leider ist der Link nicht mehr gültig! Bitte nehmen Sie erneut teil oder kontaktieren Sie unser <br>Support Team: <a href='mailto:kontakt@pulled-chicken-gratis.de' style='text-decoration:underline; color:#0099FF!important;'>kontakt@pulled-chicken-gratis.de</a>",
                                    classes: 'page-subheading',
                                    grid: {
                                      alignItems: 'flex-end',
                                      columns: 12
                                    }
                                  }
                                }
                              ]
                            }
                          },
                          {
                            fieldId: 'secondaryErrorBucket',
                            fieldType: 'empty-bucket',
                            properties: {
                              errorImages: false,
                              grid: {
                                columns: 6,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '0vh',
                                marginBottom: '0px',
                                flexWrap: 'nowrap',
                                padding: '0 !important',
                                background: 'transparent !important'
                              },
                              classes: 'form-top-wrapper',
                              fields: [
                                {
                                  fieldType: 'image-component',
                                  fieldId: 'sdjfgh',
                                  properties: {
                                    styles: {
                                      padding: '0 0 0 0',
                                      maxWidth: '100vw',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignSelf: 'flex-end'
                                    },
                                    grid: {
                                      columns: 11
                                    },
                                    noContainer: true,
                                    desktopImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                    tabletImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                    mobileImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png'
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                fieldType: 'button-component',
                fieldId: 'errorBackButton',
                properties: {
                  text: 'Zurück zur STARTSEITE',
                  grid: {
                    columns: 3,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '50px',
                    marginBottom: '75px'
                  },
                  classes: 'pc-long-button-2',
                  disabled: false,
                  action: {
                    anchor: true,
                    link: '/'
                  },
                  styles: {
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '15px !important',
                    fontFamily: 'Source Sans Pro, sans-serif !important'
                  }
                }
              }
            ]
          }
        ]
      },
      { // CAMPAIGN IS OVER
        pageName: 'campaign-is-over',
        locale: 'de',
        pageId: 115,
        isPublic: true,
        sections: [
          { // Heading
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12
            },
            fields: [
              {
                fieldType: 'empty-bucket',
                fieldId: 'expiredImageBucket',
                properties: {
                  errorImages: false,
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    padding: '1vw 1vw'
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    { // Container
                      fieldId: 'secondaryErrorBucket',
                      fieldType: 'empty-bucket',
                      properties: {
                        errorImages: false,
                        grid: {
                          columns: 12,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          marginBottom: '0px',
                          maxWidth: '100vw',
                          padding: '2vw 4vw',
                          background: 'transparent !important'
                        },
                        classes: 'form-top-wrapper banner-responsive',
                        fields: [
                          {
                            fieldId: 'secondaryErrorBucket',
                            fieldType: 'empty-bucket',
                            properties: {
                              errorImages: false,
                              grid: {
                                columns: 6,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '00px',
                                marginBottom: '0px',
                                maxWidth: '100vw',
                                padding: '0 !important',
                                background: 'transparent !important'
                              },
                              classes: 'form-top-wrapper',
                              fields: [
                                { // Heading
                                  fieldType: 'paragraph',
                                  fieldId: 'headerExpiredPage',
                                  properties: {
                                    text: 'Der Aktionszeitraum ist vorbei',
                                    classes: 'page-heading',
                                    grid: {
                                      alignItems: 'flex-end',
                                      columns: 12,
                                      marginTop: '20px'
                                    }
                                  }
                                },
                                { // Heading
                                  fieldType: 'paragraph',
                                  fieldId: 'subHeaderExpiredPage',
                                  properties: {
                                    text: 'Es tut uns leid, Ihnen mitteilen zu müssen, dass die Pulled Chicken Gratis Testen Aktion bereits beendet ist.',
                                    classes: 'page-subheading',
                                    grid: {
                                      alignItems: 'flex-end',
                                      columns: 12
                                    }
                                  }
                                }
                              ]
                            }
                          },
                          {
                            fieldId: 'secondaryErrorBucket',
                            fieldType: 'empty-bucket',
                            properties: {
                              errorImages: false,
                              grid: {
                                columns: 6,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '0vh',
                                marginBottom: '0px',
                                flexWrap: 'nowrap',
                                padding: '0 !important',
                                background: 'transparent !important'
                              },
                              classes: 'form-top-wrapper',
                              fields: [
                                {
                                  fieldType: 'image-component',
                                  fieldId: 'sdjfgh',
                                  properties: {
                                    styles: {
                                      padding: '0 0 0 0',
                                      maxWidth: '100vw',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignSelf: 'flex-end'
                                    },
                                    grid: {
                                      columns: 11
                                    },
                                    noContainer: true,
                                    desktopImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                    tabletImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                    mobileImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png'
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                fieldType: 'button-component',
                fieldId: 'errorBackButton',
                properties: {
                  text: 'Zurück zur STARTSEITE',
                  grid: {
                    columns: 3,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '50px',
                    marginBottom: '75px'
                  },
                  classes: 'pc-long-button-2',
                  disabled: false,
                  action: {
                    anchor: true,
                    link: '/'
                  },
                  styles: {
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '15px !important',
                    fontFamily: 'Source Sans Pro, sans-serif !important'
                  }
                }
              }
            ]
          }
        ]
      },
      { // LIMIT REACHED
        pageName: 'participation-limit-reached',
        locale: 'de',
        pageId: 115,
        isPublic: true,
        sections: [
          { // Heading
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12
            },
            fields: [
              {
                fieldType: 'empty-bucket',
                fieldId: 'expiredImageBucket',
                properties: {
                  errorImages: false,
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    padding: '1vw 1vw'
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    { // Container
                      fieldId: 'secondaryErrorBucket',
                      fieldType: 'empty-bucket',
                      properties: {
                        errorImages: false,
                        grid: {
                          columns: 12,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          marginBottom: '0px',
                          maxWidth: '100vw',
                          padding: '2vw 4vw',
                          background: 'transparent !important'
                        },
                        classes: 'form-top-wrapper banner-responsive',
                        fields: [
                          {
                            fieldId: 'secondaryErrorBucket',
                            fieldType: 'empty-bucket',
                            properties: {
                              errorImages: false,
                              grid: {
                                columns: 6,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '00px',
                                marginBottom: '0px',
                                maxWidth: '100vw',
                                padding: '0 !important',
                                background: 'transparent !important'
                              },
                              classes: 'form-top-wrapper',
                              fields: [
                                { // Heading
                                  fieldType: 'paragraph',
                                  fieldId: 'headerExpiredPage',
                                  properties: {
                                    text: 'Die Teilnahmegrenze ist erreicht',
                                    classes: 'page-heading',
                                    grid: {
                                      alignItems: 'flex-end',
                                      columns: 12,
                                      marginTop: '20px'
                                    }
                                  }
                                },
                                { // Heading
                                  fieldType: 'paragraph',
                                  fieldId: 'subHeaderExpiredPage',
                                  properties: {
                                    text: 'Leider können Sie kein Tester für die Pulled Chicken-Kampagne sein, da die Teilnehmergrenze erreicht ist.',
                                    classes: 'page-subheading',
                                    grid: {
                                      alignItems: 'flex-end',
                                      columns: 12
                                    }
                                  }
                                }
                              ]
                            }
                          },
                          {
                            fieldId: 'secondaryErrorBucket',
                            fieldType: 'empty-bucket',
                            properties: {
                              errorImages: false,
                              grid: {
                                columns: 6,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '0vh',
                                marginBottom: '0px',
                                flexWrap: 'nowrap',
                                padding: '0 !important',
                                background: 'transparent !important'
                              },
                              classes: 'form-top-wrapper',
                              fields: [
                                {
                                  fieldType: 'image-component',
                                  fieldId: 'sdjfgh',
                                  properties: {
                                    styles: {
                                      padding: '0 0 0 0',
                                      maxWidth: '100vw',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignSelf: 'flex-end'
                                    },
                                    grid: {
                                      columns: 11
                                    },
                                    noContainer: true,
                                    desktopImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                    tabletImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                    mobileImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png'
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                fieldType: 'button-component',
                fieldId: 'errorBackButton',
                properties: {
                  text: 'Zurück zur Startseite',
                  grid: {
                    columns: 3,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '50px',
                    marginBottom: '75px'
                  },
                  classes: 'pc-long-button-2',
                  disabled: false,
                  action: {
                    anchor: true,
                    link: '/'
                  },
                  styles: {
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '15px !important',
                    fontFamily: 'Source Sans Pro, sans-serif !important'
                  }
                }
              }
            ]
          }
        ]
      },
      { // DOI PAGE
        pageName: 'doi',
        locale: 'de',
        isPublic: true,
        pageId: 992,
        sections: [
          // DOI CHECK
          {
            sectionId: 'asd3456hasd6',
            fields: [
              {
                fieldType: 'doi',
                fieldId: 'doiasdasda',
                properties: {
                  endpoint: '/checkDoi'
                }
              }
            ]
          },
          {
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12,
              // 'maxWidth': '1270px',
              margin: 'auto'
            },
            fields: [
              {
                fieldType: 'empty-bucket',
                fieldId: '05m65ds',
                properties: {
                  grid: {
                    columns: 12,
                    marginBottom: '50px'
                  },
                  className: 'col-sm-12',
                  styles: {
                    padding: 0,
                    flexDirection: 'column',
                    alignItems: 'center'
                  },
                  fields: [
                    {
                      fieldType: 'empty-bucket',
                      fieldId: 'expiredImageBucket',
                      properties: {
                        errorImages: false,
                        grid: {
                          columns: 12,
                          alignItems: 'center',
                          justifyContent: 'center',
                          maxWidth: '100vw',
                          padding: '1vw 1vw'
                        },
                        classes: 'form-top-wrapper maxWidthOverride',
                        fields: [
                          { // Container
                            fieldId: 'secondaryErrorBucket',
                            fieldType: 'empty-bucket',
                            properties: {
                              errorImages: false,
                              grid: {
                                columns: 12,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                marginBottom: '0px',
                                maxWidth: '100vw',
                                padding: '2vw 4vw',
                                background: 'transparent !important'
                              },
                              classes: 'form-top-wrapper',
                              fields: [
                                {
                                  fieldId: 'secondaryErrorBucket',
                                  fieldType: 'empty-bucket',
                                  properties: {
                                    errorImages: false,
                                    grid: {
                                      columns: 12,
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginTop: '00px',
                                      marginBottom: '0px',
                                      maxWidth: '100vw',
                                      padding: '0 !important',
                                      background: 'transparent !important'
                                    },
                                    classes: 'form-top-wrapper',
                                    fields: [
                                      { // Heading
                                        fieldType: 'paragraph',
                                        fieldId: 'headerExpiredPage',
                                        properties: {
                                          text: '<center>Danke für Deine Bestätigung!</center>',
                                          classes: 'page-heading',
                                          grid: {
                                            alignItems: 'flex-end',
                                            columns: 12,
                                            marginTop: '20px'
                                          }
                                        }
                                      },
                                      { // Heading
                                        fieldType: 'paragraph',
                                        fieldId: 'subHeaderExpiredPage',
                                        properties: {
                                          text: 'Wir werden Dir in Zukunft leckere Rezeptideen und Inspirationen rund um Käse zukommen lassen. Wir veranstalten auch regelmäßig spannende Gewinnspiele mit tollen Preisen - ein echter Hit für alle Käseliebhaber!',
                                          classes: 'page-subheading',
                                          grid: {
                                            alignItems: 'flex-end',
                                            columns: 12
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  fieldId: 'secondaryErrorBucket',
                                  fieldType: 'empty-bucket',
                                  properties: {
                                    errorImages: false,
                                    grid: {
                                      columns: 6,
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginTop: '0vh',
                                      marginBottom: '0px',
                                      flexWrap: 'nowrap',
                                      padding: '0 !important',
                                      background: 'transparent !important'
                                    },
                                    classes: 'form-top-wrapper',
                                    fields: [
                                      {
                                        fieldType: 'image-component',
                                        fieldId: 'sdjfgh',
                                        properties: {
                                          styles: {
                                            padding: '0 0 0 0',
                                            maxWidth: '100vw',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignSelf: 'flex-end'
                                          },
                                          grid: {
                                            columns: 11
                                          },
                                          noContainer: true,
                                          desktopImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                          tabletImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                          mobileImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png'
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          {
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12
              //"padding": "0 0 80px 0"
            },
            fields: [
              // { // Key Visual
              //   "fieldType": "key-visual",
              //   "fieldId": "296eg1",
              //   "properties": {
              //     "grid": {
              //       "columns": "12"
              //     },
              //     "styles": {
              //       "padding": "0 0 0 0"
              //     },
              //     "noContainer": true,
              //     "desktopImageUrl": "assets/keyVisual/keyVisual.jpg",
              //     "tabletImageUrl": "assets/keyVisual/keyVisual.jpg",
              //     "mobileImageUrl": "assets/keyVisual/keyVisual.jpg"
              //   }
              // },
              // { // Go to Lenor website button
              //   "fieldType": "button-component",
              //   "fieldId": "oql2de",
              //   "properties": {
              //     "text": "Zurück zur ISLOS Startseite",
              //     "grid": {
              //       "columns": "4",
              //       "alignItems": "center",
              //       "justifyContent": "center"
              //     },
              //     styles: {
              //       "max-width": "400px;",
              //       "margin": "40px auto 0px auto"
              //     },
              //     "disabled": false,
              //     "addClass": "nextBtn",
              //     "action": {
              //       fn: "goToPage",
              //       params: "home"
              //     }
              //   }
              // }
              {
                fieldType: 'button-component',
                fieldId: 'errorBackButton',
                properties: {
                  text: 'Zurück zur STARTSEITE',
                  grid: {
                    columns: 3,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '50px',
                    marginBottom: '75px'
                  },
                  classes: 'pc-long-button-2',
                  disabled: false,
                  action: {
                    anchor: true,
                    link: '/'
                  },
                  styles: {
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '15px !important',
                    fontFamily: 'Source Sans Pro, sans-serif !important'
                  }
                }
              }
            ]
          }
        ]
      },
      { // RECEIPT + PRODUCT RE-UPLOAD
        pageName: 'receipt-product-reupload',
        locale: 'de',
        isPublic: true,
        pageId: 119,
        sections: [
          { // Section
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center'
            },
            sectionId: 'fu30zq',
            fields: [
              { // Query String
                fieldType: 'query-string-to-state',
                fieldId: 'uwxo2o1',
                properties: {
                  variables: [
                    {
                      query: 'referenceNumber',
                      state: 'referenceNumber'
                    },
                    {
                      query: 'token',
                      state: 'receiptToken'
                    }
                  ]
                }
              },
              { // 72 Hours check
                fieldType: 'checkReceiptToken',
                fieldId: 'dksnfsdkjf',
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    paddingTop: 0
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginTop: '60px'
                        },
                        text: 'Kaufbeleg + Produktfoto mit Aktionssticker hochladen',
                        classes: 'page-heading paragraphBg overrideMarginBot'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '15px'
                        },
                        text: 'Bitte laden Sie ein Foto Ihres Kassenbons zusammen mit dem Deckel des teilnehmenden Produkts hoch. Achten Sie darauf, dass der Werbeaufkleber auf dem Deckel gut sichtbar ist.',
                        classes: 'page-subheading paragraphBg addPadding'
                      }
                    },
                    { // Receipt uploader
                      fieldType: 'empty-bucket',
                      fieldId: 'h453eadaf',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        styles: {
                          gap: '50px',
                          marginTop: '30px',
                          marginBottom: '30px',
                          maxWidth: '85%',
                          flexWrap: 'nowrap',
                          borderRadius: '20px',
                          justifyContent: 'center',
                          backgroundColor: 'rgba(255, 255, 255, 0.4)',
                          padding: '0 !important',
                          color: '#828282'
                        },
                        classes: 'banner-responsive',
                        fields: [
                          { // Recipt upload
                            fieldType: 'receipt-processor',
                            fieldId: 'klvakj',
                            properties: {
                              grid: {
                                columns: 5,
                                alignItems: 'center',
                                justifyContent: 'center'
                              },
                              styles: {
                                maxHeight: '540px',
                                backgroundColor: '#FFF',
                                borderRadius: '30px',
                                padding: '12px !important'
                              },
                              reUpload: true,
                              successAction: {
                                fn: 'goToPage',
                                params: 'receipt-thank-you'
                              },
                              texts: {
                                choose: 'Choose a file',
                                text1: 'Click here to upload the receipt and product photo',
                                text2: 'Only JPEG, PNG or PDF. Maximum 16MB',
                                wrongFormat: 'Wrong format receipt',
                                fileSizeLimit: 'File too big',
                                upload: 'Hochladen'
                              },
                              editTexts: {
                                crop: 'Crop',
                                erase: 'Erase',
                                drag: 'Drag',
                                zoomIn: 'Zoom In',
                                zoomOut: 'Zoom Out'
                              },
                              mainWrapperClass: 'pc-receipt-processor',
                              colorSchema: '#303030',
                              phrases: [
                                'Please erase your personal data with the eraser tool.',
                                'Please frame the receipt / invoice in the gray rectangle.'
                              ],
                              cornerDetectionPhrases: [
                                'Please erase your personal data with the eraser tool.',
                                'Please frame the receipt / invoice in the gray rectangle.'
                              ],
                              convertToBlackWhite: false
                            }
                          },
                          { // Receipt Info
                            fieldType: 'empty-bucket',
                            fieldId: 'vdf932fd',
                            properties: {
                              grid: {
                                columns: 7
                              },
                              classes: 'addMarginLeft',
                              fields: [
                                {
                                  fieldType: 'receipt-information',
                                  fieldId: '5mq1p6',
                                  properties: {
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: 12
                                    },
                                    mainWrapperClass: 'pc-receipt-information',
                                    shouldDisplayFull: true,
                                    descriptionText: 'Die folgenden Informationen müssen deutlich lesbar sein.',
                                    descriptionSubText: 'Zerknitterte Belege können nicht akzeptiert werden.',
                                    hideExamples: true,
                                    showProduct: true,
                                    steps: [
                                      { // Retailer
                                        text: 'Retailer / Shop',
                                        label: 'A'
                                      },
                                      {
                                        text: 'Campaign product and its price',
                                        label: 'B'
                                      },
                                      {
                                        text: 'Total sum of the receipt / invoice and applied VAT',
                                        label: 'C'
                                      },
                                      {
                                        text: 'Date and time of purchase',
                                        label: 'D'
                                      },
                                      {
                                        text: 'Receipt / Invoice ID (not the bank transaction ID)',
                                        label: 'E'
                                      },
                                      {
                                        text: 'Sticker',
                                        label: 'F'
                                      }
                                    ]
                                  }
                                },
                                { // Receipt Examples
                                  fieldType: 'empty-bucket',
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      margin: '0px auto'
                                    },
                                    classes: 'receiptUploader',
                                    fields: [
                                      {
                                        fieldType: 'receipt-examples',
                                        fieldId: '5mq1p6',
                                        properties: {
                                          grid: {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            columns: 12
                                          },
                                          mainWrapperClass: 'pc-receipt-examples',
                                          showProduct: true
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px'
                        },
                        classes:
                          'btn-bucket pc-two-button-bucket m-2rem-desktop-only',
                        fields: [
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: 'Next',
                              classes: 'btn-sec-next',
                              requires: ['klvakj'],
                              action: [
                                {
                                  fn: 'sendReceipt',
                                  params: 'klvakj'
                                }
                              ],
                              arrow: {
                                next: true,
                                color: '#FFF'
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // RECEIPT RE-UPLOAD THANK YOU
        pageName: 'receipt-thank-you',
        pageId: 113,
        sections: [
          {
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12
            },
            fields: [
              {
                fieldType: 'empty-bucket',
                fieldId: 'expiredImageBucket',
                properties: {
                  errorImages: false,
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    padding: '0px !important'
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    { // Container
                      fieldId: 'secondaryErrorBucket',
                      fieldType: 'empty-bucket',
                      properties: {
                        errorImages: false,
                        grid: {
                          columns: 12,
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '0px',
                          maxWidth: '100vw',
                          padding: '0px !important',
                          background: 'transparent !important'
                        },
                        classes: 'form-top-wrapper',
                        fields: [
                          {
                            fieldId: 'secondaryErrorBucket',
                            fieldType: 'empty-bucket',
                            properties: {
                              errorImages: false,
                              grid: {
                                columns: 12,
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexWrap: 'nowrap',
                                padding: '0 !important',
                                background: 'transparent !important'
                              },
                              classes: 'form-top-wrapper',
                              fields: [
                                {
                                  fieldType: 'image-component',
                                  fieldId: 'sdjfgh',
                                  properties: {
                                    styles: {
                                      padding: '0 0 0 0',
                                      maxWidth: '100vw',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    },
                                    grid: {
                                      columns: 12
                                    },
                                    noContainer: true,
                                    desktopImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                    tabletImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                    mobileImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png'
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                fieldId: 'secondaryErrorBucket',
                fieldType: 'empty-bucket',
                properties: {
                  errorImages: false,
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '40px',
                    marginBottom: '0px',
                    maxWidth: '100vw',
                    padding: '0 !important',
                    background: 'transparent !important'
                  },
                  classes: 'form-top-wrapper',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'headerReclaimPage',
                      properties: {
                        text: 'Danke für die Teilnahme',
                        classes: 'page-heading text-center',
                        grid: {
                          columns: 10,
                          marginTop: '20px'
                        }
                      }
                    },
                    { // Subeading
                      fieldType: 'paragraph',
                      fieldId: 'subHeaderReclaimPage',
                      properties: {
                        text: "Vielen Dank, dass Sie Ihren Kaufbeleg erneut eingereicht haben. Sollten Sie Fragen oder sonstige Anliegen haben, können Sie unseren Support Service unter <a href='mailto:kontakt@pulled-chicken-gratis.de' style='color: #0099FF !important; text-decoration: underline;'>kontakt@pulled-chicken-gratis.de</a> kontaktieren.",
                        classes: 'page-subheading text-center',
                        grid: {
                          columns: 10,
                          marginTop: '20px'
                        }
                      }
                    },
                    {
                      fieldType: 'button-component',
                      fieldId: 'errorBackButton',
                      properties: {
                        text: 'Zurück zur STARTSEITE',
                        grid: {
                          columns: 4,
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '50px',
                          marginBottom: '75px'
                        },
                        classes: 'pc-long-button-2',
                        disabled: false,
                        action: {
                          anchor: true,
                          link: '/'
                        },
                        styles: {
                          textTransform: 'none',
                          fontWeight: '400',
                          fontSize: '15px !important',
                          fontFamily: 'Source Sans Pro, sans-serif !important'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // PRODUCT RE-UPLOAD
        pageName: 'product-reupload',
        isPublic: true,
        locale: 'de',
        pageId: 121,
        sections: [
          { // Query and Heading
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center'
            },
            sectionId: 'vgw364bh',
            fields: [
              { // Query
                fieldType: 'query-string-to-state',
                fieldId: 'uwxo2o1',
                properties: {
                  variables: [
                    {
                      query: 'referenceNumber',
                      state: 'referenceNumber'
                    },
                    {
                      query: 'token',
                      state: 'receiptToken'
                    }
                  ]
                }
              },
              { // 72 Hours check
                fieldType: 'checkReceiptToken',
                fieldId: 'dksnfsdkjf',
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    paddingTop: '0 !important'
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginTop: '40px'
                        },
                        text: 'Lade ein Produktfoto hoch.',
                        classes: 'page-heading paragraphBg overrideMarginBot'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '15px'
                        },
                        text: 'Bitte laden Sie das Foto Ihres Produkts hoch.',
                        classes: 'page-subheading paragraphBg addPadding'
                      }
                    },
                    { // Product uploader
                      fieldType: 'empty-bucket',
                      fieldId: 'h453eadaf',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        styles: {
                          marginTop: '30px',
                          marginBottom: '30px',
                          maxWidth: '85%',
                          padding: '0 !important',
                          color: '#828282',
                          display: 'flex',
                          justifyContent: 'center'
                        },
                        fields: [
                          { // Product upload
                            fieldType: 'product-upload',
                            fieldId: '3sxum1',
                            properties: {
                              grid: {
                                columns: 6,
                                alignItems: 'center',
                                justifyContent: 'center'
                              },
                              styles: {
                                minHeight: '450px',
                                backgroundColor: '#FFF',
                                borderRadius: '20px',
                                padding: '12px !important'
                              },
                              colorSchema: '#828282',
                              texts: {
                                choose: 'Choose a file',
                                text1:
                                  'Hier klicken, um ein Produktfoto hochzuladen',
                                text2: 'Only JPEG or PNG. Maximum 16MB',
                                wrongFormat: 'Wrong format',
                                fileSizeLimit: 'File too big'
                              },
                              reUpload: true,
                              endpoint: '/reUploadProductPhoto',
                              productPhotoIndex: 0,
                              successAction: {
                                fn: 'goToPage',
                                params: 'product-thank-you'
                              }
                            }
                          },
                          {
                            fieldType: 'empty-bucket',
                            fieldId: 'h453eadaf',
                            properties: {
                              grid: {
                                columns: 6
                              },
                              styles: {
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'row',
                                alignContent: 'center'
                              },
                              fields: [
                                { // Heading
                                  fieldType: 'paragraph',
                                  fieldId: 'par1001',
                                  properties: {
                                    grid: {
                                      columns: 12
                                    },
                                    text: 'Stellen Sie sicher, dass das Produktfoto sichtbar ist',
                                    styles: {
                                      lineHeight: '30px',
                                      fontWeight: 700,
                                      textTransform: 'uppercase',
                                      color: '#303030',
                                      textAlign: 'center',
                                      padding: '0 !important',
                                      maxWidth: '75%',
                                      maxHeight: 'min-content'
                                    }
                                  }
                                },
                                { // Receipt Info
                                  fieldType: 'image-component',
                                  fieldId: 'sdjfgh',
                                  properties: {
                                    styles: {
                                      padding: '0 0 0 0',
                                      maxWidth: '100vw',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    },
                                    grid: {
                                      columns: 7
                                    },
                                    noContainer: true,
                                    desktopImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/product-example.png',
                                    tabletImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/product-example.png',
                                    mobileImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/product-example.png'
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px',
                          maxWidth: '48vw'
                        },
                        classes: 'btn-bucket pc-two-button-bucket',
                        fields: [
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 4
                              },
                              text: 'Next',
                              classes: 'btn-sec-next',
                              requires: ['3sxum1'],
                              action: {
                                fn: 'sendProductPhotos',
                                params: '3sxum1'
                              },
                              arrow: {
                                next: true,
                                color: '#FFF'
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // PRODUCT RE-UPLOAD THANK YOU
        pageName: 'product-thank-you',
        pageId: 113,
        sections: [
          {
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12
            },
            fields: [
              {
                fieldType: 'empty-bucket',
                fieldId: 'expiredImageBucket',
                properties: {
                  errorImages: false,
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    padding: '0px !important'
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    { // Container
                      fieldId: 'secondaryErrorBucket',
                      fieldType: 'empty-bucket',
                      properties: {
                        errorImages: false,
                        grid: {
                          columns: 12,
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '0px',
                          maxWidth: '100vw',
                          padding: '0px !important',
                          background: 'transparent !important'
                        },
                        classes: 'form-top-wrapper',
                        fields: [
                          {
                            fieldId: 'secondaryErrorBucket',
                            fieldType: 'empty-bucket',
                            properties: {
                              errorImages: false,
                              grid: {
                                columns: 12,
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexWrap: 'nowrap',
                                padding: '0 !important',
                                background: 'transparent !important'
                              },
                              classes: 'form-top-wrapper',
                              fields: [
                                {
                                  fieldType: 'image-component',
                                  fieldId: 'sdjfgh',
                                  properties: {
                                    styles: {
                                      padding: '0 0 0 0',
                                      maxWidth: '100vw',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    },
                                    grid: {
                                      columns: 12
                                    },
                                    noContainer: true,
                                    desktopImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                    tabletImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                    mobileImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png'
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                fieldId: 'secondaryErrorBucket',
                fieldType: 'empty-bucket',
                properties: {
                  errorImages: false,
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '40px',
                    marginBottom: '0px',
                    maxWidth: '100vw',
                    padding: '0 !important',
                    background: 'transparent !important'
                  },
                  classes: 'form-top-wrapper',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'headerReclaimPage',
                      properties: {
                        text: 'Danke für die Teilnahme',
                        classes: 'page-heading text-center',
                        grid: {
                          columns: 10,
                          marginTop: '20px'
                        }
                      }
                    },
                    { // Subeading
                      fieldType: 'paragraph',
                      fieldId: 'subHeaderReclaimPage',
                      properties: {
                        text: "Vielen Dank für die Übermittlung Ihres Produktfotos. Sollten Sie Fragen oder sonstige Anliegen haben, können Sie unseren Support Service unter <a href='mailto:kontakt@pulled-chicken-gratis.de' style='color: #0099FF !important; text-decoration: underline;'>kontakt@pulled-chicken-gratis.de</a> kontaktieren.",
                        classes: 'page-subheading text-center',
                        grid: {
                          columns: 10,
                          marginTop: '20px'
                        }
                      }
                    },
                    {
                      fieldType: 'button-component',
                      fieldId: 'errorBackButton',
                      properties: {
                        text: 'Zurück zur STARTSEITE',
                        grid: {
                          columns: 4,
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '50px',
                          marginBottom: '75px'
                        },
                        classes: 'pc-long-button-2',
                        disabled: false,
                        action: {
                          anchor: true,
                          link: '/'
                        },
                        styles: {
                          textTransform: 'none',
                          fontWeight: '400',
                          fontSize: '15px !important',
                          fontFamily: 'Source Sans Pro, sans-serif !important'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // RECEIPT RE-UPLOAD
        pageName: 'receipt-reupload',
        isPublic: true,
        locale: 'de',
        pageId: 121123,
        sections: [
          { // Query and Heading
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center'
            },
            sectionId: 'vgw364bh',
            fields: [
              { // Query
                fieldType: 'query-string-to-state',
                fieldId: 'uwxo2o1',
                properties: {
                  variables: [
                    {
                      query: 'referenceNumber',
                      state: 'referenceNumber'
                    },
                    {
                      query: 'token',
                      state: 'receiptToken'
                    }
                  ]
                }
              },
              { // 72 Hours check
                fieldType: 'checkReceiptToken',
                fieldId: 'dksnfsdkjf',
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    paddingTop: '0 !important'
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginTop: '40px'
                        },
                        text: 'Kaufbeleg hochladen',
                        classes: 'page-heading paragraphBg overrideMarginBot'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '15px'
                        },
                        text: 'Bitte laden Sie in diesem Schritt den Kaufbeleg Ihres Aktionsproduktes hoch.',
                        classes: 'page-subheading paragraphBg addPadding'
                      }
                    },
                    { // Product uploader
                      fieldType: 'empty-bucket',
                      fieldId: 'h453eadaf',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        styles: {
                          marginTop: '30px',
                          marginBottom: '30px',
                          maxWidth: '85%',
                          padding: '0 !important',
                          color: '#828282',
                          display: 'flex',
                          justifyContent: 'center'
                        },
                        fields: [
                          { // Product upload
                            fieldType: 'product-upload',
                            fieldId: '3sxum1',
                            properties: {
                              grid: {
                                columns: 5,
                                alignItems: 'center',
                                justifyContent: 'center'
                              },
                              styles: {
                                minHeight: '450px',
                                backgroundColor: '#FFF',
                                borderRadius: '20px',
                                padding: '12px !important'
                              },
                              colorSchema: '#828282',
                              texts: {
                                choose: 'Choose a file',
                                text1: 'Hier klicken, um den Kaufbeleg hochzuladen',
                                text2: 'Only JPEG or PNG. Maximum 16MB',
                                wrongFormat: 'Wrong format',
                                fileSizeLimit: 'File too big'
                              },
                              reUpload: true,
                              endpoint: '/reUploadProductPhoto',
                              productPhotoIndex: 1,
                              successAction: {
                                fn: 'goToPage',
                                params: 'receipt-thank-you'
                              }
                            }
                          },
                          { // Receipt Info
                            fieldType: 'empty-bucket',
                            fieldId: 'vdf932fd',
                            properties: {
                              grid: {
                                columns: 7
                              },
                              classes: 'addMarginLeft',
                              fields: [
                                {
                                  fieldType: 'receipt-information',
                                  fieldId: '5mq1p6',
                                  properties: {
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: 12
                                    },
                                    mainWrapperClass: 'pc-receipt-information',
                                    shouldDisplayFull: true,
                                    descriptionText: 'Die folgenden Informationen müssen deutlich lesbar sein.',
                                    descriptionSubText: 'Zerknitterte Belege können nicht akzeptiert werden.',
                                    hideExamples: true,
                                    showProduct: false,
                                    steps: [
                                      { // Retailer
                                        text: 'Retailer / Shop',
                                        label: 'A'
                                      },
                                      {
                                        text: 'Campaign product and its price',
                                        label: 'B'
                                      },
                                      {
                                        text: 'Total sum of the receipt / invoice and applied VAT',
                                        label: 'C'
                                      },
                                      {
                                        text: 'Date and time of purchase',
                                        label: 'D'
                                      },
                                      {
                                        text: 'Receipt / Invoice ID (not the bank transaction ID)',
                                        label: 'E'
                                      }
                                    ]
                                  }
                                },
                                { // Receipt Examples
                                  fieldType: 'empty-bucket',
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      margin: '0px auto'
                                    },
                                    classes: 'receiptUploader',
                                    fields: [
                                      {
                                        fieldType: 'receipt-examples',
                                        fieldId: '5mq1p6',
                                        properties: {
                                          grid: {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            columns: 12
                                          },
                                          mainWrapperClass: 'pc-receipt-examples'
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px',
                          maxWidth: '48vw'
                        },
                        classes: 'btn-bucket pc-two-button-bucket',
                        fields: [
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 4
                              },
                              text: 'Next',
                              classes: 'btn-sec-next',
                              requires: ['3sxum1'],
                              action: {
                                fn: 'sendProductPhotos',
                                params: '3sxum1'
                              },
                              arrow: {
                                next: true,
                                color: '#FFF'
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // IBAN REUPLOAD
        pageName: 'iban-reupload',
        locale: 'de',
        isPublic: true,
        pageId: 106,
        requires: [1],
        sections: [
          { // Section
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              columns: 12
            },
            fields: [
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    paddingTop: '0 !important'
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    { // Iban reupload
                      fieldType: 'iban-reupload',
                      fieldId: 'ij3411',
                      properties: {
                        grid: { columns: 12 },
                        endpoint: '/ibanUpdateCheck'
                      }
                    },
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginTop: '40px'
                        },
                        text: 'Bankverbindung eingeben',
                        classes: 'page-heading overrideMarginBot paragraphBg'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '40px'
                        },
                        text: 'Bitte teilen Sie uns mit, auf welches Konto der Erstattungsbetrag überwiesen werden soll.',
                        classes:
                          'page-subheading paragraphBg addPadding overrideMarginBot'
                      }
                    },
                    {
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 9,
                          justifyContent: 'center',
                          maxWidth: '85vw',
                          paddingTop: '50px !important'
                        },
                        fields: [
                          {
                            fieldId: 'fu0923r',
                            fieldType: 'empty-bucket',
                            properties: {
                              grid: {
                                columns: 12,
                                alignItems: 'center',
                                justifyContent: 'center',
                                maxWidth: '85vw',
                                paddingTop: '0 !important'
                              },
                              fields: [
                                { // Form
                                  fieldType: 'form-component',
                                  fieldId: 'bank1Nf0',
                                  properties: {
                                    successAction: {
                                      fn: 'goToPage',
                                      params: 'iban-thank-you'
                                    },
                                    endpoint: '/updateIBANBigBoy',
                                    keepInState: ['referenceNumber'],
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: 12
                                    },
                                    styles: {
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      backgroundColor: 'white',
                                      padding: '2vw',
                                      borderRadius: '20px',
                                      color: 'black',
                                      boxShadow:
                                        '0px 4px 10px rgba(132, 132, 132, 0.65)'
                                    },
                                    fields: [
                                      {
                                        fieldType: 'empty-bucket',
                                        fieldId: 'f934if',
                                        properties: {
                                          grid: {
                                            columns: 12
                                          },
                                          fields: [
                                            {
                                              fieldId: 'tp6zfb',
                                              fieldType: 'text-input',
                                              properties: {
                                                id: 'firstname',
                                                name: 'firstname',
                                                label: 'Vorname Kontoinhaber*',
                                                noLabel: true,
                                                placeholder:
                                                  'First name of the account holder*',
                                                validation:
                                                  'required|namesWithHyphensAndSpaces',
                                                text: '',
                                                grid: {
                                                  columns: 6
                                                }
                                              }
                                            },
                                            {
                                              fieldId: 'p55fao',
                                              fieldType: 'text-input',
                                              properties: {
                                                id: 'lastname',
                                                name: 'lastname',
                                                validation:
                                                  'required|namesWithHyphensAndSpaces',
                                                label: 'Nachname Kontoinhaber*',
                                                noLabel: true,
                                                placeholder:
                                                  'Last name of the account holder*',
                                                text: '',
                                                grid: {
                                                  columns: 6
                                                }
                                              }
                                            },
                                            {
                                              fieldId: 'h9arlr',
                                              fieldType: 'text-input',
                                              properties: {
                                                id: 'iban',
                                                name: 'iban',
                                                validation:
                                                  'required|iban|sepa',
                                                label: 'IBAN*',
                                                noLabel: false,
                                                labelOnTop: false,
                                                placeholder: 'IBAN*',
                                                text: '',
                                                grid: {
                                                  columns: 6
                                                }
                                              }
                                            },
                                            {
                                              fieldId: 'v1nf34',
                                              fieldType: 'text-input',
                                              properties: {
                                                id: 'bic',
                                                name: 'bic',
                                                label: 'BIC*',
                                                noLabel: false,
                                                labelOnTop: false,
                                                placeholder: 'BIC*',
                                                validation: 'required|bic',
                                                text: '',
                                                grid: {
                                                  columns: 6
                                                }
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                { // Subheading
                                  fieldType: 'paragraph',
                                  fieldId: 'par1001',
                                  properties: {
                                    grid: {
                                      columns: 12
                                    },
                                    text: '* Vermeiden Sie eine Änderung bei den Umlauten: <b>Müller bzw. Mueller.</b> Vermeiden Sie ein Hinzufügen oder Weglassen von Bindestrichen: <b>Marie Luise bzw. Marie-Luise.</b> Geben Sie den zweiten Vornamen nur dann an, wenn auch das Konto so geführt wird. Sollten die Daten nicht mit den Kontodaten übereinstimmen, kommt es in den meisten Fällen zu Rückbuchungen seitens der Bank und erschwert den Ablauf einer unkomplizierten und raschen Rückerstattung.',
                                    styles: {
                                      fontSize: '14px',
                                      color: '#303030',
                                      marginTop: '30px',
                                      textAlign: 'justify'
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 12,
                          margin: '35px 0 30px 0',
                          maxWidth: '48vw'
                        },
                        classes: 'btn-bucket pc-two-button-bucket',
                        fields: [
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 4
                              },
                              text: 'Next',
                              classes: 'btn-sec-next',
                              action: {
                                fn: 'sendForm',
                                params: 'bank1Nf0'
                              },
                              arrow: {
                                next: true,
                                color: '#FFF'
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // IBAN RE-UPLOAD THANK YOU
        pageName: 'iban-thank-you',
        pageId: 113,
        sections: [
          {
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12
            },
            fields: [
              {
                fieldType: 'empty-bucket',
                fieldId: 'expiredImageBucket',
                properties: {
                  errorImages: false,
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '100vw',
                    padding: '0px !important'
                  },
                  classes: 'form-top-wrapper maxWidthOverride',
                  fields: [
                    { // Container
                      fieldId: 'secondaryErrorBucket',
                      fieldType: 'empty-bucket',
                      properties: {
                        errorImages: false,
                        grid: {
                          columns: 12,
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '0px',
                          maxWidth: '100vw',
                          padding: '0px !important',
                          background: 'transparent !important'
                        },
                        classes: 'form-top-wrapper',
                        fields: [
                          {
                            fieldId: 'secondaryErrorBucket',
                            fieldType: 'empty-bucket',
                            properties: {
                              errorImages: false,
                              grid: {
                                columns: 12,
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexWrap: 'nowrap',
                                padding: '0 !important',
                                background: 'transparent !important'
                              },
                              classes: 'form-top-wrapper',
                              fields: [
                                {
                                  fieldType: 'image-component',
                                  fieldId: 'sdjfgh',
                                  properties: {
                                    styles: {
                                      padding: '0 0 0 0',
                                      maxWidth: '100vw',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    },
                                    grid: {
                                      columns: 12
                                    },
                                    noContainer: true,
                                    desktopImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                    tabletImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
                                    mobileImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png'
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                fieldId: 'secondaryErrorBucket',
                fieldType: 'empty-bucket',
                properties: {
                  errorImages: false,
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '40px',
                    marginBottom: '0px',
                    maxWidth: '100vw',
                    padding: '0 !important',
                    background: 'transparent !important'
                  },
                  classes: 'form-top-wrapper',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'headerReclaimPage',
                      properties: {
                        text: 'Danke für die Teilnahme',
                        classes: 'page-heading text-center',
                        grid: {
                          columns: 10,
                          marginTop: '20px'
                        }
                      }
                    },
                    { // Subeading
                      fieldType: 'paragraph',
                      fieldId: 'subHeaderReclaimPage',
                      properties: {
                        text: "Vielen Dank, dass Sie Ihre Bankdaten erneut eingereicht haben.Einer erfolgreichen Überweisung sollte also nichts mehr im Wege stehen.<br> Sollten Sie Fragen haben, die mit Ihren Bankdaten zusammenhängen, wenden Sie sich bitte an <a href='mailto:iban@pulled-chicken-gratis.de' style='color: #0099FF !important; text-decoration: underline;'>iban@pulled-chicken-gratis.de</a>",
                        classes: 'page-subheading text-center',
                        grid: {
                          columns: 10,
                          marginTop: '20px'
                        }
                      }
                    },
                    {
                      fieldType: 'button-component',
                      fieldId: 'errorBackButton',
                      properties: {
                        text: 'Zurück zur STARTSEITE',
                        grid: {
                          columns: 4,
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '50px',
                          marginBottom: '75px'
                        },
                        classes: 'pc-long-button-2',
                        disabled: false,
                        action: {
                          anchor: true,
                          link: '/'
                        },
                        styles: {
                          textTransform: 'none',
                          fontWeight: '400',
                          fontSize: '15px !important',
                          fontFamily: 'Source Sans Pro, sans-serif !important'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
      // { // SURVEY
      //   pageName: 'survey',
      //   pageId: 113,
      //   sections: [
      //     {
      //       grid: {
      //         alignItems: 'center',
      //         justifyContent: 'center',
      //         columns: 12
      //       },
      //       fields: [
      //         { // Container
      //           fieldId: 'fu0923r',
      //           fieldType: 'empty-bucket',
      //           properties: {
      //             grid: {
      //               columns: 12,
      //               alignItems: 'center',
      //               justifyContent: 'center',
      //               maxWidth: '100vw'
      //             },
      //             styles: {
      //               padding: '0px !important'
      //             },
      //             classes: 'form-top-wrapper maxWidthOverride',
      //             fields: [
      //               {
      //                 fieldType: 'image-component',
      //                 fieldId: 'sdjfgh',
      //                 properties: {
      //                   styles: {
      //                     padding: '0 0 0 0',
      //                     maxWidth: '100vw',
      //                     display: 'flex',
      //                     justifyContent: 'center',
      //                     alignItems: 'center',
      //                     marginBottom: '50px'
      //                   },
      //                   grid: {
      //                     columns: 12
      //                   },
      //                   noContainer: true,
      //                   desktopImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
      //                   tabletImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png',
      //                   mobileImageUrl: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/thank-you-banner.png'
      //                 }
      //               },
      //               { // Subeading
      //                 fieldType: 'paragraph',
      //                 fieldId: 'subHeaderReclaimPage',
      //                 properties: {
      //                   text: 'Du magst British Heritage? Erzähle uns mehr.....’',
      //                   classes: 'page-subheading',
      //                   grid: {
      //                     columns: 8
      //                   },
      //                   styles: {
      //                     fontSize: '16px',
      //                     textAlign: 'center'
      //                   }
      //                 }
      //               },
      //               { // Steps
      //                 fieldType: 'survey',
      //                 fieldId: '5urv3yC0mp0n3nt',
      //                 properties: {
      //                   grid: {
      //                     columns: 12,
      //                     alignItems: 'center',
      //                     justifyContent: 'center'
      //                   },
      //                   endpoint: '/submitSurvey',
      //                   successAction: {
      //                     fn: 'goToPage',
      //                     params: 'thank-you'
      //                   },
      //                   colorSchema: '#106D36',
      //                   questions: [
      //                     {
      //                       name: 'radio-1',
      //                       type: 'radio',
      //                       text: 'Hast du vor diesem Produkttest schon einmal ein British Heritage-Produkt gekauft?',
      //                       options: ['Ja', 'Nein']
      //                     },
      //                     {
      //                       //if else
      //                       name: 'radio-2',
      //                       type: 'radio',
      //                       text: 'Wenn ja, welches?',
      //                       options: [
      //                         'British Heritage Cheddar Scheiben 6 Monate (125g)',
      //                         'British Heritage Cheddar Scheiben 12 Monate (125g)',
      //                         'British Heritage Cheddar Stück 12 Monate (200g)'
      //                       ],
      //                       condition: {
      //                         index: 0,
      //                         value: 'Ja'
      //                       }
      //                     },
      //                     {
      //                       name: 'textarea-1',
      //                       type: 'textarea',
      //                       text: 'Was hat dir besonders gut an dem getesteten British Heritage-Produkt gefallen?',
      //                       placeholder: 'Bitte erläutere hier'
      //                     },
      //                     {
      //                       name: 'textarea-2',
      //                       type: 'textarea',
      //                       text: 'Was hat dir nicht an dem getesteten British Heritage-Produkt gefallen?',
      //                       placeholder: 'Bitte erläutere hier'
      //                     },
      //                     {
      //                       name: 'rating-1',
      //                       type: 'rating',
      //                       rateCount: 10,
      //                       text: 'Gib bitte eine Gesamtbewertung zwischen 1 und 10, mit der du deine Meinung zum getesteten Produkt insgesamt zusammenfasst.',
      //                       subText:
      //                         '(10 bedeutet, dass dir das Produkt sehr gefallen hat; 1 bedeutet, dass dir das Produkt überhaupt nicht gefallen hat.)'
      //                     },
      //                     {
      //                       name: 'radio-3',
      //                       type: 'radio',
      //                       text: 'Würdest du British Heritage wieder kaufen?',
      //                       options: ['Ja', 'Nein']
      //                     },
      //                     {
      //                       name: 'radio-4',
      //                       type: 'checkbox',
      //                       text: 'Welchen Cheddar kaufst du sonst?',
      //                       options: [
      //                         'Kerrygold',
      //                         'Cathedral City',
      //                         'Castello',
      //                         'Andere Eigenmarken',
      //                         'Sonstige',
      //                         'Keine Präferenz',
      //                         'Ich habe zuvor noch keinen Cheddar gekauft'
      //                       ]
      //                     },
      //                     {
      //                       name: 'rating-2',
      //                       type: 'rating',
      //                       rateCount: 10,
      //                       text: 'Wie wahrscheinlich ist es, dass du dieses Produkt einer Freundin/einem Freund weiterempfehlen wirst?',
      //                       subText:
      //                         '(10 bedeutet, dass dir das Produkt sehr gefallen hat; 1 bedeutet, dass dir das Produkt überhaupt nicht gefallen hat.)'
      //                     },
      //                     {
      //                       name: 'radio-5',
      //                       type: 'checkbox',
      //                       text: 'Worauf achtest du bei dem Kauf von Cheddar?',
      //                       options: [
      //                         'Preis',
      //                         'Qualität',
      //                         'Marke',
      //                         'Sorte',
      //                         'Geschmack',
      //                         'Nachhaltigkeit der Verpackung/Herstellung',
      //                         'Herkunft/Regionalität',
      //                         'Bio-Siegel',
      //                         'Andere'
      //                       ],
      //                       otherOption: true,
      //                       otherText: 'Andere'
      //                     },
      //                     {
      //                       name: 'radio-6',
      //                       type: 'checkbox',
      //                       text: 'Wie verwendest du deinen British Heritage am liebsten?',
      //                       options: [
      //                         'Brotbelag',
      //                         'Burger',
      //                         'Backen herzhaft',
      //                         'Pur',
      //                         'Anders'
      //                       ],
      //                       otherOption: true,
      //                       otherText: 'Anders'
      //                     },
      //                     {
      //                       name: 'textarea-3',
      //                       type: 'textarea',
      //                       text: 'Welche anderen Produkte (oder Produktarten) von British Heritage würdest du dir noch wünschen?',
      //                       placeholder: 'Bitte erläutere hier'
      //                     }
      //                   ]
      //                 }
      //               },
      //               { // Buttons
      //                 fieldId: 'fu0923r',
      //                 fieldType: 'empty-bucket',
      //                 properties: {
      //                   grid: {
      //                     columns: 12,
      //                     marginTop: '40px',
      //                     justifyContent: 'center'
      //                   },
      //                   classes: 'btn-bucket',
      //                   fields: [
      //                     { // Back H&S Button
      //                       fieldType: 'button-component',
      //                       fieldId: 'but100',
      //                       properties: {
      //                         grid: {
      //                           columns: 2
      //                         },
      //                         text: 'Zurück',
      //                         classes: 'btn-sec-back',
      //                         action: {
      //                           fn: 'goToPage',
      //                           params: 'guest-register'
      //                         }
      //                       }
      //                     },
      //                     { // send survey
      //                       fieldType: 'button-component',
      //                       fieldId: 'but100',
      //                       properties: {
      //                         grid: {
      //                           columns: 2
      //                         },
      //                         text: 'Weiter',
      //                         classes: 'btn-sec-next',
      //                         action: {
      //                           fn: 'sendSurvey',
      //                           params: ''
      //                         }
      //                       }
      //                     }
      //                   ]
      //                 }
      //               },
      //               { // Skip Button
      //                 fieldType: 'button-component',
      //                 fieldId: 'but100',
      //                 properties: {
      //                   grid: {
      //                     columns: 2
      //                   },
      //                   styles: {
      //                     margin: '30px 0px'
      //                   },
      //                   text: 'Überspringen',
      //                   classes: 'btn-sec-back',
      //                   action: {
      //                     fn: 'sendSurvey',
      //                     params: ''
      //                   }
      //                 }
      //               }
      //             ]
      //           }
      //         }
      //       ]
      //     }
      //   ]
      // }
    ],
    header: {
      fieldType: 'navbar',
      fieldId: 'cbx34t',
      properties: {
        grid: {
          columns: 12
        },
        styles: {
          maxWidth: '100vw',
          margin: '0 auto 0 !important',
          width: '100%',
          padding: '0 50px 0px'
        },
        font: {
          name: "'Source Sans Pro', sans-serif",
          url: '/assets/fonts/stylesheet.css'
        },
        favicon: {
          imageSource:
            'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/logo.png'
        },
        fields: [
          {
            fieldId: 'ikz8vc',
            fieldType: 'anchor',
            properties: {
              text: 'Teilnahmebedingungen',
              url: '/teilnahmebedingungen',
              target: '_blank',
              cssClass: 'nav-link',
              styles: {
                textTransform: 'capitalize !important',
                fontSize: '19px !important',
                fontFamily: '"Source Sans Pro", sans-serif !important'
              }
            }
          },
          {
            fieldId: 'slnmdk',
            fieldType: 'anchor',
            properties: {
              text: `FAQ`,
              url: '/faq',
              target: '_blank',
              cssClass: 'nav-link',
              styles: {
                textTransform: 'capitalize !important',
                fontSize: '19px !important',
                fontFamily: '"Source Sans Pro", sans-serif !important'
              }
            }
          }
        ],
        meta: [
          { name: 'title', content: 'Pulled Chicken Gratis Testen' },
          {
            name: 'description',
            content: ``
          },
          {
            name: 'keywords',
            content: `Savencia, Bordeau Chesnel, Savencia Bordeau Chesnel, Gratis Testen, Savencia Gratis Testen Bordeau Chesnel, Pulled Chicken, Pulled Chicken Gratis Testen`
          }
        ]
      }
    },
    footer: {
      fieldType: 'footer-component',
      fieldId: '4tfbzz',
      properties: {
        grid: {
          alignItems: 'center',
          justifyContent: 'center',
          columns: 12
        },
        styles: {
          color: '#4E4B66',
          backgroundColor: '#B1C5BF',
          maxWidth: '100vw',
          position: 'relative'
        },
        fields: [
          {
            fieldType: 'empty-bucket',
            fieldId: 'foot100',
            properties: {
              grid: {
                columns: 12,
                justifyContent: 'center',
                margin: '0 !important',
                padding: '0 !important'
              },
              fields: [
                {
                  fieldType: 'empty-bucket',
                  fieldId: 'foot100',
                  properties: {
                    grid: {
                      columns: 12
                    },
                    styles: {
                      background: '#000',
                      width: '100%',
                      borderTop: '5px solid #E42314'
                    },
                    fields: [
                      {
                        fieldType: 'empty-bucket',
                        fieldId: 'foot100',
                        properties: {
                          grid: {
                            columns: 12
                          },
                          fields: [
                            {
                              fieldType: 'paragraph',
                              fieldId: 'foot100',
                              properties: {
                                grid: {
                                  columns: 12
                                },
                                text: `Unsere Support-Abteilung steht Ihnen bis zum 18.08.2024 zur Verfügung: <a href="mailto:kontakt@pulled-chicken-gratis.de"><b><u>kontakt@pulled-chicken-gratis.de</u></b></a>`,
                                styles: {
                                  color: '#fff',
                                  width: '100%',
                                  margin: '30px 0px 50px 25px !important'
                                }
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    errors: {
      de: {
        default: 'Hoppla, da ist was schief gelaufen!',
        noEmail: 'Es ist kein Konto mit dieser E-Mail-Adresse vorhanden.',
        emailUsed: 'Diese E-Mail-Adresse wird bereits verwendet',
        incorrect:
          'Unzulässiger Benutzername oder falsches Passwort. Bitte versuchen Sie es erneut!',
        invalidCode:
          'Der Link, den Sie verwenden möchten, ist bereits abgelaufen. Bitte fordern Sie einen neuen Link zum Zurücksetzen des Passworts an.',
        manyTries:
          'Ein Fehler ist aufgetreten, weil der Beleg zu oft hochgeladen wurde.',
        generateCode:
          'Der Bestätigungscode konnte nicht gesendet wenden, weil es sich um eine nicht zugelassene Telefonnummer handelt. Bitte überprüfen Sie die eingegebene Nummer und versuchen Sie es erneut.',
        wrongCode: 'Der SMS-Code ist falsch',
        network:
          'Hoppla. Auf dem Server ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
      }
    },
    dictionary: {
      de: {
        messages: {
          required: (field) => `${field} ist ein Pflichtfeld`,
          mandatory: 'Das ist ein Pflichtfeld',
          namesWithHyphensAndSpaces: (field) =>
            `${field} darf nur alphabetische Zeichen enthalten`,
          email: 'Geben Sie bitte eine gültige E-Mail-Adresse an',
          password:
            'Das Passwort muss mindestens 8 Zeichen lang sein, mindestens einen Großbuchstaben (A-Z), einen Kleinbuchstaben (a-z) und eine Zahl (0-9) enthalten.',
          confirmPass: 'Passwörter stimmen nicht überein',
          postal: 'PLZ ist ungültig',
          iban: 'Bitte geben Sie eine gültige IBAN an',
          bic: 'Bitte geben Sie einen gültigen BIC ein',
          phoneNumber: 'Das Handynummer muss eine ganze Zahl sein',
          hausnr: 'Hausnummer ist nicht gültig',
          sepa: 'Bitte verwenden Sie eine gültige IBAN der teilnehmenden Länder'
        },
        names: {
          iban: 'IBAN',
          gender: 'Geschlecht',
          bic: 'BIC',
          firstname: 'Vorname',
          firstName: 'Vorname',
          lastname: 'Nachname',
          lastName: 'Nachname',
          email: 'E-mail',
          birthdate: 'Geburtsdatum',
          password: 'Passwort',
          newPassword: 'Passwort',
          confirmPassword: 'Bestätigen des Passwort',
          newPasswordConfirm: 'Bestätigen des Passwort',
          strasse: 'Straße',
          hausnr: 'Nr',
          plz: 'PLZ',
          ort: 'Ort',
          land: 'Land',
          phoneNumber: 'Handynummer',
          code: 'Bestatigungscode'
        }
      }
    },
    campaignId: 'pulled-chicken-de',
    url: 'https://https://pulled-chicken-gratis.de/',
    name: 'pulled-chicken-de'
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'Ein Fehler ist aufgetreten',
    isActive: false
  },
  campaignId: 'pulled-chicken-de',
  customerToken: '',
  receiptToken: '',
  receiptImage: '',
  referenceNumber: '',
  formData: new FormData(),
  campaignStartDate: '2024-04-05',
  campaignEndDate: '2024-07-21',
  scannerActive: false,
  optIn: false,
  file: {},
  barcode: {
    code: '',
    format: ''
  },
  productCode: '',
  searchStatus: null,
  imgList: [],
  errorMessage:
    'Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?',
  errorImageList: ['', ''],
  errorActivate: false,
  scannedProducts: [],
  types: [
    'ean_reader',
    'code_128_reader',
    'code_39_reader',
    'ean_8_reader',
    'upc_reader',
    'upc_e_reader',
    'i2of5_reader',
    '2of5_reader',
    'code_93_reader',
    'codabar_reader'
  ],
  receiptBarcode: '',
  convertedImagePointsData: {},
  headerInclude: [
    '',
    'campaign-is-over',
    'registrierung-abgeschlossen',
    'reclaim',
    'doi'
  ],
  disableAnchors: false,
  locale: 'de',
  frontAndBack: false,
  countryCode: '',
  tel: '',
  consumerId: '',
  verification_code: ''
}

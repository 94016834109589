<template>
  <div>
    <div
      v-if="list.length !== 0"
      class="barcode-scanner__products-list"
      :style="styles"
    >
      <div
        class="barcode-scanner__products-list_item d-flex"
        v-for="item in list"
        :key="item.id"
      >
        <div class="row" style="width: 100%">
          <div class="col-sm-6 col-8 my-2">
            <div class="d-flex">
              <img v-if="item.logo" :src="item.logo" class="short-logo" />
              <img
                v-else
                src="../../assets/P_G_Logo_RGB.svg"
                class="short-logo"
              />
              <div class="item-content d-flex">
                <div style="font-weight: bold">{{ item.name }}</div>
                <div>EAN: {{ item.ean }}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-4 my-2">
            <b-input-group>
              <b-form-input
                type="number"
                max="1"
                size="sm"
                disabled
                v-model="item.count"
                variant="info"
                @change="
                  editInputQuantityProduct({
                    productId: item.id,
                    count: item.count,
                    maxProductCount,
                  })
                "
              />
              <template #append>
                <b-button
                  size="sm"
                  @click="removeProduct(item.id)"
                  variant="danger"
                >
                  <img src="@/assets/icons/delete.svg" style="width: 17.5px; height: 17.5px;" >
                </b-button>
              </template>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showReturnBackButton && list.length === 0"
      :style="grid"
      style="justify-content: center; display: flex"
    >
      <buttonComponent
        class="btn"
        @click.native="goToSelectProducts()"
        :textColor="white"
        text="Produkte hinzufügen"
        backgroundColor="var(--color2)"
        borderColor="var(--color2)"
        :borderRadius="'50rem'"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import buttonComponent from "@/components/renderers/button-component.vue";

export default {
  name: 'productsList',
  components: {
    buttonComponent
  },
  props: {
    styles: {
      type: Object,
      required: false
    },
    showEditButton: {
      type: Boolean,
      default: false
    },
    showReturnBackButton: {
      type: Boolean,
      default: false
    },
    maxProductCount: Number
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      list: state => {
        return state.scannedProducts
      }
    })
  },
  methods: {
    ...mapActions(["removeProduct", "editQuantityProduct", "editInputQuantityProduct"]),
    editProduct (product, action) {
      let data = { product, action };
      this.editQuantityProduct(data);
      if (!product.count) {
        this.removeProduct(product.id);
      }
    },
    goToSelectProducts () {
      this.goToPage({
        pageName: 'productSelection',
        shouldBack: true
      }, this)
    }
  }
}
</script>

<style scoped lang="scss">
.barcode-scanner__products-list {
  width: 100%;
  padding-top: 30px;
  // font-family: avenir_lt_35_lightregular;
  @media (max-width: 767px) {
    padding: 0;
  }
  &_item {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    div {
      align-items: center;
    }
    .item-content {
      flex-direction: column;
      padding-left: 10px;
      align-items: flex-start;
      font-size: 12px;

      @media (max-width: 1140px) {
        margin-top: 40px;
      }
    }
  }
  .circle {
    display: flex;
    width: 44px;
    height: 44px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: #9adfe6;
    border-radius: 2em;
  }
}

.select-none {
  user-select: none;
}

.short-logo {
  width: 36px;
}

.btn-info {
  width: 44px;
  height: 44px;
  border-radius: 100% !important;
  font-size: 0;
  line-height: 1;
  background-color: var(--color9);
  border: 0;
  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }

  .input-group-prepend & {
    background-image: url("../../assets/icons/minus.svg");
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .input-group-append & {
    background-image: url("../../assets/icons/plus.svg");
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.btn-danger {
  background: 0;
  border: 0;
  border-radius: 0;
  color: #6f6f6f;
  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }
}
.btn-edit {
  background: 0;
  border: 0;
  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }
  img {
    width: 70%;
  }
}

.input-group {
  justify-content: flex-end;
  .form-control {
    border: 0;
    padding: 0;
    margin: 0 5px;
    text-align: center;
    flex: 0 1 26%;
    &:focus {
      outline: 0 !important;
      box-shadow: none;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>

import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import de from 'vee-validate/dist/locale/de.json';
import { i18n } from './translate'

const ibantools = require('ibantools');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('mandatory', {
  ...rules['required']
})
extend('postal', {
  params: ['land'],
  validate (value, { land }) {
    const regex = land == "Germany" ? /^(\d{5})?$/ : /^(\d{4})?$/;
    if(land){
      return (regex.test(value));
    } else return true
  }
});

extend('requiredProduct', {
  validate: value => value != null ? true : false,
  message: 'Bitte wählen Sie ein Produkt aus'
});

extend('confirmPass', {
  params: ['pass'],
  validate (value, { pass }) {
    return value === pass
  }
});

extend('hausnr', {
  ...rules['integer']
});

extend('email', {
  ...rules['email']
})
extend('password', {
  validate: value => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d\W_]{8,}$/.test(value)
});
extend('iban', {
  validate: (value) => {
    const countries = ['AN', 'AT', 'BY', 'BE', 'BA', 'BG', 'HR', 'CY', 'CZ',
      'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IS', 'IE', 'IT', 'LV', 'XK', 'LI',
      'LT', 'LU', 'MT', 'MC', 'MD', 'ME', 'MK', 'NL', 'NO', 'PL', 'PT', 'RO', 'SM',
      'RS', 'SK', 'SI', 'ES', 'SE', 'CH', 'UA', 'GB', 'VA']
    const countryCode = value.substring(0, 2)
    return countries.includes(countryCode) && ibantools.isValidIBAN(value.replace(/[^\dA-Z]/g, ''))
  }
});
extend('bic', {
  validate: value => !!ibantools.isValidBIC(value)
});
extend('sepa', {
  validate: (value) => {
    const countryCode = value.substring(2, 0)
    const countToIgnore = ['GB', "LI"]
    return ibantools.isSEPACountry(countryCode) && !countToIgnore.includes(countryCode)
  }
});
extend('namesWithHyphensAndSpaces', {
  validate: value => {
    return /^([a-zA-ZßäöüÄÖÜÅåÅåÆæØø]{1,}(\s|-))*[a-zA-ZßäöüÄÖÜÅåÅåÆæØø]{1,}$/.test(value);
  }
});

extend('phoneNumber', {
  ...rules['integer']
})
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule] // copies rule configuration
    // message: wihtCustomMessages[rule] // assign message
  });
});


localize({
  en: {
    messages: en.messages
  },
  de: {
    messages: de.messages
  }
});

localize(i18n.locale);

// A simple get/set interface to manage our locale in components.
// This is not reactive, so don't create any computed properties/watchers off it.
Object.defineProperty(Vue.prototype, "locale", {
  get () {
    return i18n.locale;
  },
  set (val) {
    i18n.locale = val;
    localize(val);
  }
});

<template>
  <div class="imageWrapper" :style="styles" :class="addClass">
    <div :class="{'container': !noContainer}">
      <img class="desktop_img" :src="desktopImageUrl" alt="" />
      <img class="tablet_img" :src="tabletImageUrl" alt="" />
      <img class="mobile_img" :src="mobileImageUrl" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "image-component",
  props: {
    desktopImageUrl: {
      type: String
    },
    mobileImageUrl: {
      type: String
    },
    tabletImageUrl: {
      type: String
    },
    noContainer: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      default: () => {}
    },
    addClass: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style scoped lang="scss">
.imageWrapper {
  position: relative;
  max-width: 100vw !important;
}

.footerLogo {
  @media (max-width: 667px) {
    justify-content:flex-start !important;
    padding-left: 20px !important;
  }
}

.desktop_img {
  max-width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
}

.tablet_img {
  max-width: 100%;
  @media (max-width: 435px), (min-width: 769px) {
    display: none;
  }
}
.mobile_img {
  max-width: 100%;
      max-width: 100%;
  @media (min-width: 436px) {
    display: none;
  }
}

.mobileSticker {
  @media (max-width: 450px){
    right: 5px !important;
  }
}

</style>

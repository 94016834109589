<template>
  <span></span>
</template>

<script>
export default {
  name: "inject-tag",
  props: {
    content: {
      type: String,
      default: ""
    },
    tagType:{
      default:"div"
    }
  },
  mounted () {
    if(this.tagType==='script'){
      const script = document.createElement("script");
      script.textContent = this.content.trim();
      return document.head.appendChild(script);
    }
    const tag = document.createElement(this.tagType);
    tag.innerHTML = this.content.trim();
    document.body.append(tag.firstChild);
  }
};
</script>

<template>
  <div class="keyVisualWrapper" :style="styles" :class="addClass">
    <div :class="{'container': !noContainer}">
      <img class="desktop_img img-fluid w-100" :src="desktopImageUrl" alt="banner" />
      <img class="tablet_img img-fluid w-100" :src="tabletImageUrl" alt="banner" />
      <img class="mobile_img img-fluid w-100" :src="mobileImageUrl" alt="banner" />
    </div>
  </div>
</template>

<script>
export default {
  name: "key-visual",
  props: {
    desktopImageUrl: {
      type: String
    },
    mobileImageUrl: {
      type: String
    },
    tabletImageUrl: {
      type: String
    },
    noContainer: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      default: () => {}
    },
    addClass: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style scoped lang="scss">
.keyVisualWrapper {
  position: relative;
  max-width: 100vw !important;
  @media(max-width: 450px){
    max-width: 100vw !important;
    // margin-top: -85px !important;
  }
}

.desktop_img {
  @media (max-width: 768px) {
    display: none;
  }
}

.tablet_img {
  @media (max-width: 435px), (min-width: 769px) {
    display: none;
  }
}
.fullWidth {
  @media(max-width: 450px){
    max-width: 100vw !important;
    padding: 0px !important;
  }
}
.mobile_img {
  @media (min-width: 436px) {
    display: none;
  }
}

</style>

<template>
  <div class="step_container"
      :style="{
      '--borderColor': borderColor,
    }">
    <div class="row justify-content-center">
      <div class="col-md-6 col-sm-12 col-12" :class="`col-lg-${getCol}`" :key="idx" v-for="(step, idx) in steps">
        <div class="step_wrapper">
          <div class="step" :class="{ first: idx % 2 === 0 }">
            <div class="d-flex align-items-center">
              <img :src="getImgUrl(step.image)" alt="Step image">
              <div class="content">
                <h3 v-html="step.title" :style="{ color: textColor }"></h3>
                <span v-html="step.text"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "steps-horizontal",
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    textColor: {
      type: String,
      default: "#f2ac29"
    },
    borderColor: {
      required: false,
      type: String,
      default: "#ebf0fa"
    }
  },
  computed: {
    getCol () {
      return 12 / this.steps.length;
    }
  },
  methods: {
    getImgUrl (image) {
      var images = require.context("/public/assets/images/steps", true, /\.png$/);
      return images(`./${image}.png`);
    }
  }
};
</script>

<style scoped lang="scss">
.step_wrapper {
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
  }
}

.step_container {
  [class*="col-"]:not(:last-child) {
    margin-bottom: 30px;
  }
}

.step {
  width: 100%;
  max-width: 286px;
  height: 160px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px -4px rgba(0, 0, 0, 1);
  border-radius: 24px;
  padding: 25px 15px 25px 15px;

  @media (min-width: 767px) {
    &.first {
      float: right;
    }
  }
  @media (max-width: 1440px) {
    img {
      max-width: 80px;
      max-height: 80px;
    }
    padding: 25px 15px 25px 15px;
  }
    @media (max-width: 1200px) { 
    img {
      max-width: 100px;
      max-height: 100px;
    }
    padding: 30px 15px 30px 15px;
  }
  .content {
    margin-left: 20px;
    h3 {
      width: 100%;
      max-width: 90px;
      font-size: 14px;
      padding: 5px 0;
      background: #E42314;
      border-radius: 4px;
      text-align: center;
      font-weight: 400;
    }
    span {
      display: block;
      color: #0a0a2d;
      margin-top: 10px;
      font-size: 17px;
      font-weight: 700;
    }
    @media (max-width: 1440px) {
      margin-left: 10px;
      padding-top: 5px;
    }
  }

  h3 {
    font-weight: 800;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: 350px;
    height: 100%;
    max-height: 120px;
    padding: 15px;

    img {
      width: 80px;
      height: 80px;
    }

    h3 {
      font-size: 14px;
      line-height: 20px;
    }

    span {
      font-size: 14px;
      line-height: 15px;
    }
  }
  @media (max-width: 450px) {
    h3 {
      font-size: 16px;
      line-height: 20px;
    }
    span {
      font-size: 12px;
      line-height: 15px;
    }
  }

}
</style>

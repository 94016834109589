<template>
  <div
    :class="`product-wrapper ${selected ? 'active' : ''}`"
    :style="cssVars"
    @click="$emit('itemClick')"
    @keypress.enter="$emit('itemClick')"
    tabindex="0"
    role="button"
  >
    <div
      :class="`product ${!product.selectable ? 'notSelectable' : ''}`"
      :style="cssString"
    >
      <img
        :src="product.backgroundImage"
        class="product-image"
        :alt="product.title"
        tabindex="-1"
      >
    </div>
    <div class="text">
      <div>
        <div
          class="title"
          v-html="$t(product.title)"
        ></div>
      </div>
      <button class="button btn">
        {{ `${selected ? 'Gewählt' : 'Auswählen'}` }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      required: true,
      type: Object
    },
    selected: {
      required: true,
      type: Boolean
    },
    colorSchema: {
      required: true,
      type: String
    },
    cssString: String
  },
  computed: {
    cssVars() {
      return {
        '--color': this.colorSchema
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product-wrapper {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 5px;
  border: 2px solid transparent;
  border-radius: 20px;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.45);

  .btn {
    background-color: #FFF;
    color: #E42314 !important;
    border: 1px solid #E42314;
    font-family: 'Source Sans Pro', sans-serif !important;
  }

  &.active {
    background: #E423144D 30%;
    border: 2px solid #E42314;

    .btn {
      background-color: #E42314;
      color: #fff !important;
      border: 2px solid #E42314;
    }
  }

  &:hover {
    box-shadow: 0px 4px 20px 2px rgba(78, 117, 147, 0.35);
  }

  .product {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 20px;
    min-height: 169px;

    .product-image {
      width: 145px;
      margin: 12px auto;
    }

    &:hover {
      box-sizing: border-box;
      border-radius: 4px;
      transition: .4s ease-in-out
    }

    &.notSelectable {
      opacity: 0.6;
      -webkit-filter: grayscale(100%);
      /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }

    .isSelectedBox {
      position: absolute;
      border-radius: 5px;
      top: 10px;
      right: 40px;
      user-select: none;

      svg {
        position: absolute
      }
    }
  }

  .text {
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    row-gap: 15px;
    text-align: center;
    font-weight: 500;
    color: #303030;

    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 20px;
    }

    .description {
      color: #303030;
      font-size: 16px;
      line-height: 189x;
      margin-bottom: 20px;
      text-align: center;
    }

    .button {
      width: 100%;
      text-align: center;
      font-weight: 600;
      font-size: 18px !important;
      line-height: 24px;
      color: #FFF;
      padding: 10px 0;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: .4s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}
</style>

<template>

    <img :src="imageSource" class="img-fluid" :class="classes" :alt="alternateText" :width="width" :height="height" :style="styles" />

</template>

<script>
export default {
  name: "square-image",
  props: {
    imageSource: {
      type: String,
      default: "@/assets/logo.png"
    },
    alternateText: {
      type: String,
      default: "Sample Image"
    },
    width: {
      type: Number,
      default: 100
    },
    classes: {
      type: String,
      required: false,
      default: ''
    },
    height: {
      type: Number,
      default: 100
    },
    styles: {
      type: Object,
      default:()=>{}
    }
  }
};
</script>

<style>
img[data-field-type="square-image"] {
  object-fit: cover
}
.expiredPageImage{
  width: 262px !important;
  height: 262px !important;
  max-width: 262px !important;
  max-height: 262px !important;
}
</style>
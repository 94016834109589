import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { i18n } from '../translate'

const testApi = process.env.VUE_APP_API_URL
const APIUrl = process.env.NODE_ENV !== "development" ? process.env.VUE_APP_API_URL : testApi

export default {
  isPublic ({ state }, pageName) {
    if (pageName.toLowerCase() === 'home') return true
    const page = state.pageProperties.pages.find(page => {
      return page.pageName === pageName
    })
    return page && page.isPublic;
  },
  handleError: ({ commit, state }, err = { message: '', code: '99' }) => {
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
      err.code = err.response.status
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(err.request);
    } else {
      // Something happened in setting up the request and triggered an err
      console.log('Unexpected Error', err.message);
    }
    const page = state.pageProperties;
    const errors = page.errors[i18n.locale] || []
    let defaultMsg = errors['default']
    console.error("Error:", err)
    if (err.cause) {
      if (errors[err.cause]) {
        return commit('setModalOptions', { type: 'error', isActive: true, message: errors[err.cause] })
      } else {
        return commit('setModalOptions', { type: 'error', isActive: true, message: defaultMsg })
      }
    } else if (err.customMsg) {
      let message = err.customMsg + "<br>Code:: " + err.code
      return commit('setModalOptions', { type: 'error', isActive: true, message: message })
    } else {
      if (err.code == '404') {
        let msg = defaultMsg + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.code == '503') {
        let msg = errors['network'] + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.code == '502') {
        let msg = errors['network'] + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.message == 'Network Error') {
        let msg = errors['network'] + `<br>Code:: 50X`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else {
        let msg = defaultMsg + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      }
    }
  },

  setSelectedProduct ({ state, dispatch, commit }, data) {
    var newData = [
      {
        "campaignId": state.campaignId,
        "ean": data.value,
        "name": data.text,
        "count": 1
      }
    ];
    commit("setSelectedProductObj", newData);
    dispatch("findProducts", newData);
  },

  setPageProperties: ({
    commit
  }, properties) => {
    commit('setPageProperties', properties);
  },

  onReceiptUploadSuccess ({ commit }, data) {
    commit('setReceiptImage', data)
  },
  refreshTempId: ({ state, dispatch, commit, getters }) => {
    return axios.post(`${APIUrl}/tempId`, { campaignId: getters.campaignId, jstc: 1 })
      .then(resp => {
        if (resp.data.response) {
          const data = {
            field: 'customerToken',
            value: resp.data.customerToken
          }
          if (!location.pathname.includes("/iban-reupload")) commit('setField', data)
        }
      })
      .catch(err => {
        dispatch('handleError', err)
      })
  },
  setComponentStatus: ({ commit }, { component, status }) => {
    commit('setComponentStatus', { component, status });
  },
  sendSurveyData ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(APIUrl + '/submitSurvey', data.data)
        .then(res => {
          if(res.data.response){
            if(res.data.referenceNumber) {
              commit('setReferenceNumber', res.data.referenceNumber)
            }
          } else {
            commit('setModalOptions', { type: 'error', isActive: true, message: 'Hoppla. Ein Fehler ist aufgetreten.' })
          }
          resolve(res.data)
        })
        .catch(err => {
          commit('setModalOptions', { type: 'error', isActive: true, message: 'Hoppla. Ein Fehler ist aufgetreten.' })
          reject(err)
        })
    })
  },
  sendFormData: ({ state, dispatch }, data) => {
    return new Promise((resolve, reject) => {

      if (window.nsId) {
        data.formData.nsId = window.nsId;
      }

      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (data.triggers && data.triggers.neuStarTrigger) {
            // console.log("3. will send::: neutrigger", data.triggers)
            let a = window._agknEchoTag;
            if (a && data.n) {
              a.setBpId('firstp');
              a.addEchoKeyValue('event', 'submit');
              a.generateTag();
            }
          }
          if (res.data.response) {
            return resolve(res.data)
          } else {
            if (res.data.invalid_fields) {
              //janrain error
              if (res.data.invalid_fields[0] == 'Email address is already in use.') {
                dispatch('handleError', { cause: 'emailUsed' })
              } else if (res.data.invalid_fields[0] == 'Incorrect username or password. Please try again.') {
                dispatch('handleError', { cause: 'incorrect' })
              } else if (res.data.invalid_fields[0] == 'No account with that email address exists.') {
                dispatch('handleError', { cause: 'noEmail' })
              } else if (res.data.message && res.data.message == 'authorization_code is not valid') {
                dispatch('handleError', { cause: 'invalidCode' })
              } else {
                dispatch('handleError', { customMsg: res.data.invalid_fields })
              }
            } else if (res.data.message) {
              dispatch('handleError', { customMsg: res.data.message })
            } else {
              dispatch('handleError', { code: res.data })
            }
            return reject(res.data)
          }
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },

  uploadProductPhotos ({ state, getters, dispatch }, data) {
    // console.log('data', data)
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('productPhoto', data.image, 'productPhoto.png')
      formData.append('customerToken', data.customerToken)
      formData.append('campaignId', getters.campaignId)
      formData.append('receiptToken', data.receiptToken)
      formData.append('productPhotoIndex', data.productPhotoIndex)

      if (formData.customerToken == 1) {
        formData.append('token', data.token)
      }

      axios.post(APIUrl + data.endpoint, formData)
        .then(res => {
          return resolve(res.data)
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },

  generateCode ({ dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken
      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) {
            return resolve(res.data)
          } else {
            dispatch('handleError', { cause: 'generateCode' })
            return reject(res.data)
          }
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },

  verifyCode ({ dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken

      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) {
            return resolve(res.data)
          } else {
            dispatch('handleError', { cause: 'wrongCode' })
            return reject(res.data)
          }
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },

  sendProducts ({ commit, dispatch, state, getters }, data) {
    return new Promise((resolve, reject) => {
      // console.log("inside sendProducts::", data, state)
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = getters.campaignId
      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) {
            resolve(res.data)
          } else {
            dispatch('handleError', { customMsg: res.data.message })
            reject(res.data)
          }
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },

  uploadReceipt ({ getters, commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      if (data.receiptToken) {
        formData.append('receiptToken', data.receiptToken)

      }
      if (APIUrl.includes('localhost') || APIUrl.includes('192.168')) {
        formData.append('imageUrl', 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/0814c81a-0d8a-4f70-ae00-be7752de6846.png')
        data.apiUrl = 'http://localhost:3001/api/createReceipt'
        formData.append('campaignId', getters.campaignId)
      } else {
        formData.append('receipt', data.image, 'receipt.png')
        formData.append('campaignId', data.campaignId)
      }
      formData.append('userToken', data.customerToken)
      formData.append('subCampaignId', getters.campaignId)
      if (data.token) {
        formData.append('token', data.token)
      }
      return axios.post(`${data.apiUrl}`, formData)
        .then(resp => {
          if (resp.data.response !== 'ERR') {
            // If reference number is returned, set reference number
            if (resp.data.referenceNumber) {
              const data = {
                field: 'formData',
                value: { 'referenceNumber': resp.data.referenceNumber }
              }
              commit('setField', data)
            }

            commit('setReceiptToken', resp.data.receiptToken)
            resolve(resp.data.receiptToken);
          } else {
            dispatch('handleError', resp)
          }
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },

  getBarCode ({ commit }, code) {
    commit('setBarCode', code)
  },
  handleProductCode ({ commit }, value) {
    commit('setProductCode', value)
  },
  updateSearchStatus ({ commit }, status) {
    commit('setSearchStatus', status)
  },
  updateScanner ({ commit }, status) {
    commit('setScannerStatus', status)
  },
  setItems ({ commit }, array) {
    commit('setItems', array);
  },
  deleteItem ({ commit }, itemId) {
    commit('deleteItem', itemId);
  },
  findProducts ({ commit }, array) {
    commit('setProducts', array)
  },
  removeProduct ({ commit }, productId) {
    commit('deleteProduct', productId)
  },
  editQuantityProduct ({ commit }, { product, action, maxProductCount }) {
    commit('setQuantityProduct', { product, action, maxProductCount })
  },
  editInputQuantityProduct ({ state, commit }, { productId, count, maxProductCount }) {
    if (count === '0') {
      commit('deleteProduct', productId)
    } else {
      if (maxProductCount && count > maxProductCount) {
        // commit('setModalOptions', { type: 'error', isActive: true, message: `Sie können nicht mehr als ${maxProductCount} Produkte auswählen.` });
        commit('setModalOptions', {
          type: 'error', isActive: true, message: state.locale == 'de' ?
            `Sie können nicht mehr als ein Produkt auswählen.` :
            `Vous ne pouvez pas choisir plus d'un produit.`
        });
        commit('setInputQuantityProduct', { productId, count: maxProductCount });
        return
      }
      commit('setInputQuantityProduct', { productId, count })
    }
  },
  searchProductByBarcode ({ state, dispatch, commit }, { barcode, maxProductCount }) {
    if (state.scannedProducts && state.scannedProducts.find(x => {
      return x.ean === barcode
    })) {
      commit('setModalOptions', { type: 'error', isActive: true, message: state.locale == 'de' ? `Sie haben dieses Produkt bereits hinzugefügt.` : 'Vous avez déjà ajouté ce produit.' });
      return false;
    }
    state.currentBarcode = barcode;
    let apiUrl = 'https://js-backend.justsnap.eu/api/searchBarcode';
    let totalPrice = 0;
    for(const product of state.scannedProducts){
      totalPrice += product.price;
    }
    const isAllowed = maxProductCount ? (maxProductCount > state.scannedProducts.length || totalPrice < 3) : true;
    if (/^[0-9]+$/.test(barcode) && isAllowed) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}`, {
            campaignId: state.campaignId,
            barcode: barcode
          })
          .then(res => {
            let status = res.data.result.length > 0 ? 'success' : 'not_found';
            dispatch("updateSearchStatus", status);
            dispatch("findProducts", res.data.result);

            if (status === 'not_found') {
              // The barcode is not P&G
              dispatch('updateScannerErrorMessage', 'Der Barcode scheint nicht zu einem P&G Produkt zu gehören. Glauben Sie, dass es sich um einen Fehler handelt?')
              dispatch('setErrorActivate', true)
            } else {
              dispatch('setErrorActivate', false)
            }
            resolve(res.data);
          })
      });
    } else {
      if (!isAllowed) {
        // commit('setModalOptions', { type: 'error', isActive: true, message: `Sie können nicht mehr als ${maxProductCount} Produkte auswählen.` });
        commit('setModalOptions', {
          type: 'error', isActive: true, message: maxProductCount == 1 ? state.locale == 'de' ?
            `Sie können nicht mehr als ein Produkt auswählen.` :
            `Vous ne pouvez pas choisir plus d'un produit.` : state.locale == 'de' ?
            `Sie können nicht mehr als 2 Produkte auswählen.` :
            `Vous ne pouvez pas choisir plus de 2 produits.`
        });
        dispatch("updateSearchStatus", null);
      } else {
        dispatch("updateSearchStatus", 'not_found');
        // The barcode is invalid
        dispatch('updateScannerErrorMessage', 'Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?')
        dispatch('setErrorActivate', true)
      }
    }
  },
  updateScannerErrorMessage ({ commit }, error) {
    commit('setScannerError', error);
  },
  setErrorImages ({ commit }, data) {
    commit('setErrorImages', data)
  },
  setErrorActivate ({ commit }, data) {
    commit('setErrorActivate', data)
  },
  setReceiptBarcode ({ commit }, data) {
    commit('setReceiptBarcode', data)
  },
  setConvertedImagePointsData ({ commit }, data) {
    commit('setConvertedImagePointsData', data)
  },
  isUpdateIban: ({ commit }, token) => {
    try {
      const data = jwtDecode(token)
      return new Promise((resolve, reject) => {
        axios.get(`${APIUrl}/ibanUpdateCheck/${data.customerToken}/${data.campaignId}`)
          .then(resp => {
            commit('setCustomerToken', data.customerToken)
            resolve(resp.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    } catch (err) {
      return false
    }
  },
  setCustomerToken: ({ commit }, data) => {
    commit('setCustomerToken', data)
  },
  checkParticipationCount ({ getters }, data) {
    const formData = {
      campaignId: getters.campaignId
    }
    return new Promise((resolve, reject) => {
      axios.post(APIUrl + data.endpoint, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  check72Hours ({ commit }, token) {
    const formData = {}
    try {
      const data = jwtDecode(token)
      formData.receiptToken = data.receiptToken
      formData.campaignId = data.campaignId
      return new Promise((resolve, reject) => {
        axios.post(`${APIUrl}/check72Hours`, formData)
          .then(resp => {
            if (resp.data.response) {
              commit('setReceiptToken', data.receiptToken)
              commit('setCustomerToken', data.customerToken)
              resolve(resp.data)
            }
            else reject(resp.data)
          })
          .catch(err => {
            reject(err)
          })
      });
    } catch (err) {
      return false
    }
  },
  sendReclaimToken ({ getters }, data) {
    const formData = {
      campaignId: getters.campaignId,
      receiptToken: data.receiptToken
    }
    return new Promise((resolve, reject) => {
      axios.post(APIUrl + data.endpoint, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendRequest ({ getters }, data) {
    return new Promise((resolve, reject) => {
      let user_id = localStorage.getItem('user_id')
      axios.post(APIUrl + data.endpoint, data.formData, { headers: { Authorization: `Bearer ${user_id}` } })
        .then(resp => {
          if (resp.data.response)
            resolve(resp.data)
          else
            reject(resp.data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  checkDoi ({ getters }, data) {
    const formData = {
      verification_code: data.verification_code,
      campaignId: getters.campaignId
    }
    return new Promise((resolve, reject) => {
      axios.post(APIUrl + data.endpoint, formData)
        .then(resp => {
          if (resp.data.response)
            resolve(resp.data)
          else
            reject (resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  uploadError ({ commit, getters }) {
    commit('setModalOptions', { type: 'error', isActive: true, cause: 'manyTries' })
  }
}

<template>
  <span></span>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "QueryStringToState",
  props: {
    variables: Array
  },
  mounted () {
    this.variables.forEach(variable=>{
      this.$store.state[variable.state] = this.$route.query[variable.query];
    })
  }
};
</script>

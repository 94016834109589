<template>
  <div :style="cssVars">
    <div
      class="wrapper input-group d-flex justify-content-start m-0 p-0"
      :style="styles"
    >
      <div class="mr-5 p-0">
        <span class="title p-0 ml-5">{{ $t(title) }} </span>
      </div>
      <div class="col-3 d-flex justify-content-center ref-wrapper">
        <input
          type="text"
          :disabled="disabledInput"
          class="form-control col-sm-8"
          id="copied-tooltip"
          ref="target"
          :value="value"
          aria-label="Value"
        >
        <img
          src="../../assets/icons/copy.svg"
          alt="Copy"
          @click="copy"
          class="clipboard col-sm-2"
        >
      </div>
    </div>
    <b-tooltip
      ref="tooltip"
      target="copied-tooltip"
      noninteractive
    >
      {{ $t('Copied to clipboard') }}
    </b-tooltip>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "Clipboard",
  props: {
    text: {
      type: String,
      default: "Empty paragraph"
    },
    title: {
      type: String,
      default: "Empty paragraph"
    },
    variables: {
      type: Object,
      default: () => {}
    },
    disabledInput: {
      type: Boolean,
      default: true
    },
    colorSchema: {
      type: String,
      default: "#888888"
    },
    styles: {
      type: Object,
      default: () => {}
    }
  },
  mounted () {
    // console.log("disabled: ", this.variables, this.$store.state['referenceNumber'])
  },
  computed: {
    value () {
      let result = this.text;
      if (this.variables) {
        for (const variable in this.variables) {
          const value = this.$store.state[variable];
          result = value;
        }
      }
      return result;
    },
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  },
  methods: {
    copy () {
      var copyText = this.$refs.target;
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(copyText.value);
      this.$refs.tooltip.$emit('open')
      setTimeout(() => {
        this.$refs.tooltip.$emit('close')
      }, 2000)
    },
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000)
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  border-radius: 4px;
  height: 60px;
  padding: 5px 20px 5px 0;
  align-items: center;
  @media (max-width: 767px) {
    padding: 5px 10px;
    .b-icon {
      font-size: 20px !important;
    }
  }
}


.title {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: 18px;
  line-height: 26px;
  color: #303030;
  font-weight: 700;
  line-height: 32px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
input {
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  border: none;
  background: transparent;
  padding: 5px;
  &:disabled {
    background: transparent;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.clipboard {
  cursor: pointer;
  max-width: 50px;
}
.ref-wrapper {
  width: 100%;
  min-width: 200px;
  background: #fff;
  border: 2.36364px solid #7a7a7a;
  border-radius: 4px;
  padding: 10px 0 10px 0;
  input {
    text-align: center;
    color: #4F4F4F !important;
  }
}
</style>

<template>
  <div
    :class="mainWrapperClass"
    class="gray_block"
    :style="[cssVars]"
  >
    <div class="wrapper_check_block clearfix">
      <div class="checkMark correct">
        <img
          class="img-fluid"
          :src="showProduct ? '/assets/images/png/rcpt-prod-1.png' : '/assets/images/png/check_1.png'"
          alt=""
        >
        <img
          class="checkMark_img checkMark_img_1"
          src="../../assets/images/svg/checkMark.svg"
          alt=""
        >
        <div class="text">
          {{ this.texts.correct }}
        </div>
      </div>
      <div class="checkMark">
        <img
          class="img-fluid"
          :src="showProduct ? '/assets/images/png/rcpt-prod-2.png' : '/assets/images/png/check_3.png'"
          alt=""
        >
        <img
          class="cross_red_img cross_red_img_3"
          src="../../assets/images/svg/cross_red.svg"
          alt=""
        >
        <div class="text">
          {{ this.texts.notReadable }}
        </div>
      </div>
      <div class="checkMark">
        <img
          class="img-fluid"
          :src="showProduct ? '/assets/images/png/rcpt-prod-3.png' : '/assets/images/png/check_4.png'"
          alt=""
        >
        <img
          class="cross_red_img cross_red_img_4"
          src="../../assets/images/svg/cross_red.svg"
          alt=""
        >
        <div class="text">
          {{ this.texts.folded }}
        </div>
      </div>
      <div class="checkMark">
        <img
          class="img-fluid"
          :src="showProduct ? '/assets/images/png/rcpt-prod-4.png' : '/assets/images/png/check_2.png'"
          alt=""
        >
        <img
          class="cross_red_img cross_red_img_2"
          src="../../assets/images/svg/cross_red.svg"
          alt=""
        >
        <div class="text">
          {{ this.texts.cropped }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "receipt-examples",
  props: {
    colorSchema: {
      type: Object,
      default: () => {
        return {
          correct: "green",
          incorrect: "red"
        }
      }
    },
    texts: {
      type: Object,
      default: () => {
        return {
          correct: "Gültig",
          notReadable: "Schwer zu lesen",
          folded: "Zerknittert",
          cropped: "Abgeschnitten"
        }
      }
    },
    mainWrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    showProduct: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    pull () {
      this.pullDown = !this.shouldDisplayFull ? !this.pullDown : false
    }
  },
  computed: {
    cssVars () {
      return {
        '--correct-color': this.colorSchema.correct,
        '--incorrect-color': this.colorSchema.incorrect
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  .gray_block {
    background-color: transparent;
    padding: 0px !important;
    border-radius: 4px;

    @media (max-width: 991px) {
      padding: 40px 60px;
    }
    @media (max-width: 767px) {
      padding: 10px 15px;
    }
    .inner_gray_block {
      text-align: center;
      margin-bottom: 50px;
    }
  }
  .wrapper_check_block {
    display: flex;
    justify-content: space-around;

    @media(max-width: 1024px){
      justify-content: center;
    }
    .checkMark {
      position: relative;
      margin: 5px;
      background-color: #EDEDED;
      margin-bottom: 20px;
      width: 25%;
      padding: 1vw;
      border-radius: 8px;
      background: var(--incorrect-color);
      border: 2px solid gray !important;

      .text {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 105%;
        left: 0;
        font-size: 12px;

        @media (max-width: 1024px) {
          // display: none;
        }
      }

      .img-fluid {
        max-height: 100%;
      }
      .checkMark_img {
        position: absolute;
        top: -10%;
        left: 100%;
        max-width: 30px;
        margin-left: -20px;
        margin-bottom: 10px;
        @media (max-width: 767px) {
          margin-left: -15px;
        }
      }
      .cross_red_img {
        position: absolute;
        top: -10%;
        left: 100%;
        margin-left: -20px;
        margin-bottom: 10px;
        max-width: 30px;
        @media (max-width: 767px) {
          margin-left: -12px;
        }
      }
    }
    @media (max-width: 991px) {
      padding-top: 0;
    }
  }

</style>

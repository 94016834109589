<template>
  <div
    class="container"
    ref="questionsRef"
    :style="cssVars"
  >
    <div class="row flex-column justify-content-center align-items-center">
      <div
        class="col-lg-8 question-wrapper"
        v-for="(question, idx) in questions"
        :key="idx"
      >
        <template v-if="!question.condition">
          <div v-if="question.type == 'checkbox'">
            <div
              class="question"
              v-html="question.text"
            ></div>
            <div
              class="subQuestion"
              v-html="question.subText"
            ></div>
            <div
              class="inputWrapper"
              role="group"
            >
              <div
                class="radio-input"
                v-for="(option, i) in question.options"
                :key="option.name"
                :class="questionModel[idx].indexOf(option) != -1 ? 'active' : ''"
              >
                <label
                  class="box-container w-100"
                  :for="`checkbox-group-${idx}-${i}`"
                >
                  <input
                    v-model="questionModel[idx]"
                    type="checkbox"
                    :id="`checkbox-group-${idx}-${i}`"
                    :name="`checkbox-group-${idx}`"
                    :value="option"
                    autocomplete="off"
                    checked
                    @change="updateCheckbox(idx)"
                  >
                  <span class="checkmark"></span>
                  {{ option }}
                </label>
              </div>
            </div>
            <input
              v-if="question.otherOption"
              class="input"
              v-show="showOtherOption(idx)"
              :disabled="!showOtherOption(idx)"
              :name="`checkbox-group-${idx}`"
              type="text"
              :placeholder="question.otherText"
              aria-label="Other option"
              value=""
              v-model="otherModel[idx]"
              @change="otherModelSelected(idx)"
            >
          </div>
          <div v-else-if="question.type=='radio'">
            <div
              class="question"
              v-html="question.text"
            ></div>
            <div
              class="subQuestion"
              v-html="question.subText"
            ></div>
            <div
              class="radio-input"
              v-for="option in question.options"
              :key="option.name"
              :class="questionModel[idx] == option ? 'active' : ''"
            >
              <label
                class="box-container w-100"
                :for="option.name"
              >
                <input
                  v-model="questionModel[idx]"
                  type="radio"
                  :id="`radio-group-${idx}`"
                  :name="`radio-group-${idx}`"
                  :value="option"
                  :disabled="option.disabled"
                  autocomplete="off"
                  checked
                >
                <span class="checkmark"></span>
                <span class="text">{{ option }}</span>
              </label>
            </div>
          </div>
          <div v-else-if="question.type=='textarea'">
            <div
              class="question"
              v-html="question.text"
            ></div>
            <textarea
              type="text"
              class="input w-100"
              rows="3"
              style="height: auto;"
              v-model="questionModel[idx]"
              value=""
              :placeholder="question.placeholder"
            ></textarea>
          </div>
          <!-- <div v-else-if="question.type=='star'">
            <div class="question" v-html="question.text"></div>
            <div class="subQuestion" v-html="question.subText"></div>
            <div class="fiveStarWrapper pb-4">
              <div class="fiveStar">
                  <div
                    v-for="(starItem , i) in stars"
                    :key="i"
                    class="starWrapper"
                    @click="onClickStars(i, idx)"
                  >
                    <img
                      :reference="i"
                      :src="getStarImage(i, idx)"
                    />
                  </div>
              </div>
            </div>
          </div> -->
          <div v-else-if="question.type=='rating'">
            <div
              class="question"
              v-html="question.text"
            ></div>
            <div
              class="subQuestion"
              v-html="question.subText"
            ></div>
            <div class="ratingWrapper">
              <div class="rating">
                <div
                  class="rate-input"
                  v-for="rate in question.rateCount"
                  :key="rate.name"
                  :class="questionModel[idx] == rate ? 'active' : ''"
                >
                  <label
                    class="box-container w-fit"
                    :for="rate.name"
                  >
                    <input
                      v-model="questionModel[idx]"
                      type="radio"
                      :id="`radio-group-${idx}`"
                      :name="`radio-group-${idx}`"
                      :value="rate"
                      :disabled="rate.disabled"
                      autocomplete="off"
                      :checked="rate==1 ? 'checked' : ''"
                      @click="changeRating(rate, idx)"
                    >
                    <span class="checkmark"></span>
                    <span class="text">{{ rate }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="question.condition && questionModel[question.condition.index] == question.condition.value">
          <div v-if="question.type == 'checkbox'">
            <div
              class="question"
              v-html="question.text"
            ></div>
            <div
              class="subQuestion"
              v-html="question.subText"
            ></div>
            <div
              class="inputWrapper"
              role="group"
            >
              <div
                class="radio-input"
                v-for="(option, i) in question.options"
                :key="option.name"
                :class="questionModel[idx].indexOf(option) != -1 ? 'active' : ''"
              >
                <label
                  class="box-container w-100"
                  :for="`checkbox-group-${idx}-${i}`"
                >
                  <input
                    v-model="questionModel[idx]"
                    type="checkbox"
                    :id="`checkbox-group-${idx}-${i}`"
                    :name="`checkbox-group-${idx}`"
                    :value="option"
                    autocomplete="off"
                    checked
                    @change="updateCheckbox(idx)"
                  >
                  <span class="checkmark"></span>
                  {{ option }}
                </label>
              </div>
            </div>
            <input
              v-if="question.otherOption"
              class="input"
              :disabled="!questionModel[idx].includes('Anders') || !questionModel[idx].includes('Andere')"
              :name="`checkbox-group-${idx}`"
              type="text"
              :placeholder="question.otherText"
              aria-label="Other option"
              value=""
              v-model="otherModel[idx]"
              @change="otherModelSelected(idx)"
            >
          </div>
          <div v-else-if="question.type=='radio'">
            <div
              class="question"
              v-html="question.text"
            ></div>
            <div
              class="subQuestion"
              v-html="question.subText"
            ></div>
            <div
              class="radio-input"
              v-for="option in question.options"
              :key="option.name"
              :class="questionModel[idx] == option ? 'active' : ''"
            >
              <label
                class="box-container w-100"
                :for="option.name"
              >
                <input
                  v-model="questionModel[idx]"
                  type="radio"
                  :id="`radio-group-${idx}`"
                  :name="`radio-group-${idx}`"
                  :value="option"
                  :disabled="option.disabled"
                  autocomplete="off"
                  checked
                >
                <span class="checkmark"></span>
                <span class="text">{{ option }}</span>
              </label>
            </div>
          </div>
          <div v-else-if="question.type=='textarea'">
            <div
              class="question whiteHeading"
              v-html="question.text"
              :class="addClass"
            ></div>
            <textarea
              type="text"
              class="input w-100"
              rows="3"
              style="height: auto;"
              v-model="questionModel[idx]"
              value=""
              :placeholder="question.placeholder"
            ></textarea>
          </div>
        </template>
      </div>
      <!-- <button @click="sendSurveyData">SEND</button> -->
      <div
        v-if="this.error"
        class="my-5"
      >
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "Survey",
  components: {
  },
  props: {
    fieldId: {
      type: String
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    questions: {
      type: Array,
      default: () => []
    },
    successAction: {
      type: Object,
      default: () => null
    },
    responseData: {
      type: Array,
      default: () => []
    },
    colorSchema: {
      type: String,
      default: ""
    },
    endpoint: {
      type: String
    },
    addClass:{
      type:String,
      required:false,
      default:''
    }
  },
  data () {
    return {
      questionModel: ['', '', '', '', 0, '', [], 0, [], [], ''],
      otherModel: [],
      otherModelDisabled: [],
      error: '',
      stars: [
        { "selected" : false },
        { "selected" : false },
        { "selected" : false },
        { "selected" : false },
        { "selected" : false }
      ]
    }
  },
  watch: {
    questionModel: {
      handler (val, old) {
        // console.log("Survey: ", val, old)
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.$eventHub.$on("sendSurvey-event", this.sendSurvey)
  },
  beforeDestroy () {
    this.$eventHub.$off("sendSurvey-event")
  },
  computed: {
    ...mapGetters(['customerToken' , 'campaignId', 'receiptToken']),
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  },
  methods: {
    ...mapActions(['sendSurveyData']),
    showOtherOption (idx) {
      return this.questionModel[idx].includes('Anders') || this.questionModel[idx].includes('Andere')
    },
    getStarImage (i, idx) {
      if (this.questionModel[idx] <= i) {
        return 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/savencia-tester-bresso/star-unchecked.svg'
      } else {
        return 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/savencia-tester-chavroux/star-checked.png'
      }
    },
    onClickStars (i, idx) {
      this.$set(this.questionModel, idx, i + 1)
    },
    updateCheckbox (idx) {
      if (this.questions[idx].otherOption) {
        if (this.questionModel[idx].indexOf('Anders') != -1 || this.questionModel[idx].indexOf('Andere') != -1) {
          this.otherModelDisabled[idx] = false
        } else {
          let i = this.questionModel[idx].indexOf(this.otherModel[idx])
          if (i != -1) {
            this.questionModel[idx].splice(i, 1)
          }
          this.otherModel[idx] = ""
          this.otherModelDisabled[idx] = true
        }
      }
    },
    otherModelSelected (idx) {
      this.questionModel[idx].push(this.otherModel[idx]);
    },
    changeRating (rate, idx) {
      if (this.questionModel[idx] == rate) rate = 0
      this.$set(this.questionModel, idx, rate)
    },
    sendSurvey (skipSurvey) {
      if (this.mandatory) {
        if (this.questionModel.some(x => x.length == 0)) {
          this.error = this.$t('Survey is mandatory')
          return;
        }
      }
      let data = {
        questions: []
      }
      let questions = {}
      for (let i = 0; i < this.questions.length; i++) {
        questions[this.questions[i].text] = this.questionModel[i]
      }
      data.questions[0] = questions
      // Send data to backend
      data.customerToken = this.customerToken;
      data.receiptToken = this.receiptToken;
      data.campaignId = this.campaignId;
      // console.log("sending survey", data)
      this.sendSurveyData( { data, endpoint: this.endpoint }).then(res => {
        if (this.responseData.length) {
          this.responseData.forEach(x => {
            this.$store.commit('setField', { field: x.variable, value: res[x.token] })
          })
        }
        if(res.response){
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
        } else {
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: false
          });
          this.$eventHub.$on('sendSurvey-event', this.sendSurveyData)
        }
      }).catch(err => {
        console.error(err)
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.cursorPointer {
  cursor: pointer;
}

.question-wrapper {
  width: 100%;
  color: var(--text-color-2);
  @media (max-width: 991px) {
    width: 90%;
  }
}

.text {
  color: #106D36;
  font-style: italic;
}

.question {
  display: block;
  font-size: 1.2em;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #106D36;
}

.subQuestion {
  font-size: 12px;
  line-height: 15px;
  font-style: italic;
  margin-bottom: 20px;
  color: #106D36;
}

.surveyBtnWrapper {
  display: flex;
  justify-content: center;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    width: 100%;
    border: 1px solid var(--input-border-color);
    border-radius: 8px;
    margin-bottom: 5px;
  }
}

.active {
  .box-container {
    opacity: 1;
    font-weight:800;
  }
  .text {
    font-weight: 800;
  }
}
.hide {
  display: none;
}

.show {
  display: block;
}

.input {
  padding: 10px;
  background: #FFFFFF;
  border: 1px solid #106D36 !important;
  box-sizing: border-box;
  border-radius: 8px;
  transition: border-color 0.15s;
  height: 40px;
  width: 100%;
}
.fiveStar {
  border-radius: 10px;
  opacity: 1;
  display: flex;
  justify-content: space-evenly;
  img {
    max-width: 36px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
}
.fiveStarWrapper {
  display: flex;
  justify-content: flex-start;

  cursor: pointer;
}

.starWrapper {
  display: flex;
  max-width: 40px;
}

/* The container */
.box-container {
  display: block;
  position: relative;
  padding: 5px 35px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 1;
  color: #106D36;
  font-style: italic;
}

/* Hide the browser's default radio button */
.box-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 15%;
  left: 10px;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid #106D36;
  border-radius: 50%;
  margin-bottom: 0 !important;
}

/* On mouse-over, add a grey background color */
.box-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.box-container input:checked ~ .checkmark {
  background-color: #FFF;
  border: 2px solid var(--color);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.box-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.box-container .checkmark:after {
  top: 1px;
  left: 1px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: var(--color);
}

.ratingWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  .rating {
    opacity: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .rate-input {
      display: inline-flex;
      position: relative;
      width: 50px;
      height: 50px;
      cursor: pointer;
      @media (max-width: 991px) {
        width: 20px;
        height: 20px;
      }
      .box-container {
        padding: 5px 25px;
        margin: 0;
        @media (max-width: 991px) {
          padding: 15px 15px;
        }
        @media (max-width: 450px) {
          padding: 10px 10px;
        }
      }
      .checkmark {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        top: 0;
        left: 0;
        @media (max-width: 991px) {
          width: 30px;
          height: 30px;
        }
        @media (max-width: 450px) {
          width: 20px;
          height: 20px;
        }
      }
      .box-container:hover input ~ .checkmark {
        background-color: #ccc;
      }
      .box-container input:checked ~ .checkmark {
        background-color: #FFF;
        border: none !important;
      }
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      .box-container input:checked ~ .checkmark:after {
        display: block;
      }
      .box-container .checkmark:after {
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: rgba(255, 212, 161, 1);
        @media (max-width: 991px) {
          width: 30px;
          height: 30px;
        }
        @media (max-width: 450px) {
          width: 20px;
          height: 20px;
        }
      }
      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 991px) {
          font-size: 14px;
        }
        @media (max-width: 450px) {
          font-size: 11px;
        }
      }
    }
  }
}
.whiteHeading{
  opacity:0;
  margin:0;
}
</style>

<template>
  <div ref="wrapper" :class="{ grabbing: dragStart }" class="wrapper" :style="{width: width + 'px', height: height + 'px'} ">
    <canvas
      v-if="image"
      :width="width"
      :height="height"
      ref="canvas"
      class="canvas"
      @mousedown="handleMouseDown"
      @mouseup="handleMouseUp"
      @mousemove="handleMouseMove"
      @touchstart="handleMouseDown"
      @touchmove="handleMouseMove"
    ></canvas>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'zoomer',
  props: {
    image: HTMLImageElement,
    width: Number,
    height: Number,
    scale: Number
  },
  data () {
    return {
      imagePosition: null,
      ratio: null,
      ctx: null,
      lastX: 0,
      lastY: 0,
      dragStart: 0,
      dragged: false,
      scaleFactor: 1.1,
      originalImageCtx: null,
      originalImageAndCanvasRatio: null
    }
  },

  watch: {
    image (image) {
      if (image) {
        setTimeout(() => {
          this.init()
          // init original image canvas
          let biggestSide = Math.max(image.width, image.height)
          this.originalImageCtx.canvas.width = biggestSide
          this.originalImageCtx.canvas.height = biggestSide

          this.shadowCtx.canvas.width = biggestSide
          this.shadowCtx.canvas.height = biggestSide

          this.originalImageAndCanvasRatio = this.originalImageCtx.canvas.width / this.width

          this.drawImageScaled()
          this.drawImageOriginal()
        })
      } else {
        if (this.ctx) {
          this.ctx.clearRect(0, 0, this.width, this.height)
        }
        this.imagePosition = null
        this.ratio = null
        this.lastY = null
        this.lastX = null
        this.$emit('position', null)
      }
    },

    scale (newScale, currentScale) {
      let factor = +(newScale - currentScale).toFixed(2)
      if (factor < 0) {
        factor = -1 / factor
      }
      this.ctx.scale(factor, factor)

      // apply to original image canvas
      this.originalImageCtx.scale(factor, factor)

      this.redraw()

      // scale coordination
      this.imagePosition = {
        x0: +(this.imagePosition.x0 * factor).toFixed(5),
        y0: +(this.imagePosition.y0 * factor).toFixed(5),
        x1: +(this.imagePosition.x1 * factor).toFixed(5),
        y1: +(this.imagePosition.y1 * factor).toFixed(5)
      }

      this.emitImagePosition()
    }
  },

  methods: {
    init () {
      this.ctx = this.$refs.canvas.getContext('2d')

      let originalImageCanvas = document.createElement('canvas')
      this.originalImageCtx = originalImageCanvas.getContext('2d')

      // we need this canvas to get rid from scale side effect
      let shadowCanvas = document.createElement('canvas')
      this.shadowCtx = shadowCanvas.getContext('2d')

      trackTransforms(this.ctx)
      trackTransforms(this.originalImageCtx)

      function trackTransforms (ctx) {
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        let xform = svg.createSVGMatrix()
        ctx.getTransform = function () {
          return xform
        }

        const savedTransforms = []
        const save = ctx.save
        ctx.save = function () {
          savedTransforms.push(xform.translate(0, 0))
          return save.call(ctx)
        }

        const restore = ctx.restore
        ctx.restore = function () {
          xform = savedTransforms.pop()
          return restore.call(ctx)
        }

        const scale = ctx.scale
        ctx.scale = function (sx, sy) {
          xform = xform.scaleNonUniform(sx, sy)
          return scale.call(ctx, sx, sy)
        }

        const rotate = ctx.rotate
        ctx.rotate = function (radians) {
          xform = xform.rotate(radians * 180 / Math.PI)
          return rotate.call(ctx, radians)
        }

        const translate = ctx.translate
        ctx.translate = function (dx, dy) {
          xform = xform.translate(dx, dy)
          return translate.call(ctx, dx, dy)
        }

        const transform = ctx.transform
        ctx.transform = function (a, b, c, d, e, f) {
          const m2 = svg.createSVGMatrix()
          m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f
          xform = xform.multiply(m2)
          return transform.call(ctx, a, b, c, d, e, f)
        }

        const setTransform = ctx.setTransform
        ctx.setTransform = function (a, b, c, d, e, f) {
          xform.a = a
          xform.b = b
          xform.c = c
          xform.d = d
          xform.e = e
          xform.f = f
          return setTransform.call(ctx, a, b, c, d, e, f)
        }

        const pt = svg.createSVGPoint()
        ctx.transformedPoint = function (x, y) {
          pt.x = x; pt.y = y
          return pt.matrixTransform(xform.inverse())
        }
      }
    },

    handleMouseDown (e) {
      e.preventDefault()
      let offsetX = e.offsetX
      let offsetY = e.offsetY
      // if (e instanceof TouchEvent) {
      //   const rect = e.target.getBoundingClientRect()
      //   offsetX = e.targetTouches[0].pageX - rect.left
      //   offsetY = e.targetTouches[0].pageY - rect.top
      // }

      this.lastX = offsetX || (e.pageX - this.$refs.canvas.offsetLeft)
      this.lastY = offsetY || (e.pageY - this.$refs.canvas.offsetTop)
      this.dragStart = this.ctx.transformedPoint(this.lastX, this.lastY)
      this.dragged = false
    },

    handleMouseUp (e) {
      this.dragStart = null
      if (!this.dragged) this.zoom(e.shiftKey ? -1 : 1)
    },

    handleMouseMove (e) {
      let prevX = this.lastX
      let prevY = this.lastY
      let offsetX = e.offsetX
      let offsetY = e.offsetY
      // if (e instanceof TouchEvent) {
      //   const rect = e.target.getBoundingClientRect()
      //   offsetX = e.targetTouches[0].pageX - rect.left
      //   offsetY = e.targetTouches[0].pageY - rect.top
      // }

      this.lastX = offsetX || (e.pageX - this.$refs.canvas.offsetLeft)
      this.lastY = offsetY || (e.pageY - this.$refs.canvas.offsetTop)
      this.dragged = true
      if (this.dragStart) {
        const pt = this.ctx.transformedPoint(this.lastX, this.lastY)
        this.ctx.translate(pt.x - this.dragStart.x, pt.y - this.dragStart.y)

        // apply to original image canvas
        const pto = this.originalImageCtx.transformedPoint(this.lastX * this.originalImageAndCanvasRatio, this.lastY * this.originalImageAndCanvasRatio)
        this.originalImageCtx.translate((pto.x - this.dragStart.x * this.originalImageAndCanvasRatio), (pto.y - this.dragStart.y * this.originalImageAndCanvasRatio))

        this.imagePosition = {
          x0: +(this.imagePosition.x0 + this.lastX - prevX).toFixed(5),
          y0: +(this.imagePosition.y0 + this.lastY - prevY).toFixed(5),
          x1: +(this.imagePosition.x1 + this.lastX - prevX).toFixed(5),
          y1: +(this.imagePosition.y1 + this.lastY - prevY).toFixed(5)
        }
        this.emitImagePosition()
        this.redraw()
      }
    },

    handleScroll (e) {
      let delta = e.wheelDelta ? e.wheelDelta / 40 : e.detail ? -e.detail : 0
      if (delta > 2 || delta < -2) {
        delta = delta / 10
      }
      if (delta) this.zoom(delta)
      return e.preventDefault() && false
    },

    redraw () {
      // Clear the entire canvas
      const p1 = this.ctx.transformedPoint(0, 0)
      const p2 = this.ctx.transformedPoint(this.$refs.canvas.width, this.$refs.canvas.height)
      this.ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y)

      this.ctx.save()
      this.ctx.setTransform(1, 0, 0, 1, 0, 0)
      this.ctx.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height)
      this.ctx.restore()

      // apply to original image canvas
      const op1 = this.originalImageCtx.transformedPoint(0, 0)
      const op2 = this.originalImageCtx.transformedPoint(this.originalImageCtx.canvas.width, this.originalImageCtx.canvas.height)
      this.originalImageCtx.clearRect(op1.x, op1.y, op2.x - op1.x, op2.y - op1.y)

      this.originalImageCtx.save()
      this.originalImageCtx.setTransform(1, 0, 0, 1, 0, 0)
      this.originalImageCtx.clearRect(0, 0, this.originalImageCtx.canvas.width, this.originalImageCtx.canvas.height)
      this.originalImageCtx.restore()

      this.drawImageScaled()
      this.drawImageOriginal()
    },

    zoom (clicks) {
      const factor = Math.pow(this.scaleFactor, clicks)

      // prevent scale down
      // if (((this.imagePosition.y1 * factor) - (this.imagePosition.y0 * factor)) < this.height) {
      //   return
      // }

      const pt = this.ctx.transformedPoint(this.lastX, this.lastY)
      this.ctx.translate(pt.x, pt.y)
      this.ctx.scale(factor, factor)
      this.ctx.translate(-pt.x, -pt.y)

      // apply to original image canvas
      const opt = this.originalImageCtx.transformedPoint(this.lastX * this.originalImageAndCanvasRatio, this.lastY * this.originalImageAndCanvasRatio)
      this.originalImageCtx.translate(opt.x, opt.y)
      this.originalImageCtx.scale(factor, factor)
      this.originalImageCtx.translate(-opt.x, -opt.y)

      this.redraw()

      let imageWidth = this.imagePosition.x1 - this.imagePosition.x0
      let imageHeight = this.imagePosition.y1 - this.imagePosition.y0

      // scale coordination
      this.imagePosition = {
        x0: +(this.imagePosition.x0 * factor).toFixed(5),
        y0: +(this.imagePosition.y0 * factor).toFixed(5),
        x1: +(this.imagePosition.x1 * factor).toFixed(5),
        y1: +(this.imagePosition.y1 * factor).toFixed(5)
      }

      // translate coordination
      let newImageWidth = this.imagePosition.x1 - this.imagePosition.x0
      let newImageHeight = this.imagePosition.y1 - this.imagePosition.y0

      let deltaWidth = newImageWidth - imageWidth
      let deltaHeight = newImageHeight - imageHeight

      let translateX = +(deltaWidth / (imageWidth / this.lastX)).toFixed(5)
      let translateY = +(deltaHeight / (imageHeight / this.lastY)).toFixed(5)

      this.imagePosition.x0 -= translateX
      this.imagePosition.x1 -= translateX
      this.imagePosition.y0 -= translateY
      this.imagePosition.y1 -= translateY

      this.emitImagePosition()
    },

    drawImageScaled () {
      const canvas = this.ctx.canvas
      const image = this.image
      const hRatio = canvas.width / image.width
      const vRatio = canvas.height / image.height
      this.ratio = Math.min(hRatio, vRatio)
      const centerShiftX = (canvas.width - image.width * this.ratio) / 2
      const centerShiftY = (canvas.height - image.height * this.ratio) / 2

      if (!this.imagePosition) {
        this.imagePosition = {
          x0: centerShiftX,
          y0: centerShiftY,
          x1: centerShiftX + image.width * this.ratio,
          y1: centerShiftY + image.height * this.ratio
        }
        // initial emit
        this.emitImagePosition()
        this.$emit('ratio', this.ratio)
      }

      this.ctx.clearRect(0, 0, canvas.width, canvas.height)
      this.ctx.drawImage(image, 0, 0, image.width, image.height, centerShiftX, centerShiftY, image.width * this.ratio, image.height * this.ratio)
    },

    drawImageOriginal () {
      // draw origin image
      const originalImageCanvas = this.originalImageCtx.canvas
      const ohRatio = originalImageCanvas.width / this.image.width
      const ovRatio = originalImageCanvas.height / this.image.height
      const ratio = Math.min(ohRatio, ovRatio)
      const ocenterShiftX = (originalImageCanvas.width - this.image.width * ratio) / 2
      const ocenterShiftY = (originalImageCanvas.height - this.image.height * ratio) / 2

      this.originalImageCtx.clearRect(0, 0, originalImageCanvas.width, originalImageCanvas.height)
      this.originalImageCtx.drawImage(this.image, 0, 0, this.image.width, this.image.height, ocenterShiftX, ocenterShiftY, this.image.width * ratio, this.image.height * ratio)

      this.drawImageShadow()
    },

    drawImageShadow: debounce(function () {
      if (!this.originalImageCtx) {
        return
      }
      const originalImageCanvas = this.originalImageCtx.canvas

      // rerender scaled image to another canvas to get rid from scale transformations
      this.shadowCtx.clearRect(0, 0, originalImageCanvas.width, originalImageCanvas.height)
      this.shadowCtx.drawImage(originalImageCanvas, 0, 0, originalImageCanvas.width, originalImageCanvas.height, 0, 0, originalImageCanvas.width, originalImageCanvas.height)

      this.$emit('input', {
        ctx: this.shadowCtx,
        ratio: this.originalImageAndCanvasRatio
      })
    }, 300),

    emitImagePosition: debounce(function () {
      this.$emit('position', {
        x0: this.imagePosition.x0,
        x1: this.imagePosition.x1,
        y0: this.imagePosition.y0,
        y1: this.imagePosition.y1
      })
    }, 0)
  }
}
</script>

<style scoped>
  .wrapper {
    font-size: 0;

    cursor: grab;
  }

  .wrapper.grabbing {
    cursor: grabbing;
  }
</style>

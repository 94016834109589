<template>
  <a
    v-if="this.action"
    :class="cssClass"
    @click="handleClick"
    :target="target"
    :style="styles"
    v-html="$t(text)"
  >
  </a>
  <a
    v-else
    :class="[cssClass, { disabled: disabledAnchors }]"
    :href="url"
    :target="target"
    :style="styles"
    v-html="$t(text)"
  ></a>
</template>

<script>
export default {
  name: "Anchor",
  props: {
    text: {
      type: String,
      default: "Empty Link Text"
    },
    target: {
      required: false,
      type: String,
      default: "_self"
    },
    url: {
      required: false,
      type: String,
      default: "#"
    },
    cssClass: {
      required: false,
      type: String,
      default: ""
    },
    styles: {
      required: false,
      type: Object
    },
    action: {
      type: [Object, Array]
    }
  },
  methods: {
    handleClick () {
      if (this.action.length > 0) {
        // console.log("inside actions loop::");
        for (let i = 0; i < this.action.length; i++) {
          const act = this.action[i];
          const fn = act.fn;
          const params = act.params;
          this[fn](params, this);
        }
        return;
      }
      const fn = this.action.fn;
      const params = this.action.params;
      this[fn](params, this);
    }
  },
  computed: {
    disabledAnchors () {
      return this.$store.state.disableAnchors;
    }
  }
};
</script>

<style lang="scss" scoped>
.disabled {
  display: none;
}
.liners-subtext {
  font-size: 20px;
  line-height: 24px;
}
a {
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
}
</style>

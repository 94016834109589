<template>
  <span></span>
</template>

<script>
export default {
  name: "refresh-token",
  mounted () {
    this.$store.dispatch("refreshTempId")
  }
};
</script>

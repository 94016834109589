<template>
  <button
    type="button"
    :class="`btn ${classes}`"
    :style="[styles]"
    :disabled="disabled || loading || dependencies"
    @click="clicked"
  >
    <div v-if="arrow.prev" class="arrow arrow-prev">
      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.41016 1.41L2.83016 6L7.41016 10.59L6.00016 12L0.000155878 6L6.00016 1.23266e-07L7.41016 1.41Z" :fill="arrow.color"/>
      </svg>
    </div>
    <div v-if="!loading">
      {{ $t(text) }}
    </div>
    <div
      v-else
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <div v-if="arrow.next" class="arrow arrow-next">
      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z" :fill="arrow.color"/>
      </svg>
    </div>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "button-component",
  data () {
    return {
      loading: false
    };
  },
  props: {
    requires: {
      type: Array
    },
    text: {
      required: false,
      type: String,
      default: "Button"
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    action: {
      type: [Object, Array]
    },
    classes: {
      type: String,
      default: ""
    },
    styles: {
      type: Object,
      default: () => {}
    },
    arrow: {
      type: Object,
      default: () => {
        return {
          prev: false,
          next: false,
          color: '#fff'
        }
      }
    }
  },
  computed: {
    ...mapGetters(['hasUnresolvedDependencies']),
    dependencies () {
      return this.hasUnresolvedDependencies(this.requires);
    }
  },
  methods: {
    async clicked () {
      if (!this.action) return;
      if (this.action.anchor) {
        window.location.href = this.action.link
      }
      if (this.action.length > 0) {
        for (let i = 0; i < this.action.length; i++) {
          const act = this.action[i];
          const fn = act.fn;
          const params = act.params;
          await this[fn](params, this);
        }
        return;
      }
      const fn = this.action.fn;
      const params = this.action.params;
      await this[fn](params, this);
    }
  }
};
</script>

<style lang="scss">
.btn {
  font-family: 'Source Sans Pro', sans-serif !important;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  min-width: fit-content;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 24px !important;
  line-height: 19px;
  font-weight: 600;
  border-radius: 30px !important;
  text-align: center;
  padding: 10px 20px !important;
  max-width: 200px;
  position: relative;
  @media(max-width: 768px){
    font-size: 14px;
    padding: 15px 10px;
    margin: 10px 0px;
  }
  @media (max-width: 425.97px) {
    font-size: 11px;
    padding: 5px;
    max-width: 320px !important;
    margin: 10px 0px;
  }
  &_disabled {
    opacity: 0.4;
  }

  &-pri {
    &-next {
      color: #E42314;
      background-color: #FFFFFF;
      border: 1px solid #303030;
      &:hover {
        background-color: #FFFFFF;
      }
      &:focus {
        background-color: #EF3340;
      }
      &:disabled {
        background-color: #E42314;
      }
    }
    &-back {
      background-color:#FFF;
      color: #FFFFFF;
      border: 1px solid #303030;
      &:hover {
        color: #FFFFFF;
      }
      &:focus {
        color: #EF3340;
      }
      &:disabled {
        color: #E42314;
      }
    }
  }
  &-sec {
    &-next {
      background: #E42314 !important;
      color: #ffffff !important;
      border: 2px solid #E42314 !important;
      &:hover {
        background: #FFFFFF;
        color: #E42314;
      }
      &:disabled {
        background-color: #E42314;
        color: #FFFFFF;
      }
      &:focus {
        background-color: #EF3340;
        color: #303030;
      }
    }
    &-back {
      background-color: #FFF !important;
      color: #E42314 !important;
      border: 2px solid #E42314 !important;

      &:hover {
        color: #E42314;
      }
      &:disabled {
        color: #E42314;
      }
      &:focus {
        color: #EF3340;
      }
    }
  }

  .arrow {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;

    &-prev {
      left: 10%
    }

    &-next {
      right: 10%;
    }

    svg {
      width: 12px;
      height: auto;
    }
  }
}
</style>

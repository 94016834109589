<template>
  <div class="" :class="mainWrapperClass">
    <ValidationProvider
      v-if="validation"
      :rules="validation"
      v-slot="{ errors }"
      :name="name"
    >
    <div class="top-label">
      {{label}}
    </div>
    <div class="form-group">
      <div class="input-group">
        <label v-if="labelOnTop" :style="labelOnTopStyle" class="input-label">
          {{$t(label)}}
          <span v-if="validation.includes('required')">*</span>
          <span v-else>{{$t('(optional)')}}</span>
          <span v-if="name === 'iban' || name === 'bic'" class="mx-3">
            <b-icon icon="exclamation-circle-fill" variant="dark" :id="name">
            </b-icon>
            <b-tooltip custom-class="text-black" :target="name" triggers="hover" variant="secondary" placement="topright">
              <b>{{ $t(toolTip.title) }}</b>
              <br>
              <span v-html="$t(toolTip.text)"></span>
            </b-tooltip>
          </span>
        </label>
        <input
          :style="styles"
          :type="inputType"
          class="form-control textInput"
          :class="{'is-invalid': errors.length}"
          :value="text == '' ? value : text"
          @input="updateValue($event.target.value)"
          ref="input"
          :name="name"
          :id="id"
          :placeholder="$t(placeholder)"
          :maxlength="maxlength"
          :title="placeholder"
        />
        <div v-if="inputType === 'password'" class="input-group-append" style="height: 46px;">
          <span class="input-group-text" @click="revealPass()" @keypress.enter="revealPass()" tabindex="0" id="basic-addon2"><v-icon :name="passIcon"/></span>
        </div>
        <!-- <label v-if="!labelOnTop && !noLabel" :for="id" class="floating-label" :class="{'slideUp': slideUp}">
          {{ $t(label) }}
        </label> -->
      </div>
      <div
        class="validation-error"
        v-if="errors.length"
      >{{errors[0]}}</div>

    </div>
    </ValidationProvider>
    <div
      class=""
      v-else
    >
      <div class="form-group">
        <label v-if="labelOnTop" :style="labelOnTopStyle" class="input-label">
          {{$t(label)}}
          <span v-if="validation.includes('required')">*</span>
          <span v-else>{{$t('(optional)')}}</span>
        </label>
        <div v-if="!labelOnTop && !noLabel" :for="id" class="top-label" :class="{'slideUp': slideUp}">
          {{ $t(label) }}
        </div>
        <input
          :style="styles"
          :type="inputType"
          class="form-control textInput"
          :value="text == '' ? value : text"
          @input="updateValue($event.target.value)"
          ref="input"
          :name="name"
          :id="id"
          :placeholder="$t(placeholder)"
          :maxlength="maxlength"
          :title="placeholder"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: "text-input",
  data () {
    return {
      passIcon: 'regular/eye'
    }
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    inputType: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    text: {
      type: String,
      default: ""
    },
    validation: {
      type: String,
      required: false
    },
    value: {
      required: false
    },
    maxlength: {
      type: Number,
      required: false
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    slideUp:Boolean,
    labelOnTop: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    labelOnTopStyle: {
      type: Object,
      default: () => {}
    },
    isUpperCase: Boolean,
    toolTip: {
      type: Object,
      default: () => {
        return {}
      }
    },
    mainWrapperClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    updateValue (val) {
      let value = this.isUpperCase ? val.toUpperCase() : val;
      if (this.name === "iban") {
        value = value.replace(/[^\da-zA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim().toUpperCase();
      }
      this.$emit("component-updated", {
        text: value,
        slideUp:!!value
      });
      this.$emit("input", value);
      this.$store.dispatch("setComponentStatus", {
        component: this.id,
        status: !!value
      });
    },
    revealPass () {
      var x = document.getElementById(this.id);
      if (x.type === "password") {
        x.type = "text";
        this.passIcon = 'regular/eye-slash'
      } else {
        x.type = "password";
        this.passIcon = 'regular/eye'
      }
    }
  }
};
</script>
<style scoped>
  #basic-addon2 {
    background-color: #F0F4FD !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
</style>

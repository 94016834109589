import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './languages'

Vue.use(VueI18n);

// Create VueI18n instance with options
export const i18n = new VueI18n({
  locale: 'de',
  messages,
  silentTranslationWarn: process.env.NODE_ENV === 'production'
});

import heading from "@/components/renderers/heading"
import paragraph from "@/components/renderers/paragraph"
import squareImage from "@/components/renderers/square-image"
import alert from "@/components/renderers/alert"
import keyVisual from "@/components/renderers/key-visual"
import navbar from "@/components/renderers/navbar"
import anchor from "@/components/renderers/anchor"
import receiptProcessor from "@/components/renderers/receipt-processor"
import productUpload from "@/components/renderers/product-upload"
import formComponent from "@/components/renderers/form-component"
import textInput from "@/components/renderers/text-input"
import stepCard from "@/components/renderers/step-card"
import selectInput from "@/components/renderers/select-input"
import radioInput from "@/components/renderers/radio-input"
import dateInput from "@/components/renderers/date-input"
import buttonComponent from "@/components/renderers/button-component"
import productSelection from "@/components/renderers/ProductSelection/index.vue"
import emptyBucket from "@/components/renderers/empty-bucket"
import receiptInformation from "@/components/renderers/receipt-information"
import receiptExamples from "@/components/renderers/receipt-examples"
import checkbox from "@/components/renderers/checkbox"
import stepCounter from "@/components/renderers/step-counter"
import footerComponent from "@/components/renderers/footer-component"
import reclaimBucket from "@/components/renderers/reclaim-bucket"
import injectTag from "@/components/renderers/inject-tag"
import dataLayerEvent from "@/components/renderers/datalayer-event"
import refreshToken from "@/components/renderers/refresh-token"
import queryStringToState from "@/components/renderers/query-string-to-state"
import barcodeScanner from "@/components/renderers/BarcodeScanner"
import ibanReupload from "@/components/renderers/iban-reupload"
import checkReceiptToken from "@/components/renderers/checkReceiptToken"
import participationCounter from "@/components/renderers/participation-counter"
import doi from "@/components/renderers/doi"
import clipboard from "@/components/renderers/clipboard"
import survey from "@/components/renderers/survey"
import embeddedDoc from "@/components/renderers/embedded-doc"
import imageComponent from "@/components/renderers/image-component"
import participationSteps from "@/components/renderers/participation-steps"
import stepsHorizontal from "@/components/renderers/steps-horizontal"
import campaignSteps from "@/components/renderers/campaign-steps.vue"
import pdfViewer from "@/components/renderers/pdf-viewer.vue"

export default [
  barcodeScanner,
  embeddedDoc,
  refreshToken,
  dataLayerEvent,
  injectTag,
  reclaimBucket,
  ibanReupload,
  stepCounter,
  receiptInformation,
  receiptExamples,
  heading,
  paragraph,
  squareImage,
  alert,
  emptyBucket,
  keyVisual,
  navbar,
  anchor,
  receiptProcessor,
  productUpload,
  formComponent,
  textInput,
  stepCard,
  selectInput,
  radioInput,
  dateInput,
  buttonComponent,
  productSelection,
  checkbox,
  footerComponent,
  queryStringToState,
  checkReceiptToken,
  participationCounter,
  doi,
  clipboard,
  survey,
  imageComponent,
  participationSteps,
  stepsHorizontal,
  campaignSteps,
  pdfViewer
];

<template>
  <div
    :class="mainWrapperClass"
  >
    <ValidationProvider
      v-if="validation"
      :name="name.toLowerCase()"
      :rules="validation"
      v-slot="{ errors }"
    >
      <div class="form-group">
        <div class="input-group">
          <div
            v-if="labelOnTop"
            :style="labelOnTopStyle"
            class="top-label"
          >
            {{ $t(label) }}
            <span v-if="validation.includes('required')">*</span>
          </div>
          <select
            v-model="localSelected"
            required
            ref="select"
            :name="name"
            :id="field.fieldId"
            class="form-control"
            :class="{'is-invalid': errors.length}"
          >
            <option
              value
              disabled
              :selected="!selectedValue"
              hidden
            >
              {{ localSelected ? '': $t(placeholder) }}
            </option>
            <option
              v-for="option in options"
              :key="option.value"
              :selected="option.value == selectedValue"
              :disabled="option.disabled"
              :value="option.value"
            >
              {{ $t(option.text) }}
            </option>
          </select>
        </div>
        <div
          class="validation-error"
          v-if="errors.length"
        >
          {{ errors[0] }}
        </div>
      </div>
    </ValidationProvider>
    <template v-else>
      <div class="form-group">
        <div class="input-group">
          <div
            v-if="labelOnTop"
            :style="labelOnTopStyle"
            class="top-label"
          >
            {{ $t(label) }}
          </div>
          <select
            v-model="localSelected"
            required
            ref="select"
            :name="name"
            :id="field.fieldId"
          >
            <option
              value
              disabled
              :selected="!selectedValue"
              hidden
            >
              {{ localSelected ? '': $t(placeholder) }}
            </option>
            <option
              v-for="option in options"
              :key="option.value"
              :selected="option.value == selectedValue"
              :disabled="option.disabled"
              :value="option.value"
            >
              {{ $t(option.text) }}
            </option>
          </select>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "select-input",
  data () {
    return {
      localSelected: this.selectedValue || ""
    };
  },
  props: {
    field: {
      required: false,
      type: Array,
      default: () => {
        return []
      }
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    selectedValue: {
      type: String,
      required: false,
      default: ""
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select Something"
    },
    width: {
      type: String,
      default: "100%"
    },
    validation: {
      type: String,
      default: ""
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    labelOnTop: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    showFlags: {
      type: Boolean,
      default: false
    },
    labelOnTopStyle: {
      type: Object,
      default: () => {}
    },
    mainWrapperClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  watch: {
    localSelected (value) {
      this.$emit("component-updated", {
        selectedValue: value
      });
      this.$emit("input", value);
    }
  }
};
</script>

<style scoped lang="scss">
select {
  padding: 6px 12px;
  border: 1px solid #E0E0E0 !important;
  border-radius: 8px !important;
}
</style>

<template>
  <div>
    <div style="position: relative">
      <div class="container participa-wrapper">
        <div class="steps">
          <steps-horizontal
            :steps="steps"
            :textColor="color"
            :borderColor="borderColor"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepsHorizontal from './steps-horizontal.vue';

export default {
  components: { StepsHorizontal },
  name: "participation-steps",
  props: {
    color: {
      type: String,
      default: "#f2ac29"
    },
    borderColor:{
      type: String,
      default: "#ebf0fa"
    },
    buttonClass: {
      type: String,
      default: ""
    },
    steps: {
      type: Array,
      default: () => []
    },
    nextPage: {
      type: String,
      default: ""
    }
  },
  computed: {
    camapignActive () {
      const startDate = new Date(
        new Date(this.$store.state.campaignStartDate).setHours(0, 0, 0, 0)
      ).toLocaleString("en-US");
      const endDate = new Date(
        new Date(this.$store.state.campaignEndDate).setHours(0, 0, 0, 0)
      ).toLocaleString("en-US");
      const currentTime = new Date().toLocaleString("en-US", {
        timeZone: "Europe/Bucharest"
      });
      const dayInSeconds = 86400; // If we need to include the ending day
      
      const isTestVersion = this.$route.query.version === "test";

      return (
        isTestVersion ||
        (new Date(startDate).getTime() / 1000 <=
          new Date(currentTime).getTime() / 1000 &&
          new Date(endDate).getTime() / 1000 + dayInSeconds >=
            new Date(currentTime).getTime() / 1000)
      );
    }
  }
};
</script>
<style lang="scss">
.participa-wrapper {
  position: static;

  h1 {
    margin-top: 20px;
    @media (max-width: 545px) {
      font-size: 34px !important;
      line-height: 35px !important;
    }
  }

  .steps {
    margin: 20px 0 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 767px) {
      margin: 50px 0 30px;
    }
  }
}

.campaignOverWrapper {
  margin-top: 40px;

  @media (max-width: 767px) {
    margin-top: 20px;
    margin-bottom: 0 !important;
  }
}

.decoration {
  position: absolute;
}

.page_404 img {
  width: 100%;
}

.image_block {
  /* background-image: url(../../assets/images/gif/close_campaign.gif); */
  height: 400px;
  width: 100%;
  background-position: center;
  background-size: cover;

  @media (max-width: 767px) {
    height: 320px;
  }
}

.contant_box_404 {
  p {
    font-size: 18px;
    padding-bottom: 130px;
    color: #081821;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  button {
    margin-top: 60px;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.campaignOver {
  h2 {
    line-height: 40px;
    margin-top: 30px;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      line-height: 30px;
      font-size: 22px;
    }
  }
}
</style>./steps-horizontal.vue

<template>
  <component :is="updateComponent" :class="classes" :style="styles" v-html="$t(html)"></component>
</template>

<script>
import debounce from "lodash/debounce";
import {mapGetters} from 'vuex'
export default {
  name: "paragraph",
  props: {
    text: {
      type: String,
      default: "Empty paragraph"
    },
    text2: {
      type: String,
      required: false,
      default: ''
    },
    grid: {
      type: Object,
      default: () => {
        return {
          backgroundColor: "#fff"
        }
      }
    },
    action: {
      type: [Object, Array]
    },
    variables: Object,

    conditionalText: {
      type: Boolean,
      required: false,
      default: false,
    },

    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    classes: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters(['optIn']),
    updateComponent () {
      if (this.classes.includes('heading')) {
        return 'h1'
      } else if (this.classes.includes('subHeading')) {
        return 'h2'
      } else {
        return 'p'
      }
    },
    html () {
      let result = this.text;
      if (this.variables) {
        for (const variable in this.variables) {
          let value = this.$store.state[variable];
          result = result.replace(new RegExp(`{${variable}}`, "g"), value);
        }
      }
      if(this.conditionalText && this.optIn){
        result = this.text2
      }
      return result;
    }
  },
  methods: {
    // updateComponent: debounce(function (event) {
    //   this.$emit("component-updated", {
    //     text: event.target.innerText
    //   });
    // }, 2000),
    // async triggerActions () {
    //   console.log("CLICK")
    //   if (this.action.length > 0) {
    //     for (let i = 0; i < this.action.length; i++) {
    //       const act = this.action[i];
    //       const fn = act.fn;
    //       const params = act.params;
    //       await this[fn](params, this);
    //     }
    //     return;
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
  .orange-100px-heading{
    font-family: 'Source Sans Pro', sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 100px;
    line-height: 115px;
    color: #FF840E;
    text-transform: uppercase;
  }
  .overrideMarginBot{
    @media(max-width: 450px){
      margin-bottom: 0px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  }
  .addPadding{
    @media(max-width: 450px){
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
  }
  .paragraphBg{
    @media(max-width: 450px){
      background: transparent;
    }
  }
  .headingCustom{
    font-family: 'Source Sans Pro', sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #b75810;
    &-20{
      font-family: 'Source Sans Pro', sans-serif !important;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      color: #b75810;
    }
    @media(max-width: 768px){
      font-size: 25px;
      &-20{
        font-family: 'Source Sans Pro', sans-serif !important;
        font-style: normal;
        font-weight: 700;
        color: #b75810;
        font-size: 20px;
      }
    }
  }
  .gray-s-subheading{
    font-family: 'Source Sans Pro', sans-serif !important;
    font-style: normal;
    font-weight: 325;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #303030;
  }
</style>

import store from '../store'

export default {
  methods: {
    showModal: (message, type='error') => {
      const isActive = true
      store.commit('setModalOptions', { type, message, isActive })
    },
    hideModal: () => {
      store.commit('setModalOptions', { type: store.state.modal.type, message: '', isActive: false })
    }
  }
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import lodash from 'lodash'
import { i18n } from './translate'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/mixins';
import '@/vee-validate'
import VueMeta from 'vue-meta'


import './assets/styles/resp-table.css';


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(VueMeta)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.prototype._ = lodash

import '@/helpers/common-components.js';
Vue.config.productionTip = false
const EventBus = new Vue()
Object.defineProperties(Vue.prototype, {
  $eventHub: {
    get: function () {
      return EventBus
    }
  }
})

// Vue.prototype.$eventHub = new Vue();
new Vue({
  i18n,
  router,
  store,
  render: h => {
    return h(App)
  }
}).$mount('#app > div')

<template>
<span></span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "doi",
  props: {
    endpoint: {
      type: String,
      required: true
    },
    title: String,
    text: String,
    button: String
  },
  data: () => {
    return {
      verification_code: "",
      loading: true,
      sent: false
    };
  },
  methods: {
    ...mapActions(['checkDoi'])
  },
  mounted () {
    this.verification_code = this.$route.query.token;

    this.checkDoi({ verification_code: this.verification_code, endpoint: this.endpoint })
      .then(res => {
        if (res.response) {
          this.sent = true;
        }
      })
      .catch(er => {
        this.goToPage('expired-page', this)
      });
  }
};
</script>

<template>
  <span></span>
</template>

<script>
export default {
  name: 'pdf-viewer',
  props: {
    link: {
      type: String,
      default: ''
    }
  },
  mounted () {
    if (navigator.userAgent.includes('Android')) {
      window.open(this.link, '_blank')
      setTimeout(() => {
        window.close()
      }, 1000);
    } else {
      window.location.href = this.link
    }
  }
}
</script>
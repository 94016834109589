import deepdash from "deepdash-es";
export default {
  methods: {
    updateProperty (field, newVal) {
      let clone = this._.cloneDeep(this.$store.state.pageProperties);
      const __ = deepdash(this._);
      let f = __.findDeep(clone, fld => {
        return fld === field.fieldId
      });
      if (f) {
        this._.set(clone, f.context.parent.path + ".properties", newVal);
        // console.info("clone", clone, "found", f, "newVal", newVal)
        this.$store.commit("setPageProperties", clone);


      }

    },
    updateGrid (field, newVal) {
      let clone = this._.cloneDeep(this.$store.state.pageProperties);
      const __ = deepdash(this._);
      let f = __.findDeep(clone, fld => {
        return fld === field.fieldId
      });
      if (f) {
        this._.set(clone, f.context.parent.path + ".properties.grid", newVal);
        // console.info("clone", clone, "found", f, "newVal", newVal)
        this.$store.commit("setPageProperties", clone);


      }

    },
    getProperties (field) {
      let clone = this._.cloneDeep(this.$store.state.pageProperties);
      const __ = deepdash(this._);
      let f = __.findDeep(clone, fld => {
        return fld === field.fieldId
      });
      if (f) {
        return this._.get(clone, f.context.parent.path + ".properties");

      }
      return false

    }

  }
}
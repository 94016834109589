<template>
  <nav class="navbar navbar-wrapper navbar-expand-lg" ref="navbarWrapper" :style="[cssVars, styles]">
    <button
      class="navbar-toggler col-1"
      type="button"
      @click="checkBoxClicked"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="md:justify-content-center d-lg-flex d-xl-flex col-6 md:col-12 p-0 sm:p-3.5 position-static">
      <h1 v-if="title" class="m-0 p-0"></h1>
      <a href="/">
        <img class="logo" :src="logo.src" alt="">
      </a>
    </div>

    <div class="navbar-collapse expanded" ref='expanded'>
      <ul class="navbar-nav">
        <li class="navbarLinks" v-for="field in fields" :key="field.fieldId">
          <componentRenderer v-bind:key="field.id" :field="field" />
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import componentRenderer from "@/components/component-renderer";

export default {
  name: "navbar",
  components: {
    componentRenderer
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {
          "background": "#FFF",
          "color": "#000"
        }
      }
    },
    title: {
      type: String
    },
    logo: {
      type: Object,
      default: () => {
        return {
          "src": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/savencia/pulled-chicken-de/logo.png",
          "alt": "logo"
        }
      }
    },
    fields: {
      required: false,
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    cssVars () {
      return {
        '--color': this.styles.color,
        '--bg-color': this.styles.background
      }
    }
  },
  methods: {
    checkBoxClicked () {
      const expanded = this.$refs['expanded'];
      const navbarWrapper = this.$refs['navbarWrapper']
      expanded.style.maxHeight = expanded.style.maxHeight ? null : expanded.scrollHeight + 10 + "px";
      // navbarWrapper.style.borderRadius = navbarWrapper.style.borderRadius === '10px' ? '30px' : "10px"
      // navbarWrapper.style.height = navbarWrapper.style.height === '150px' ? "null" : "150px"
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar-wrapper {
  justify-content: space-between;
  z-index: 9999;
  position: relative;
  height: 88px;
  @media(max-width: 450px){
    max-width: 100vw !important;
    display: flex;
    align-items: center;
    margin: 0px !important;
    padding: 0px !important;
  }
  .navbar-brand {
    margin: 0 !important;
    height: 100%;

    img {
      margin: 0 20px;
      // height: 100%;

      @media(max-width: 450px){
        top: 12px;
        max-width: 90px;
        position: static !important;
        margin: 0px !important;
      }
    }
  }

  .nav-link {
    color: #fff !important;
    text-transform: uppercase;
    font-family: "'Source Sans Pro', sans-serif" !important;
    position: relative;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0.5rem;
      content: '';
      width: 0;
      height: 3px;
      background-color: #4E4B76;
    }

    &:hover {
      color: #4E4B76;
      text-decoration: none !important;

      &::after {
        width: calc(100% - 1rem);
      }
    }
  }
}
.navbar-collapse{
  flex-grow:initial;

  .navbar-nav {
    align-items: center;

    li {
      width: fit-content;
    }
  }
}

.navbar-toggler {
  position: absolute;
}

.navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  width: 24px !important;
  height: 24px !important;
}
.expanded {
  @media (max-width: 991px) {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
    -moz-transition: height 0.4s ease-out;; /* Firefox 4 */
    -webkit-transition: height 0.4s ease-out;;  /* Safari and Chrome */
    -o-transition: height 0.4s ease-out;;  /* Opera */
    margin-top: 88px;
    background-color: #e42314;
  }
}

.logo {
  width: auto;
  height: 84px;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  @media(max-width: 600px) {
    width: auto;
    height: 60px;
    top: 20px;
  }
}
</style>

import Vue from "vue";
import generateUID from "./generateUID"
import updateProperty from "./updateProperty"
import actions from "./componentActions"
import showModal from "./showModal"
import cssVars from "./cssVars"
import scrollTo from './scrollTo';

Vue.mixin(generateUID)
Vue.mixin(updateProperty)
Vue.mixin(actions)
Vue.mixin(showModal)
Vue.mixin(cssVars)
Vue.mixin(scrollTo);

export default {
  generateUID,
  updateProperty,
  showModal,
  scrollTo
}
<template>
  <div :class="mainWrapperClass" class="receipt-uploader" :style="[styles, cssVars]"> 
    <div class="wrapper_file_upload" :style="[styles, cssVars]"
          :class="{ highlight: highlight }"
          @dragover.prevent
          @drop.stop.prevent="handleDrop"
          @dragenter.stop.prevent="onDragEnter"
          @dragleave.stop.prevent="onDragLeave"
    >
      <label v-show="!selectedFile" class="file_upload" tabindex="0" for="file">
        <input
          @change="onFilePicked"
          accept="image/*"
          class="inputfile"
          type="file"
          name="file"
          ref="file"
          id="file"
        />
        <svg width="70" height="48" viewBox="0 0 70 48" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-bottom: 25px;">
          <path d="M58.725 20.9662C57.2319 9.206 47.163 0.0834961 34.9997 0.0834961C25.5833 0.0834961 17.4038 5.58433 13.6694 14.2592C6.33042 16.4562 0.833008 23.3852 0.833008 30.8335C0.833008 39.9116 7.97043 47.2847 16.9187 47.8177V47.9168H55.4655V47.9066L55.4997 47.9168C63.0368 47.9168 69.1663 41.7873 69.1663 34.2502C69.1623 31.1872 68.1315 28.214 66.2387 25.8059C64.3458 23.3977 61.7003 21.6937 58.725 20.9662ZM21.333 24.0002H31.583V13.7502H38.4163V24.0002H48.6663L34.9997 41.0835L21.333 24.0002Z" fill="#828282"/>
        </svg>
        <div class="text1" v-html="$t(this.texts.text1)"></div>
        <div class="text2" v-html="$t(this.texts.text2)"></div>
      </label>
      <div v-show="selectedFile" class="row row-dop justify-content-center mt-4">
        <label class="wrapper_square_img">
          <img
            class="square-img img-fluid"
            ref="preview"
            src="../../assets/icons/placeholder.svg"
            alt
          />
          <div @click="removeImage" class="delete_block">
            <img class="delete_img" src="../../assets/icons/delete.svg" alt="delete" />
          </div>
        </label>
      </div>
      <button v-if="selectedFile" @click.prevent="selectAnother" @keypress.enter="selectAnother" tabindex="0" class="btn btn_abs btn-sec-back mb-5">{{ $t(this.texts.choose) }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "product-upload",
  props: {
    fieldId: String,
    endpoint: String,
    successAction: {
      type: Object,
      required: true
    },
    value: {
      required: false
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B"
    },
    tokenName: {
      required: false,
      type: String,
      default: "token"
    },
    reUpload: {
      required: false,
      type: Boolean
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    styles: {
      type: Object,
      default: () => {}
    },
    productPhotoIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      selectedFile: null,
      uploadSectionHover: false,
      highlight: false
    };
  },
  methods: {
    clear () {
      this.selectedFile = null;
      this.setValue(null);
    },
    removeImage () {
      this.selectedFile = null;
      this.setValue(null);
      this.$refs.file.value = null;
    },
    onFilePicked (event) {
      const files = event.target.files || event.dataTransfer.files;
      this.handleFiles(files)
    },
    onDragEnter () {
      this.highlight = true
    },
    onDragLeave () {
      this.highlight = false
    },
    handleDrop (e) {
      let dt = e.dataTransfer
      let files = dt.files
      this.handleFiles(files)
    },
    handleFiles (files) {
      const maxfilesize = 1024 * 1024 * 16; // 1 Mb

      if (files && files[0]) {
        let filename = files[0].name;

        if (filename && filename.lastIndexOf(".") <= 0) {
          return;
        }
        if (files[0].size > maxfilesize) {
          // this.showModal(this.$t(this.texts.fileSizeLimit));
          alert(this.$t(this.texts.fileSizeLimit));
          this.$refs.image.type = '';
          this.$refs.image.type = 'file';
          return;
        }
        const extension = files[0].name.split(".").pop();
        const found = ["png", "jpg", "jpe", "jpeg"].filter(
          ext => ext === extension.toLowerCase()
        );
        if (!found.length) {
          // this.showModal(this.$t(this.texts.wrongFormat));
          alert(this.$t(this.texts.wrongFormat));
          this.$refs.image.type = '';
          this.$refs.image.type = 'file';
          return;
        }
        this.selectedFile = files[0];
        this.setPreview(this.selectedFile);
        this.setValue(this.selectedFile);
      }
    },
    setValue (value) {
      this.$emit("component-updated", {
        value
      });
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: !!value
      });
    },
    setPreview (file) {
      const reader = new FileReader();

      this.selectedFile = file;
      reader.onload = e => {
        this.$refs.preview.src = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    sendProductPhoto (formId, triggers) {
      const formData = {
        image: this.selectedFile,
        endpoint: this.endpoint,
        receiptToken: this.receiptToken,
        productPhotoIndex: this.productPhotoIndex
      };

      if (this.reUpload) {
        formData["receiptToken"] = this.receiptToken;
        formData["customerToken"] = 1;
      }

      this.$store.dispatch("uploadProductPhotos", formData).then(r => {
        // console.log(this.successAction, this.response);
        if (r.response && this.successAction) {
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: true
          });
        }
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      }).catch(err => {
        // console.log("Error", err)
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      });
    },
    selectAnother () {
      this.$refs.file.click();
    }
  },
  mounted () {
    if (this.value) {
      this.setPreview(this.value);
    }
    this.$eventHub.$on("sendProductPhotos-event", this.sendProductPhoto);
  },
  computed: {
    ...mapGetters(['receiptToken']),
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  }
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.wrapper_square_img {
  position: relative;
  display: inline-block;
  width: 89%;
}
.delete_block {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition: transform .5s;
  }
}
.delete_block .delete_img {
  width: 16px;
}
.inputfile {
  display: none;
}
.wrapper_file_upload {
  position: relative;
  height: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 2px dashed #106D36;
  border-radius: 20px;
  background: #FFFFFF;

  @media (max-width: 767px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  &_prog {
    border-style: solid;
  }
  .file_upload {
    width: 100%;
    text-align: center;
    cursor: pointer;
    padding-top: 40px;
    padding-bottom: 20px;
    .text {
      &1 {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0px;
        color: #828282;
        opacity: 1;
        padding-bottom: 15px;
      }

      &2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0px;
        color: #828282;
        opacity: 1;
      }
    }
  }
}
.file_upload input[type="file"] {
  display: none;
}
.btn {
  position: absolute;
  margin: 0 auto;
  bottom: 0;
  padding: 10px 30px;
  width: 100%;
  z-index: 1000;
  background-color: var(--color);
  color: #FFF;
  border-radius: 0;
  border: 2px solid #106D36;
  @media (max-width: 767px) {
    // width: 100%;
    padding: 10px 30px;
  }
}
</style>

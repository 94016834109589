<template>
  <div ref="wrapper">
    <div
      class="layers-wrapper"
      ref="canvasWrapper"
    >
      <zoomer
        class="layer layer1"
        :class="{'active-layer': mode === 'drag'}"
        v-if="canvasLength"
        :width="canvasLength"
        :height="canvasLength"
        :image="image"
        :scale="scale"
        @ratio="ratio = $event"
        @position="imagePosition = $event"
        @input="afterZoomingLayer = $event"
      ></zoomer>
      <erazer
        class="layer layer2"
        :class="{'active-layer': mode === 'erase'}"
        v-if="canvasLength"
        :width="canvasLength"
        :height="canvasLength"
        :image-position="imagePosition"
        :layer="afterZoomingLayer"
        @input="afterErazingLayer = $event"
      ></erazer>
      <cropper
        class="layer layer3"
        :class="{'active-layer': mode === 'crop'}"
        v-show="mode === 'crop'"
        v-if="canvasLength"
        :width="canvasLength"
        :height="canvasLength"
        :image-position="safeImagePosition"
        :ratio="ratio"
        :points="points"
        :layer="afterErazingLayer"
        @input="emitBlob($event)"
      ></cropper>
    </div>
    <div
      class="controls"
      v-if="image"
    >
      <div class="btn-group btn-group-toggle">
        <label
          class="btn btn-secondary control-btn"
          :class="{active: mode === 'crop'}"
        >
          <input
            v-model="mode"
            type="radio"
            value="crop"
            name="options"
          >
          <slot name="crop">
            <span>{{ cropTitle }}</span>
          </slot>
        </label>
        <label
          class="btn btn-secondary control-btn"
          :class="{active: mode === 'erase'}"
        >
          <input
            v-model="mode"
            type="radio"
            value="erase"
            name="options"
          >
          <slot name="erase">
            <span>{{ eraseTitle }}</span>
          </slot>
        </label>
        <label
          class="btn btn-secondary control-btn"
          :class="{active: mode === 'drag'}"
        >
          <input
            v-model="mode"
            type="radio"
            value="drag"
            name="options"
          >
          <slot name="drag">
            <span>{{ dragTitle }}</span>
          </slot>
        </label>
        <label
          class="btn btn-secondary control-btn"
          @click="zoomIn"
        >
          <slot name="zoomIn">
            <span>+</span>
          </slot>
        </label>
        <label
          class="btn btn-secondary control-btn"
          @click="zoomOut"
        >
          <slot name="zoomOut">
            <span>-</span>
          </slot>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Erazer from './Erazer.vue'
import Cropper from './Cropper.vue'
import Zoomer from './Zoomer.vue'

export default {
  name: 'ImageDrawer',
  components: { Zoomer, Cropper, Erazer },
  props: {
    image: HTMLImageElement,
    points: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      mode: 'crop',
      canvasLength: 0,
      imagePosition: null,
      ratio: null,
      afterZoomingLayer: null,
      afterErazingLayer: null,
      afterCroppingLayer: null,
      scale: 1,
      scaleStep: 1.05
    }
  },

  watch: {
    mode (val) {
      if (val === 'drag') {
        this.$eventHub.$emit('freezePosition', true)
      } else {
        this.$eventHub.$emit('freezePosition', false)
      }
    }
  },
  computed: {
    //eslint-disable-next-line
    safeImagePosition () {
      if (this.image && this.imagePosition) {
        return {
          x0: Math.max(this.imagePosition.x0, 0),
          x1: Math.min(this.imagePosition.x1, this.canvasLength),
          y0: Math.max(this.imagePosition.y0, 0),
          y1: Math.min(this.imagePosition.y1, this.canvasLength)
        }
      }
    }
  },

  mounted () {
    setTimeout(() => {
      this.canvasLength = this.$refs.wrapper.offsetWidth
    }, 200)

    if (this.$refs.canvasWrapper.offsetWidth) {
      this.$refs.canvasWrapper.style.height = this.$refs.canvasWrapper.offsetWidth + 'px';
    }
  },

  methods: {
    emitBlob (layer) {
      if (layer) {
        layer.ctx.canvas.toBlob(blob => {
          this.$emit('input', blob)
        }, "image/jpeg")
      } else {
        this.$emit('input', null)
      }
    },
    zoomIn () {
      this.scale = parseFloat((this.scale + this.scaleStep).toFixed(2))
    },
    zoomOut () {
      this.scale = parseFloat((this.scale - this.scaleStep).toFixed(2))
    }
  }
}
</script>

<style scoped>
  .controls {
    z-index: 5;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #0a0a0a;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .control-btn {
    background: #FFFFFF !important;
    box-shadow: 0px 4px 8px #E7EDF9 !important;
    border-radius: 2px !important;
    margin: 20px;
    min-height: 35px;
    max-width: 100px;
    padding-top: 5px;
  }

  .layers-wrapper {
    position: relative;
  }
  .layer1 {
    z-index: 100;
  }
  .layer2 {
    z-index: 101;
  }
  .layer3 {
    z-index: 102;
  }
  .layer {
    pointer-events: none;
  }
  .active-layer {
    pointer-events: inherit;
    position: relative;
  }
  @media only screen and (device-width: 768px) {
    .controls .btn {
      padding: 6px 12px;
    }
  }
  @media (max-width: 479px) {
    .controls .btn {
      padding: 6px 12px;
    }
  }
  @media (max-width: 374px) {
    .controls .btn {
      padding: 6px 12px;
    }
  }
</style>

<template>
  <span></span>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "checkReceiptToken",
  mounted() {
    const endDate = new Date(this.$store.state.campaignEndDate).toLocaleString('en-US', { timeZone: 'Europe/Berlin' })
    const currentTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' });
    const dayInSeconds = 86400; // If we need to include the ending day
    let camEnded = (new Date(endDate).getTime() / 1000 + dayInSeconds) < (new Date(currentTime).getTime() / 1000)
    if (camEnded) {
      this.goToPage('campaign-is-over', this)
      return
    }
    const token = this.$route.query.jwtToken || this.$route.query.token
    if (!token) this.goToPage("expired-page", this)
    this.check72Hours(token)
      .then(res => {
      }).catch(err => {
        this.goToPage("expired-page", this);
      })
  },
  methods: {
    ...mapActions(['check72Hours'])
  }
}
</script>
